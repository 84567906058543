@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
.modal-sidebar, .bodyPublic .subBody, .dropdown-menu, .info-desktop .info-content, .znk-table-select .znk-table-dropdown .dropdown-menu, .znk-table-dropdown .dropdown-menu, .znk-table-dropdown-custom .dropdown-menu, .znk-bullet-point-container .znk-bullet-point, .modal-push-sticky {
  -webkit-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
  -moz-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
  box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1); }

.znk-shadow {
  -webkit-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
  -moz-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
  box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1); }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #B44BE1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #FF7733;
  --yellow: #ffc107;
  --green: #38A895;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #696CFF;
  --secondary: #72809d;
  --success: #A7E521;
  --info: #2C3F78;
  --warning: #FFD422;
  --danger: #FFEEEB;
  --light: #f8f9fa;
  --dark: #254050;
  --error: #FF3E1D;
  --logo: #35425B;
  --gray: #778699;
  --bg: #F7F7F9;
  --title: #2C3F58;
  --transparent: rgba(255, 255, 255, 0.4);
  --mustard: #DFA536;
  --gold: #FF7733;
  --pause: #F0F4F8;
  --cancel: #D7E1EA;
  --input: #F2F2FF;
  --chars: #8FC813;
  --incomplete: #E7AD00;
  --disabled: #D6DAE3;
  --empty: #DCE1E7;
  --incompleteText: #E6AC00;
  --missedBack: #FFEEEB;
  --missedText: #FF3E1D;
  --attendedBack: #F3FCD4;
  --attendedText: #81B023;
  --counseling: #2c3f58;
  --wishlist: grey;
  --inProgress: #B44BE1;
  --applied: #ffd322;
  --admitted: #81B023;
  --rejected: #FF3E1D;
  --waitlistedDiffered: blue;
  --withdrawn: gray;
  --ielts: #696CFF;
  --toefl: #FF7733;
  --sat: #B44BE1;
  --act: #38A895;
  --ap: #FF7733;
  --duolingo: #5B5D5F;
  --ssat: #B44BE1;
  --gre: #38A895;
  --lsat: #472B39;
  --isee: #3F472B;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #2C3F78;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.125rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #696CFF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1d21ff;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.125rem;
  font-weight: 500;
  line-height: 1.2;
  color: #2C3F78; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1.125rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.75rem; }

h6, .h6 {
  font-size: 0.625rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.2; }

.display-2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 500; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0.25rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.125rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 320px; }
  @media (min-width: 576px) {
    .container {
      max-width: 480px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 800px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1100px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1440px; } }

.container-fluid, .container-xs, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto; }

.container {
  max-width: 320px; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 480px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 800px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1100px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1440px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 0.25rem;
  color: #2C3F78; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #2C3F78;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d5d6ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b1b3ff; }

.table-hover .table-primary:hover {
  background-color: #bcbdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bcbdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d8dbe4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b6bdcc; }

.table-hover .table-secondary:hover {
  background-color: #c9cdda; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c9cdda; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e6f8c1; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #d1f18c; }

.table-hover .table-success:hover {
  background-color: #ddf5aa; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #ddf5aa; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c4c9d9; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #919bb9; }

.table-hover .table-info:hover {
  background-color: #b4bbcf; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b4bbcf; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff3c1; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe98c; }

.table-hover .table-warning:hover {
  background-color: #ffeea8; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffeea8; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fffaf9; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fff6f5; }

.table-hover .table-danger:hover {
  background-color: #ffe5e0; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffe5e0; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2cace; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8e9ca4; }

.table-hover .table-dark:hover {
  background-color: #b4bec3; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4bec3; }

.table-error,
.table-error > th,
.table-error > td {
  background-color: #ffc9c0; }

.table-error th,
.table-error td,
.table-error thead th,
.table-error tbody + tbody {
  border-color: #ff9b89; }

.table-hover .table-error:hover {
  background-color: #ffb3a7; }
  .table-hover .table-error:hover > td,
  .table-hover .table-error:hover > th {
    background-color: #ffb3a7; }

.table-logo,
.table-logo > th,
.table-logo > td {
  background-color: #c6cad1; }

.table-logo th,
.table-logo td,
.table-logo thead th,
.table-logo tbody + tbody {
  border-color: #969daa; }

.table-hover .table-logo:hover {
  background-color: #b8bdc6; }
  .table-hover .table-logo:hover > td,
  .table-hover .table-logo:hover > th {
    background-color: #b8bdc6; }

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #d9dde2; }

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #b8c0ca; }

.table-hover .table-gray:hover {
  background-color: #cbd0d7; }
  .table-hover .table-gray:hover > td,
  .table-hover .table-gray:hover > th {
    background-color: #cbd0d7; }

.table-bg,
.table-bg > th,
.table-bg > td {
  background-color: #fdfdfd; }

.table-bg th,
.table-bg td,
.table-bg thead th,
.table-bg tbody + tbody {
  border-color: #fbfbfc; }

.table-hover .table-bg:hover {
  background-color: #f0f0f0; }
  .table-hover .table-bg:hover > td,
  .table-hover .table-bg:hover > th {
    background-color: #f0f0f0; }

.table-title,
.table-title > th,
.table-title > td {
  background-color: #c4c9d0; }

.table-title th,
.table-title td,
.table-title thead th,
.table-title tbody + tbody {
  border-color: #919ba8; }

.table-hover .table-title:hover {
  background-color: #b6bcc5; }
  .table-hover .table-title:hover > td,
  .table-hover .table-title:hover > th {
    background-color: #b6bcc5; }

.table-transparent,
.table-transparent > th,
.table-transparent > td {
  background-color: rgba(255, 255, 255, 0.832); }

.table-transparent th,
.table-transparent td,
.table-transparent thead th,
.table-transparent tbody + tbody {
  border-color: rgba(255, 255, 255, 0.688); }

.table-hover .table-transparent:hover {
  background-color: rgba(242, 242, 242, 0.832); }
  .table-hover .table-transparent:hover > td,
  .table-hover .table-transparent:hover > th {
    background-color: rgba(242, 242, 242, 0.832); }

.table-mustard,
.table-mustard > th,
.table-mustard > td {
  background-color: #f6e6c7; }

.table-mustard th,
.table-mustard td,
.table-mustard thead th,
.table-mustard tbody + tbody {
  border-color: #eed096; }

.table-hover .table-mustard:hover {
  background-color: #f2dcb1; }
  .table-hover .table-mustard:hover > td,
  .table-hover .table-mustard:hover > th {
    background-color: #f2dcb1; }

.table-gold,
.table-gold > th,
.table-gold > td {
  background-color: #ffd9c6; }

.table-gold th,
.table-gold td,
.table-gold thead th,
.table-gold tbody + tbody {
  border-color: #ffb895; }

.table-hover .table-gold:hover {
  background-color: #ffc8ad; }
  .table-hover .table-gold:hover > td,
  .table-hover .table-gold:hover > th {
    background-color: #ffc8ad; }

.table-pause,
.table-pause > th,
.table-pause > td {
  background-color: #fbfcfd; }

.table-pause th,
.table-pause td,
.table-pause thead th,
.table-pause tbody + tbody {
  border-color: #f7f9fb; }

.table-hover .table-pause:hover {
  background-color: #eaeff5; }
  .table-hover .table-pause:hover > td,
  .table-hover .table-pause:hover > th {
    background-color: #eaeff5; }

.table-cancel,
.table-cancel > th,
.table-cancel > td {
  background-color: #f4f7f9; }

.table-cancel th,
.table-cancel td,
.table-cancel thead th,
.table-cancel tbody + tbody {
  border-color: #eaeff4; }

.table-hover .table-cancel:hover {
  background-color: #e4ebf0; }
  .table-hover .table-cancel:hover > td,
  .table-hover .table-cancel:hover > th {
    background-color: #e4ebf0; }

.table-input,
.table-input > th,
.table-input > td {
  background-color: #fbfbff; }

.table-input th,
.table-input td,
.table-input thead th,
.table-input tbody + tbody {
  border-color: ghostwhite; }

.table-hover .table-input:hover {
  background-color: #e2e2ff; }
  .table-hover .table-input:hover > td,
  .table-hover .table-input:hover > th {
    background-color: #e2e2ff; }

.table-chars,
.table-chars > th,
.table-chars > td {
  background-color: #e0f0bd; }

.table-chars th,
.table-chars td,
.table-chars thead th,
.table-chars tbody + tbody {
  border-color: #c5e284; }

.table-hover .table-chars:hover {
  background-color: #d6eba8; }
  .table-hover .table-chars:hover > td,
  .table-hover .table-chars:hover > th {
    background-color: #d6eba8; }

.table-incomplete,
.table-incomplete > th,
.table-incomplete > td {
  background-color: #f8e8b8; }

.table-incomplete th,
.table-incomplete td,
.table-incomplete thead th,
.table-incomplete tbody + tbody {
  border-color: #f3d47a; }

.table-hover .table-incomplete:hover {
  background-color: #f6e0a1; }
  .table-hover .table-incomplete:hover > td,
  .table-hover .table-incomplete:hover > th {
    background-color: #f6e0a1; }

.table-disabled,
.table-disabled > th,
.table-disabled > td {
  background-color: #f4f5f7; }

.table-disabled th,
.table-disabled td,
.table-disabled thead th,
.table-disabled tbody + tbody {
  border-color: #eaecf0; }

.table-hover .table-disabled:hover {
  background-color: #e5e8ec; }
  .table-hover .table-disabled:hover > td,
  .table-hover .table-disabled:hover > th {
    background-color: #e5e8ec; }

.table-empty,
.table-empty > th,
.table-empty > td {
  background-color: #f5f7f8; }

.table-empty th,
.table-empty td,
.table-empty thead th,
.table-empty tbody + tbody {
  border-color: #edeff3; }

.table-hover .table-empty:hover {
  background-color: #e6ebee; }
  .table-hover .table-empty:hover > td,
  .table-hover .table-empty:hover > th {
    background-color: #e6ebee; }

.table-incompleteText,
.table-incompleteText > th,
.table-incompleteText > td {
  background-color: #f8e8b8; }

.table-incompleteText th,
.table-incompleteText td,
.table-incompleteText thead th,
.table-incompleteText tbody + tbody {
  border-color: #f2d47a; }

.table-hover .table-incompleteText:hover {
  background-color: #f6e0a1; }
  .table-hover .table-incompleteText:hover > td,
  .table-hover .table-incompleteText:hover > th {
    background-color: #f6e0a1; }

.table-missedBack,
.table-missedBack > th,
.table-missedBack > td {
  background-color: #fffaf9; }

.table-missedBack th,
.table-missedBack td,
.table-missedBack thead th,
.table-missedBack tbody + tbody {
  border-color: #fff6f5; }

.table-hover .table-missedBack:hover {
  background-color: #ffe5e0; }
  .table-hover .table-missedBack:hover > td,
  .table-hover .table-missedBack:hover > th {
    background-color: #ffe5e0; }

.table-missedText,
.table-missedText > th,
.table-missedText > td {
  background-color: #ffc9c0; }

.table-missedText th,
.table-missedText td,
.table-missedText thead th,
.table-missedText tbody + tbody {
  border-color: #ff9b89; }

.table-hover .table-missedText:hover {
  background-color: #ffb3a7; }
  .table-hover .table-missedText:hover > td,
  .table-hover .table-missedText:hover > th {
    background-color: #ffb3a7; }

.table-attendedBack,
.table-attendedBack > th,
.table-attendedBack > td {
  background-color: #fcfef3; }

.table-attendedBack th,
.table-attendedBack td,
.table-attendedBack thead th,
.table-attendedBack tbody + tbody {
  border-color: #f9fde9; }

.table-hover .table-attendedBack:hover {
  background-color: #f6fcdb; }
  .table-hover .table-attendedBack:hover > td,
  .table-hover .table-attendedBack:hover > th {
    background-color: #f6fcdb; }

.table-attendedText,
.table-attendedText > th,
.table-attendedText > td {
  background-color: #dce9c1; }

.table-attendedText th,
.table-attendedText td,
.table-attendedText thead th,
.table-attendedText tbody + tbody {
  border-color: #bdd68d; }

.table-hover .table-attendedText:hover {
  background-color: #d1e2ae; }
  .table-hover .table-attendedText:hover > td,
  .table-hover .table-attendedText:hover > th {
    background-color: #d1e2ae; }

.table-counseling,
.table-counseling > th,
.table-counseling > td {
  background-color: #c4c9d0; }

.table-counseling th,
.table-counseling td,
.table-counseling thead th,
.table-counseling tbody + tbody {
  border-color: #919ba8; }

.table-hover .table-counseling:hover {
  background-color: #b6bcc5; }
  .table-hover .table-counseling:hover > td,
  .table-hover .table-counseling:hover > th {
    background-color: #b6bcc5; }

.table-wishlist,
.table-wishlist > th,
.table-wishlist > td {
  background-color: #dbdbdb; }

.table-wishlist th,
.table-wishlist td,
.table-wishlist thead th,
.table-wishlist tbody + tbody {
  border-color: #bdbdbd; }

.table-hover .table-wishlist:hover {
  background-color: #cecece; }
  .table-hover .table-wishlist:hover > td,
  .table-hover .table-wishlist:hover > th {
    background-color: #cecece; }

.table-inProgress,
.table-inProgress > th,
.table-inProgress > td {
  background-color: #eacdf7; }

.table-inProgress th,
.table-inProgress td,
.table-inProgress thead th,
.table-inProgress tbody + tbody {
  border-color: #d8a1ef; }

.table-hover .table-inProgress:hover {
  background-color: #e1b7f3; }
  .table-hover .table-inProgress:hover > td,
  .table-hover .table-inProgress:hover > th {
    background-color: #e1b7f3; }

.table-applied,
.table-applied > th,
.table-applied > td {
  background-color: #fff3c1; }

.table-applied th,
.table-applied td,
.table-applied thead th,
.table-applied tbody + tbody {
  border-color: #ffe88c; }

.table-hover .table-applied:hover {
  background-color: #ffeea8; }
  .table-hover .table-applied:hover > td,
  .table-hover .table-applied:hover > th {
    background-color: #ffeea8; }

.table-admitted,
.table-admitted > th,
.table-admitted > td {
  background-color: #dce9c1; }

.table-admitted th,
.table-admitted td,
.table-admitted thead th,
.table-admitted tbody + tbody {
  border-color: #bdd68d; }

.table-hover .table-admitted:hover {
  background-color: #d1e2ae; }
  .table-hover .table-admitted:hover > td,
  .table-hover .table-admitted:hover > th {
    background-color: #d1e2ae; }

.table-rejected,
.table-rejected > th,
.table-rejected > td {
  background-color: #ffc9c0; }

.table-rejected th,
.table-rejected td,
.table-rejected thead th,
.table-rejected tbody + tbody {
  border-color: #ff9b89; }

.table-hover .table-rejected:hover {
  background-color: #ffb3a7; }
  .table-hover .table-rejected:hover > td,
  .table-hover .table-rejected:hover > th {
    background-color: #ffb3a7; }

.table-waitlistedDiffered,
.table-waitlistedDiffered > th,
.table-waitlistedDiffered > td {
  background-color: #b8b8ff; }

.table-waitlistedDiffered th,
.table-waitlistedDiffered td,
.table-waitlistedDiffered thead th,
.table-waitlistedDiffered tbody + tbody {
  border-color: #7a7aff; }

.table-hover .table-waitlistedDiffered:hover {
  background-color: #9f9fff; }
  .table-hover .table-waitlistedDiffered:hover > td,
  .table-hover .table-waitlistedDiffered:hover > th {
    background-color: #9f9fff; }

.table-withdrawn,
.table-withdrawn > th,
.table-withdrawn > td {
  background-color: #dbdbdb; }

.table-withdrawn th,
.table-withdrawn td,
.table-withdrawn thead th,
.table-withdrawn tbody + tbody {
  border-color: #bdbdbd; }

.table-hover .table-withdrawn:hover {
  background-color: #cecece; }
  .table-hover .table-withdrawn:hover > td,
  .table-hover .table-withdrawn:hover > th {
    background-color: #cecece; }

.table-ielts,
.table-ielts > th,
.table-ielts > td {
  background-color: #d5d6ff; }

.table-ielts th,
.table-ielts td,
.table-ielts thead th,
.table-ielts tbody + tbody {
  border-color: #b1b3ff; }

.table-hover .table-ielts:hover {
  background-color: #bcbdff; }
  .table-hover .table-ielts:hover > td,
  .table-hover .table-ielts:hover > th {
    background-color: #bcbdff; }

.table-toefl,
.table-toefl > th,
.table-toefl > td {
  background-color: #ffd9c6; }

.table-toefl th,
.table-toefl td,
.table-toefl thead th,
.table-toefl tbody + tbody {
  border-color: #ffb895; }

.table-hover .table-toefl:hover {
  background-color: #ffc8ad; }
  .table-hover .table-toefl:hover > td,
  .table-hover .table-toefl:hover > th {
    background-color: #ffc8ad; }

.table-sat,
.table-sat > th,
.table-sat > td {
  background-color: #eacdf7; }

.table-sat th,
.table-sat td,
.table-sat thead th,
.table-sat tbody + tbody {
  border-color: #d8a1ef; }

.table-hover .table-sat:hover {
  background-color: #e1b7f3; }
  .table-hover .table-sat:hover > td,
  .table-hover .table-sat:hover > th {
    background-color: #e1b7f3; }

.table-act,
.table-act > th,
.table-act > td {
  background-color: #c7e7e1; }

.table-act th,
.table-act td,
.table-act thead th,
.table-act tbody + tbody {
  border-color: #98d2c8; }

.table-hover .table-act:hover {
  background-color: #b5dfd7; }
  .table-hover .table-act:hover > td,
  .table-hover .table-act:hover > th {
    background-color: #b5dfd7; }

.table-ap,
.table-ap > th,
.table-ap > td {
  background-color: #ffd9c6; }

.table-ap th,
.table-ap td,
.table-ap thead th,
.table-ap tbody + tbody {
  border-color: #ffb895; }

.table-hover .table-ap:hover {
  background-color: #ffc8ad; }
  .table-hover .table-ap:hover > td,
  .table-hover .table-ap:hover > th {
    background-color: #ffc8ad; }

.table-duolingo,
.table-duolingo > th,
.table-duolingo > td {
  background-color: #d1d2d2; }

.table-duolingo th,
.table-duolingo td,
.table-duolingo thead th,
.table-duolingo tbody + tbody {
  border-color: #aaabac; }

.table-hover .table-duolingo:hover {
  background-color: #c4c5c5; }
  .table-hover .table-duolingo:hover > td,
  .table-hover .table-duolingo:hover > th {
    background-color: #c4c5c5; }

.table-ssat,
.table-ssat > th,
.table-ssat > td {
  background-color: #eacdf7; }

.table-ssat th,
.table-ssat td,
.table-ssat thead th,
.table-ssat tbody + tbody {
  border-color: #d8a1ef; }

.table-hover .table-ssat:hover {
  background-color: #e1b7f3; }
  .table-hover .table-ssat:hover > td,
  .table-hover .table-ssat:hover > th {
    background-color: #e1b7f3; }

.table-gre,
.table-gre > th,
.table-gre > td {
  background-color: #c7e7e1; }

.table-gre th,
.table-gre td,
.table-gre thead th,
.table-gre tbody + tbody {
  border-color: #98d2c8; }

.table-hover .table-gre:hover {
  background-color: #b5dfd7; }
  .table-hover .table-gre:hover > td,
  .table-hover .table-gre:hover > th {
    background-color: #b5dfd7; }

.table-lsat,
.table-lsat > th,
.table-lsat > td {
  background-color: #cbc4c8; }

.table-lsat th,
.table-lsat td,
.table-lsat thead th,
.table-lsat tbody + tbody {
  border-color: #9f9198; }

.table-hover .table-lsat:hover {
  background-color: #bfb6bb; }
  .table-hover .table-lsat:hover > td,
  .table-hover .table-lsat:hover > th {
    background-color: #bfb6bb; }

.table-isee,
.table-isee > th,
.table-isee > td {
  background-color: #c9cbc4; }

.table-isee th,
.table-isee td,
.table-isee thead th,
.table-isee tbody + tbody {
  border-color: #9b9f91; }

.table-hover .table-isee:hover {
  background-color: #bdbfb6; }
  .table-hover .table-isee:hover > td,
  .table-hover .table-isee:hover > th {
    background-color: #bdbfb6; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #e9e9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #2C3F78;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #A7E521; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(167, 229, 33, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #A7E521;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23A7E521' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #A7E521;
    box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #A7E521;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23A7E521' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #A7E521;
    box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #A7E521; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #A7E521; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #A7E521; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #b9ea4f;
  background-color: #b9ea4f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #A7E521; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #A7E521; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #A7E521;
  box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FFEEEB; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 238, 235, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FFEEEB;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FFEEEB' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FFEEEB' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #FFEEEB;
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #FFEEEB;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FFEEEB' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FFEEEB' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #FFEEEB;
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FFEEEB; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FFEEEB; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #FFEEEB; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: white;
  background-color: white; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FFEEEB; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FFEEEB; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #FFEEEB;
  box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #2C3F78;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #2C3F78;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #696CFF;
  border-color: #696CFF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #4347ff;
    border-color: #363aff; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #4347ff;
    border-color: #363aff;
    box-shadow: 0 0 0 0.2rem rgba(128, 130, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #363aff;
    border-color: #292eff; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 130, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #72809d;
  border-color: #72809d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5f6d89;
    border-color: #5a6782; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5f6d89;
    border-color: #5a6782;
    box-shadow: 0 0 0 0.2rem rgba(135, 147, 172, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #72809d;
    border-color: #72809d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5a6782;
    border-color: #55617a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 147, 172, 0.5); }

.btn-success {
  color: #212529;
  background-color: #A7E521;
  border-color: #A7E521; }
  .btn-success:hover {
    color: #212529;
    background-color: #90c817;
    border-color: #88bd16; }
  .btn-success:focus, .btn-success.focus {
    color: #212529;
    background-color: #90c817;
    border-color: #88bd16;
    box-shadow: 0 0 0 0.2rem rgba(147, 200, 34, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #A7E521;
    border-color: #A7E521; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #212529;
    background-color: #88bd16;
    border-color: #80b115; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 200, 34, 0.5); }

.btn-info {
  color: #fff;
  background-color: #2C3F78;
  border-color: #2C3F78; }
  .btn-info:hover {
    color: #fff;
    background-color: #22305c;
    border-color: #1e2b53; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #22305c;
    border-color: #1e2b53;
    box-shadow: 0 0 0 0.2rem rgba(76, 92, 140, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2C3F78;
    border-color: #2C3F78; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1e2b53;
    border-color: #1b2749; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 92, 140, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #FFD422;
  border-color: #FFD422; }
  .btn-warning:hover {
    color: #212529;
    background-color: #fbca00;
    border-color: #eec000; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #fbca00;
    border-color: #eec000;
    box-shadow: 0 0 0 0.2rem rgba(222, 186, 35, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FFD422;
    border-color: #FFD422; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #eec000;
    border-color: #e1b500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 186, 35, 0.5); }

.btn-danger {
  color: #212529;
  background-color: #FFEEEB;
  border-color: #FFEEEB; }
  .btn-danger:hover {
    color: #212529;
    background-color: #ffcdc5;
    border-color: #ffc3b8; }
  .btn-danger:focus, .btn-danger.focus {
    color: #212529;
    background-color: #ffcdc5;
    border-color: #ffc3b8;
    box-shadow: 0 0 0 0.2rem rgba(222, 208, 206, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #212529;
    background-color: #ffc3b8;
    border-color: #ffb8ab; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 208, 206, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #254050;
  border-color: #254050; }
  .btn-dark:hover {
    color: #fff;
    background-color: #192b36;
    border-color: #15242d; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #192b36;
    border-color: #15242d;
    box-shadow: 0 0 0 0.2rem rgba(70, 93, 106, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #254050;
    border-color: #254050; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #15242d;
    border-color: #111d24; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(70, 93, 106, 0.5); }

.btn-error {
  color: #fff;
  background-color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-error:hover {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200; }
  .btn-error:focus, .btn-error.focus {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200;
    box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }
  .btn-error.disabled, .btn-error:disabled {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-error:not(:disabled):not(.disabled):active, .btn-error:not(:disabled):not(.disabled).active,
  .show > .btn-error.dropdown-toggle {
    color: #fff;
    background-color: #e92200;
    border-color: #dc2000; }
    .btn-error:not(:disabled):not(.disabled):active:focus, .btn-error:not(:disabled):not(.disabled).active:focus,
    .show > .btn-error.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }

.btn-logo {
  color: #fff;
  background-color: #35425B;
  border-color: #35425B; }
  .btn-logo:hover {
    color: #fff;
    background-color: #273043;
    border-color: #222b3b; }
  .btn-logo:focus, .btn-logo.focus {
    color: #fff;
    background-color: #273043;
    border-color: #222b3b;
    box-shadow: 0 0 0 0.2rem rgba(83, 94, 116, 0.5); }
  .btn-logo.disabled, .btn-logo:disabled {
    color: #fff;
    background-color: #35425B;
    border-color: #35425B; }
  .btn-logo:not(:disabled):not(.disabled):active, .btn-logo:not(:disabled):not(.disabled).active,
  .show > .btn-logo.dropdown-toggle {
    color: #fff;
    background-color: #222b3b;
    border-color: #1e2533; }
    .btn-logo:not(:disabled):not(.disabled):active:focus, .btn-logo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-logo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(83, 94, 116, 0.5); }

.btn-gray {
  color: #fff;
  background-color: #778699;
  border-color: #778699; }
  .btn-gray:hover {
    color: #fff;
    background-color: #647386;
    border-color: #5f6d7e; }
  .btn-gray:focus, .btn-gray.focus {
    color: #fff;
    background-color: #647386;
    border-color: #5f6d7e;
    box-shadow: 0 0 0 0.2rem rgba(139, 152, 168, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background-color: #778699;
    border-color: #778699; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #5f6d7e;
    border-color: #596677; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(139, 152, 168, 0.5); }

.btn-bg {
  color: #212529;
  background-color: #F7F7F9;
  border-color: #F7F7F9; }
  .btn-bg:hover {
    color: #212529;
    background-color: #e1e1e9;
    border-color: #dadae3; }
  .btn-bg:focus, .btn-bg.focus {
    color: #212529;
    background-color: #e1e1e9;
    border-color: #dadae3;
    box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5); }
  .btn-bg.disabled, .btn-bg:disabled {
    color: #212529;
    background-color: #F7F7F9;
    border-color: #F7F7F9; }
  .btn-bg:not(:disabled):not(.disabled):active, .btn-bg:not(:disabled):not(.disabled).active,
  .show > .btn-bg.dropdown-toggle {
    color: #212529;
    background-color: #dadae3;
    border-color: #d3d3de; }
    .btn-bg:not(:disabled):not(.disabled):active:focus, .btn-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 216, 218, 0.5); }

.btn-title {
  color: #fff;
  background-color: #2C3F58;
  border-color: #2C3F58; }
  .btn-title:hover {
    color: #fff;
    background-color: #1f2d3f;
    border-color: #1b2736; }
  .btn-title:focus, .btn-title.focus {
    color: #fff;
    background-color: #1f2d3f;
    border-color: #1b2736;
    box-shadow: 0 0 0 0.2rem rgba(76, 92, 113, 0.5); }
  .btn-title.disabled, .btn-title:disabled {
    color: #fff;
    background-color: #2C3F58;
    border-color: #2C3F58; }
  .btn-title:not(:disabled):not(.disabled):active, .btn-title:not(:disabled):not(.disabled).active,
  .show > .btn-title.dropdown-toggle {
    color: #fff;
    background-color: #1b2736;
    border-color: #17212e; }
    .btn-title:not(:disabled):not(.disabled):active:focus, .btn-title:not(:disabled):not(.disabled).active:focus,
    .show > .btn-title.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 92, 113, 0.5); }

.btn-transparent {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4); }
  .btn-transparent:hover {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.4);
    border-color: rgba(230, 230, 230, 0.4); }
  .btn-transparent:focus, .btn-transparent.focus {
    color: #212529;
    background-color: rgba(236, 236, 236, 0.4);
    border-color: rgba(230, 230, 230, 0.4);
    box-shadow: 0 0 0 0.2rem rgba(163, 165, 166, 0.5); }
  .btn-transparent.disabled, .btn-transparent:disabled {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4); }
  .btn-transparent:not(:disabled):not(.disabled):active, .btn-transparent:not(:disabled):not(.disabled).active,
  .show > .btn-transparent.dropdown-toggle {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.4);
    border-color: rgba(223, 223, 223, 0.4); }
    .btn-transparent:not(:disabled):not(.disabled):active:focus, .btn-transparent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-transparent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(163, 165, 166, 0.5); }

.btn-mustard {
  color: #212529;
  background-color: #DFA536;
  border-color: #DFA536; }
  .btn-mustard:hover {
    color: #212529;
    background-color: #ce9321;
    border-color: #c38b1f; }
  .btn-mustard:focus, .btn-mustard.focus {
    color: #212529;
    background-color: #ce9321;
    border-color: #c38b1f;
    box-shadow: 0 0 0 0.2rem rgba(195, 146, 52, 0.5); }
  .btn-mustard.disabled, .btn-mustard:disabled {
    color: #212529;
    background-color: #DFA536;
    border-color: #DFA536; }
  .btn-mustard:not(:disabled):not(.disabled):active, .btn-mustard:not(:disabled):not(.disabled).active,
  .show > .btn-mustard.dropdown-toggle {
    color: #fff;
    background-color: #c38b1f;
    border-color: #b8831d; }
    .btn-mustard:not(:disabled):not(.disabled):active:focus, .btn-mustard:not(:disabled):not(.disabled).active:focus,
    .show > .btn-mustard.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 146, 52, 0.5); }

.btn-gold {
  color: #212529;
  background-color: #FF7733;
  border-color: #FF7733; }
  .btn-gold:hover {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500; }
  .btn-gold:focus, .btn-gold.focus {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500;
    box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }
  .btn-gold.disabled, .btn-gold:disabled {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-gold:not(:disabled):not(.disabled):active, .btn-gold:not(:disabled):not(.disabled).active,
  .show > .btn-gold.dropdown-toggle {
    color: #fff;
    background-color: #ff5500;
    border-color: #f25100; }
    .btn-gold:not(:disabled):not(.disabled):active:focus, .btn-gold:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gold.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }

.btn-pause {
  color: #212529;
  background-color: #F0F4F8;
  border-color: #F0F4F8; }
  .btn-pause:hover {
    color: #212529;
    background-color: #d6e1ec;
    border-color: #cddbe8; }
  .btn-pause:focus, .btn-pause.focus {
    color: #212529;
    background-color: #d6e1ec;
    border-color: #cddbe8;
    box-shadow: 0 0 0 0.2rem rgba(209, 213, 217, 0.5); }
  .btn-pause.disabled, .btn-pause:disabled {
    color: #212529;
    background-color: #F0F4F8;
    border-color: #F0F4F8; }
  .btn-pause:not(:disabled):not(.disabled):active, .btn-pause:not(:disabled):not(.disabled).active,
  .show > .btn-pause.dropdown-toggle {
    color: #212529;
    background-color: #cddbe8;
    border-color: #c5d4e4; }
    .btn-pause:not(:disabled):not(.disabled):active:focus, .btn-pause:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pause.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 213, 217, 0.5); }

.btn-cancel {
  color: #212529;
  background-color: #D7E1EA;
  border-color: #D7E1EA; }
  .btn-cancel:hover {
    color: #212529;
    background-color: #becedd;
    border-color: #b6c8d8; }
  .btn-cancel:focus, .btn-cancel.focus {
    color: #212529;
    background-color: #becedd;
    border-color: #b6c8d8;
    box-shadow: 0 0 0 0.2rem rgba(188, 197, 205, 0.5); }
  .btn-cancel.disabled, .btn-cancel:disabled {
    color: #212529;
    background-color: #D7E1EA;
    border-color: #D7E1EA; }
  .btn-cancel:not(:disabled):not(.disabled):active, .btn-cancel:not(:disabled):not(.disabled).active,
  .show > .btn-cancel.dropdown-toggle {
    color: #212529;
    background-color: #b6c8d8;
    border-color: #adc2d4; }
    .btn-cancel:not(:disabled):not(.disabled):active:focus, .btn-cancel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cancel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(188, 197, 205, 0.5); }

.btn-input {
  color: #212529;
  background-color: #F2F2FF;
  border-color: #F2F2FF; }
  .btn-input:hover {
    color: #212529;
    background-color: #ccccff;
    border-color: #bfbfff; }
  .btn-input:focus, .btn-input.focus {
    color: #212529;
    background-color: #ccccff;
    border-color: #bfbfff;
    box-shadow: 0 0 0 0.2rem rgba(211, 211, 223, 0.5); }
  .btn-input.disabled, .btn-input:disabled {
    color: #212529;
    background-color: #F2F2FF;
    border-color: #F2F2FF; }
  .btn-input:not(:disabled):not(.disabled):active, .btn-input:not(:disabled):not(.disabled).active,
  .show > .btn-input.dropdown-toggle {
    color: #212529;
    background-color: #bfbfff;
    border-color: #b2b2ff; }
    .btn-input:not(:disabled):not(.disabled):active:focus, .btn-input:not(:disabled):not(.disabled).active:focus,
    .show > .btn-input.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 211, 223, 0.5); }

.btn-chars {
  color: #212529;
  background-color: #8FC813;
  border-color: #8FC813; }
  .btn-chars:hover {
    color: #fff;
    background-color: #76a510;
    border-color: #6e990f; }
  .btn-chars:focus, .btn-chars.focus {
    color: #fff;
    background-color: #76a510;
    border-color: #6e990f;
    box-shadow: 0 0 0 0.2rem rgba(127, 176, 22, 0.5); }
  .btn-chars.disabled, .btn-chars:disabled {
    color: #212529;
    background-color: #8FC813;
    border-color: #8FC813; }
  .btn-chars:not(:disabled):not(.disabled):active, .btn-chars:not(:disabled):not(.disabled).active,
  .show > .btn-chars.dropdown-toggle {
    color: #fff;
    background-color: #6e990f;
    border-color: #658e0d; }
    .btn-chars:not(:disabled):not(.disabled):active:focus, .btn-chars:not(:disabled):not(.disabled).active:focus,
    .show > .btn-chars.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(127, 176, 22, 0.5); }

.btn-incomplete {
  color: #212529;
  background-color: #E7AD00;
  border-color: #E7AD00; }
  .btn-incomplete:hover {
    color: #fff;
    background-color: #c19000;
    border-color: #b48700; }
  .btn-incomplete:focus, .btn-incomplete.focus {
    color: #fff;
    background-color: #c19000;
    border-color: #b48700;
    box-shadow: 0 0 0 0.2rem rgba(201, 153, 6, 0.5); }
  .btn-incomplete.disabled, .btn-incomplete:disabled {
    color: #212529;
    background-color: #E7AD00;
    border-color: #E7AD00; }
  .btn-incomplete:not(:disabled):not(.disabled):active, .btn-incomplete:not(:disabled):not(.disabled).active,
  .show > .btn-incomplete.dropdown-toggle {
    color: #fff;
    background-color: #b48700;
    border-color: #a77d00; }
    .btn-incomplete:not(:disabled):not(.disabled):active:focus, .btn-incomplete:not(:disabled):not(.disabled).active:focus,
    .show > .btn-incomplete.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(201, 153, 6, 0.5); }

.btn-disabled {
  color: #212529;
  background-color: #D6DAE3;
  border-color: #D6DAE3; }
  .btn-disabled:hover {
    color: #212529;
    background-color: #bfc5d3;
    border-color: #b8bfce; }
  .btn-disabled:focus, .btn-disabled.focus {
    color: #212529;
    background-color: #bfc5d3;
    border-color: #b8bfce;
    box-shadow: 0 0 0 0.2rem rgba(187, 191, 199, 0.5); }
  .btn-disabled.disabled, .btn-disabled:disabled {
    color: #212529;
    background-color: #D6DAE3;
    border-color: #D6DAE3; }
  .btn-disabled:not(:disabled):not(.disabled):active, .btn-disabled:not(:disabled):not(.disabled).active,
  .show > .btn-disabled.dropdown-toggle {
    color: #212529;
    background-color: #b8bfce;
    border-color: #b0b8c9; }
    .btn-disabled:not(:disabled):not(.disabled):active:focus, .btn-disabled:not(:disabled):not(.disabled).active:focus,
    .show > .btn-disabled.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(187, 191, 199, 0.5); }

.btn-empty {
  color: #212529;
  background-color: #DCE1E7;
  border-color: #DCE1E7; }
  .btn-empty:hover {
    color: #212529;
    background-color: #c5ced7;
    border-color: #bec7d2; }
  .btn-empty:focus, .btn-empty.focus {
    color: #212529;
    background-color: #c5ced7;
    border-color: #bec7d2;
    box-shadow: 0 0 0 0.2rem rgba(192, 197, 203, 0.5); }
  .btn-empty.disabled, .btn-empty:disabled {
    color: #212529;
    background-color: #DCE1E7;
    border-color: #DCE1E7; }
  .btn-empty:not(:disabled):not(.disabled):active, .btn-empty:not(:disabled):not(.disabled).active,
  .show > .btn-empty.dropdown-toggle {
    color: #212529;
    background-color: #bec7d2;
    border-color: #b6c1cd; }
    .btn-empty:not(:disabled):not(.disabled):active:focus, .btn-empty:not(:disabled):not(.disabled).active:focus,
    .show > .btn-empty.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(192, 197, 203, 0.5); }

.btn-incompleteText {
  color: #212529;
  background-color: #E6AC00;
  border-color: #E6AC00; }
  .btn-incompleteText:hover {
    color: #fff;
    background-color: #c08f00;
    border-color: #b38600; }
  .btn-incompleteText:focus, .btn-incompleteText.focus {
    color: #fff;
    background-color: #c08f00;
    border-color: #b38600;
    box-shadow: 0 0 0 0.2rem rgba(200, 152, 6, 0.5); }
  .btn-incompleteText.disabled, .btn-incompleteText:disabled {
    color: #212529;
    background-color: #E6AC00;
    border-color: #E6AC00; }
  .btn-incompleteText:not(:disabled):not(.disabled):active, .btn-incompleteText:not(:disabled):not(.disabled).active,
  .show > .btn-incompleteText.dropdown-toggle {
    color: #fff;
    background-color: #b38600;
    border-color: #a67c00; }
    .btn-incompleteText:not(:disabled):not(.disabled):active:focus, .btn-incompleteText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-incompleteText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 152, 6, 0.5); }

.btn-missedBack {
  color: #212529;
  background-color: #FFEEEB;
  border-color: #FFEEEB; }
  .btn-missedBack:hover {
    color: #212529;
    background-color: #ffcdc5;
    border-color: #ffc3b8; }
  .btn-missedBack:focus, .btn-missedBack.focus {
    color: #212529;
    background-color: #ffcdc5;
    border-color: #ffc3b8;
    box-shadow: 0 0 0 0.2rem rgba(222, 208, 206, 0.5); }
  .btn-missedBack.disabled, .btn-missedBack:disabled {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
  .btn-missedBack:not(:disabled):not(.disabled):active, .btn-missedBack:not(:disabled):not(.disabled).active,
  .show > .btn-missedBack.dropdown-toggle {
    color: #212529;
    background-color: #ffc3b8;
    border-color: #ffb8ab; }
    .btn-missedBack:not(:disabled):not(.disabled):active:focus, .btn-missedBack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-missedBack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 208, 206, 0.5); }

.btn-missedText {
  color: #fff;
  background-color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-missedText:hover {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200; }
  .btn-missedText:focus, .btn-missedText.focus {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200;
    box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }
  .btn-missedText.disabled, .btn-missedText:disabled {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-missedText:not(:disabled):not(.disabled):active, .btn-missedText:not(:disabled):not(.disabled).active,
  .show > .btn-missedText.dropdown-toggle {
    color: #fff;
    background-color: #e92200;
    border-color: #dc2000; }
    .btn-missedText:not(:disabled):not(.disabled):active:focus, .btn-missedText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-missedText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }

.btn-attendedBack {
  color: #212529;
  background-color: #F3FCD4;
  border-color: #F3FCD4; }
  .btn-attendedBack:hover {
    color: #212529;
    background-color: #e9fab0;
    border-color: #e6f9a4; }
  .btn-attendedBack:focus, .btn-attendedBack.focus {
    color: #212529;
    background-color: #e9fab0;
    border-color: #e6f9a4;
    box-shadow: 0 0 0 0.2rem rgba(212, 220, 186, 0.5); }
  .btn-attendedBack.disabled, .btn-attendedBack:disabled {
    color: #212529;
    background-color: #F3FCD4;
    border-color: #F3FCD4; }
  .btn-attendedBack:not(:disabled):not(.disabled):active, .btn-attendedBack:not(:disabled):not(.disabled).active,
  .show > .btn-attendedBack.dropdown-toggle {
    color: #212529;
    background-color: #e6f9a4;
    border-color: #e2f898; }
    .btn-attendedBack:not(:disabled):not(.disabled):active:focus, .btn-attendedBack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-attendedBack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 220, 186, 0.5); }

.btn-attendedText {
  color: #fff;
  background-color: #81B023;
  border-color: #81B023; }
  .btn-attendedText:hover {
    color: #fff;
    background-color: #6a901d;
    border-color: #62851b; }
  .btn-attendedText:focus, .btn-attendedText.focus {
    color: #fff;
    background-color: #6a901d;
    border-color: #62851b;
    box-shadow: 0 0 0 0.2rem rgba(148, 188, 68, 0.5); }
  .btn-attendedText.disabled, .btn-attendedText:disabled {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
  .btn-attendedText:not(:disabled):not(.disabled):active, .btn-attendedText:not(:disabled):not(.disabled).active,
  .show > .btn-attendedText.dropdown-toggle {
    color: #fff;
    background-color: #62851b;
    border-color: #5a7b18; }
    .btn-attendedText:not(:disabled):not(.disabled):active:focus, .btn-attendedText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-attendedText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 188, 68, 0.5); }

.btn-counseling {
  color: #fff;
  background-color: #2c3f58;
  border-color: #2c3f58; }
  .btn-counseling:hover {
    color: #fff;
    background-color: #1f2d3f;
    border-color: #1b2736; }
  .btn-counseling:focus, .btn-counseling.focus {
    color: #fff;
    background-color: #1f2d3f;
    border-color: #1b2736;
    box-shadow: 0 0 0 0.2rem rgba(76, 92, 113, 0.5); }
  .btn-counseling.disabled, .btn-counseling:disabled {
    color: #fff;
    background-color: #2c3f58;
    border-color: #2c3f58; }
  .btn-counseling:not(:disabled):not(.disabled):active, .btn-counseling:not(:disabled):not(.disabled).active,
  .show > .btn-counseling.dropdown-toggle {
    color: #fff;
    background-color: #1b2736;
    border-color: #17212e; }
    .btn-counseling:not(:disabled):not(.disabled):active:focus, .btn-counseling:not(:disabled):not(.disabled).active:focus,
    .show > .btn-counseling.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 92, 113, 0.5); }

.btn-wishlist {
  color: #fff;
  background-color: grey;
  border-color: grey; }
  .btn-wishlist:hover {
    color: #fff;
    background-color: #6d6d6d;
    border-color: #676767; }
  .btn-wishlist:focus, .btn-wishlist.focus {
    color: #fff;
    background-color: #6d6d6d;
    border-color: #676767;
    box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }
  .btn-wishlist.disabled, .btn-wishlist:disabled {
    color: #fff;
    background-color: grey;
    border-color: grey; }
  .btn-wishlist:not(:disabled):not(.disabled):active, .btn-wishlist:not(:disabled):not(.disabled).active,
  .show > .btn-wishlist.dropdown-toggle {
    color: #fff;
    background-color: #676767;
    border-color: #606060; }
    .btn-wishlist:not(:disabled):not(.disabled):active:focus, .btn-wishlist:not(:disabled):not(.disabled).active:focus,
    .show > .btn-wishlist.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }

.btn-inProgress {
  color: #fff;
  background-color: #B44BE1;
  border-color: #B44BE1; }
  .btn-inProgress:hover {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5; }
  .btn-inProgress:focus, .btn-inProgress.focus {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5;
    box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }
  .btn-inProgress.disabled, .btn-inProgress:disabled {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-inProgress:not(:disabled):not(.disabled):active, .btn-inProgress:not(:disabled):not(.disabled).active,
  .show > .btn-inProgress.dropdown-toggle {
    color: #fff;
    background-color: #a024d5;
    border-color: #9822cb; }
    .btn-inProgress:not(:disabled):not(.disabled):active:focus, .btn-inProgress:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inProgress.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }

.btn-applied {
  color: #212529;
  background-color: #ffd322;
  border-color: #ffd322; }
  .btn-applied:hover {
    color: #212529;
    background-color: #fbc900;
    border-color: #eebf00; }
  .btn-applied:focus, .btn-applied.focus {
    color: #212529;
    background-color: #fbc900;
    border-color: #eebf00;
    box-shadow: 0 0 0 0.2rem rgba(222, 185, 35, 0.5); }
  .btn-applied.disabled, .btn-applied:disabled {
    color: #212529;
    background-color: #ffd322;
    border-color: #ffd322; }
  .btn-applied:not(:disabled):not(.disabled):active, .btn-applied:not(:disabled):not(.disabled).active,
  .show > .btn-applied.dropdown-toggle {
    color: #212529;
    background-color: #eebf00;
    border-color: #e1b400; }
    .btn-applied:not(:disabled):not(.disabled):active:focus, .btn-applied:not(:disabled):not(.disabled).active:focus,
    .show > .btn-applied.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 185, 35, 0.5); }

.btn-admitted {
  color: #fff;
  background-color: #81B023;
  border-color: #81B023; }
  .btn-admitted:hover {
    color: #fff;
    background-color: #6a901d;
    border-color: #62851b; }
  .btn-admitted:focus, .btn-admitted.focus {
    color: #fff;
    background-color: #6a901d;
    border-color: #62851b;
    box-shadow: 0 0 0 0.2rem rgba(148, 188, 68, 0.5); }
  .btn-admitted.disabled, .btn-admitted:disabled {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
  .btn-admitted:not(:disabled):not(.disabled):active, .btn-admitted:not(:disabled):not(.disabled).active,
  .show > .btn-admitted.dropdown-toggle {
    color: #fff;
    background-color: #62851b;
    border-color: #5a7b18; }
    .btn-admitted:not(:disabled):not(.disabled):active:focus, .btn-admitted:not(:disabled):not(.disabled).active:focus,
    .show > .btn-admitted.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(148, 188, 68, 0.5); }

.btn-rejected {
  color: #fff;
  background-color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-rejected:hover {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200; }
  .btn-rejected:focus, .btn-rejected.focus {
    color: #fff;
    background-color: #f62400;
    border-color: #e92200;
    box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }
  .btn-rejected.disabled, .btn-rejected:disabled {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-rejected:not(:disabled):not(.disabled):active, .btn-rejected:not(:disabled):not(.disabled).active,
  .show > .btn-rejected.dropdown-toggle {
    color: #fff;
    background-color: #e92200;
    border-color: #dc2000; }
    .btn-rejected:not(:disabled):not(.disabled):active:focus, .btn-rejected:not(:disabled):not(.disabled).active:focus,
    .show > .btn-rejected.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 91, 63, 0.5); }

.btn-waitlistedDiffered {
  color: #fff;
  background-color: blue;
  border-color: blue; }
  .btn-waitlistedDiffered:hover {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc; }
  .btn-waitlistedDiffered:focus, .btn-waitlistedDiffered.focus {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }
  .btn-waitlistedDiffered.disabled, .btn-waitlistedDiffered:disabled {
    color: #fff;
    background-color: blue;
    border-color: blue; }
  .btn-waitlistedDiffered:not(:disabled):not(.disabled):active, .btn-waitlistedDiffered:not(:disabled):not(.disabled).active,
  .show > .btn-waitlistedDiffered.dropdown-toggle {
    color: #fff;
    background-color: #0000cc;
    border-color: #0000bf; }
    .btn-waitlistedDiffered:not(:disabled):not(.disabled):active:focus, .btn-waitlistedDiffered:not(:disabled):not(.disabled).active:focus,
    .show > .btn-waitlistedDiffered.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }

.btn-withdrawn {
  color: #fff;
  background-color: gray;
  border-color: gray; }
  .btn-withdrawn:hover {
    color: #fff;
    background-color: #6d6d6d;
    border-color: #676767; }
  .btn-withdrawn:focus, .btn-withdrawn.focus {
    color: #fff;
    background-color: #6d6d6d;
    border-color: #676767;
    box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }
  .btn-withdrawn.disabled, .btn-withdrawn:disabled {
    color: #fff;
    background-color: gray;
    border-color: gray; }
  .btn-withdrawn:not(:disabled):not(.disabled):active, .btn-withdrawn:not(:disabled):not(.disabled).active,
  .show > .btn-withdrawn.dropdown-toggle {
    color: #fff;
    background-color: #676767;
    border-color: #606060; }
    .btn-withdrawn:not(:disabled):not(.disabled):active:focus, .btn-withdrawn:not(:disabled):not(.disabled).active:focus,
    .show > .btn-withdrawn.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 147, 147, 0.5); }

.btn-ielts {
  color: #fff;
  background-color: #696CFF;
  border-color: #696CFF; }
  .btn-ielts:hover {
    color: #fff;
    background-color: #4347ff;
    border-color: #363aff; }
  .btn-ielts:focus, .btn-ielts.focus {
    color: #fff;
    background-color: #4347ff;
    border-color: #363aff;
    box-shadow: 0 0 0 0.2rem rgba(128, 130, 255, 0.5); }
  .btn-ielts.disabled, .btn-ielts:disabled {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
  .btn-ielts:not(:disabled):not(.disabled):active, .btn-ielts:not(:disabled):not(.disabled).active,
  .show > .btn-ielts.dropdown-toggle {
    color: #fff;
    background-color: #363aff;
    border-color: #292eff; }
    .btn-ielts:not(:disabled):not(.disabled):active:focus, .btn-ielts:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ielts.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 130, 255, 0.5); }

.btn-toefl {
  color: #212529;
  background-color: #FF7733;
  border-color: #FF7733; }
  .btn-toefl:hover {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500; }
  .btn-toefl:focus, .btn-toefl.focus {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500;
    box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }
  .btn-toefl.disabled, .btn-toefl:disabled {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-toefl:not(:disabled):not(.disabled):active, .btn-toefl:not(:disabled):not(.disabled).active,
  .show > .btn-toefl.dropdown-toggle {
    color: #fff;
    background-color: #ff5500;
    border-color: #f25100; }
    .btn-toefl:not(:disabled):not(.disabled):active:focus, .btn-toefl:not(:disabled):not(.disabled).active:focus,
    .show > .btn-toefl.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }

.btn-sat {
  color: #fff;
  background-color: #B44BE1;
  border-color: #B44BE1; }
  .btn-sat:hover {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5; }
  .btn-sat:focus, .btn-sat.focus {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5;
    box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }
  .btn-sat.disabled, .btn-sat:disabled {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-sat:not(:disabled):not(.disabled):active, .btn-sat:not(:disabled):not(.disabled).active,
  .show > .btn-sat.dropdown-toggle {
    color: #fff;
    background-color: #a024d5;
    border-color: #9822cb; }
    .btn-sat:not(:disabled):not(.disabled):active:focus, .btn-sat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-sat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }

.btn-act {
  color: #fff;
  background-color: #38A895;
  border-color: #38A895; }
  .btn-act:hover {
    color: #fff;
    background-color: #2e8b7c;
    border-color: #2b8273; }
  .btn-act:focus, .btn-act.focus {
    color: #fff;
    background-color: #2e8b7c;
    border-color: #2b8273;
    box-shadow: 0 0 0 0.2rem rgba(86, 181, 165, 0.5); }
  .btn-act.disabled, .btn-act:disabled {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
  .btn-act:not(:disabled):not(.disabled):active, .btn-act:not(:disabled):not(.disabled).active,
  .show > .btn-act.dropdown-toggle {
    color: #fff;
    background-color: #2b8273;
    border-color: #28786b; }
    .btn-act:not(:disabled):not(.disabled):active:focus, .btn-act:not(:disabled):not(.disabled).active:focus,
    .show > .btn-act.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(86, 181, 165, 0.5); }

.btn-ap {
  color: #212529;
  background-color: #FF7733;
  border-color: #FF7733; }
  .btn-ap:hover {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500; }
  .btn-ap:focus, .btn-ap.focus {
    color: #fff;
    background-color: #ff5e0d;
    border-color: #ff5500;
    box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }
  .btn-ap.disabled, .btn-ap:disabled {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-ap:not(:disabled):not(.disabled):active, .btn-ap:not(:disabled):not(.disabled).active,
  .show > .btn-ap.dropdown-toggle {
    color: #fff;
    background-color: #ff5500;
    border-color: #f25100; }
    .btn-ap:not(:disabled):not(.disabled):active:focus, .btn-ap:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ap.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 107, 50, 0.5); }

.btn-duolingo {
  color: #fff;
  background-color: #5B5D5F;
  border-color: #5B5D5F; }
  .btn-duolingo:hover {
    color: #fff;
    background-color: #484a4b;
    border-color: #424445; }
  .btn-duolingo:focus, .btn-duolingo.focus {
    color: #fff;
    background-color: #484a4b;
    border-color: #424445;
    box-shadow: 0 0 0 0.2rem rgba(116, 117, 119, 0.5); }
  .btn-duolingo.disabled, .btn-duolingo:disabled {
    color: #fff;
    background-color: #5B5D5F;
    border-color: #5B5D5F; }
  .btn-duolingo:not(:disabled):not(.disabled):active, .btn-duolingo:not(:disabled):not(.disabled).active,
  .show > .btn-duolingo.dropdown-toggle {
    color: #fff;
    background-color: #424445;
    border-color: #3c3d3e; }
    .btn-duolingo:not(:disabled):not(.disabled):active:focus, .btn-duolingo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-duolingo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(116, 117, 119, 0.5); }

.btn-ssat {
  color: #fff;
  background-color: #B44BE1;
  border-color: #B44BE1; }
  .btn-ssat:hover {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5; }
  .btn-ssat:focus, .btn-ssat.focus {
    color: #fff;
    background-color: #a62adc;
    border-color: #a024d5;
    box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }
  .btn-ssat.disabled, .btn-ssat:disabled {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-ssat:not(:disabled):not(.disabled):active, .btn-ssat:not(:disabled):not(.disabled).active,
  .show > .btn-ssat.dropdown-toggle {
    color: #fff;
    background-color: #a024d5;
    border-color: #9822cb; }
    .btn-ssat:not(:disabled):not(.disabled):active:focus, .btn-ssat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-ssat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(191, 102, 230, 0.5); }

.btn-gre {
  color: #fff;
  background-color: #38A895;
  border-color: #38A895; }
  .btn-gre:hover {
    color: #fff;
    background-color: #2e8b7c;
    border-color: #2b8273; }
  .btn-gre:focus, .btn-gre.focus {
    color: #fff;
    background-color: #2e8b7c;
    border-color: #2b8273;
    box-shadow: 0 0 0 0.2rem rgba(86, 181, 165, 0.5); }
  .btn-gre.disabled, .btn-gre:disabled {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
  .btn-gre:not(:disabled):not(.disabled):active, .btn-gre:not(:disabled):not(.disabled).active,
  .show > .btn-gre.dropdown-toggle {
    color: #fff;
    background-color: #2b8273;
    border-color: #28786b; }
    .btn-gre:not(:disabled):not(.disabled):active:focus, .btn-gre:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gre.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(86, 181, 165, 0.5); }

.btn-lsat {
  color: #fff;
  background-color: #472B39;
  border-color: #472B39; }
  .btn-lsat:hover {
    color: #fff;
    background-color: #2f1d26;
    border-color: #271820; }
  .btn-lsat:focus, .btn-lsat.focus {
    color: #fff;
    background-color: #2f1d26;
    border-color: #271820;
    box-shadow: 0 0 0 0.2rem rgba(99, 75, 87, 0.5); }
  .btn-lsat.disabled, .btn-lsat:disabled {
    color: #fff;
    background-color: #472B39;
    border-color: #472B39; }
  .btn-lsat:not(:disabled):not(.disabled):active, .btn-lsat:not(:disabled):not(.disabled).active,
  .show > .btn-lsat.dropdown-toggle {
    color: #fff;
    background-color: #271820;
    border-color: #1f1319; }
    .btn-lsat:not(:disabled):not(.disabled):active:focus, .btn-lsat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-lsat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(99, 75, 87, 0.5); }

.btn-isee {
  color: #fff;
  background-color: #3F472B;
  border-color: #3F472B; }
  .btn-isee:hover {
    color: #fff;
    background-color: #2a2f1d;
    border-color: #232718; }
  .btn-isee:focus, .btn-isee.focus {
    color: #fff;
    background-color: #2a2f1d;
    border-color: #232718;
    box-shadow: 0 0 0 0.2rem rgba(92, 99, 75, 0.5); }
  .btn-isee.disabled, .btn-isee:disabled {
    color: #fff;
    background-color: #3F472B;
    border-color: #3F472B; }
  .btn-isee:not(:disabled):not(.disabled):active, .btn-isee:not(:disabled):not(.disabled).active,
  .show > .btn-isee.dropdown-toggle {
    color: #fff;
    background-color: #232718;
    border-color: #1c1f13; }
    .btn-isee:not(:disabled):not(.disabled):active:focus, .btn-isee:not(:disabled):not(.disabled).active:focus,
    .show > .btn-isee.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(92, 99, 75, 0.5); }

.btn-outline-primary {
  color: #696CFF;
  border-color: #696CFF; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #696CFF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }

.btn-outline-secondary {
  color: #72809d;
  border-color: #72809d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #72809d;
    border-color: #72809d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 128, 157, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #72809d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #72809d;
    border-color: #72809d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 128, 157, 0.5); }

.btn-outline-success {
  color: #A7E521;
  border-color: #A7E521; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #A7E521;
    border-color: #A7E521; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #A7E521;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #A7E521;
    border-color: #A7E521; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.5); }

.btn-outline-info {
  color: #2C3F78;
  border-color: #2C3F78; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2C3F78;
    border-color: #2C3F78; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 120, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2C3F78;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #2C3F78;
    border-color: #2C3F78; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 63, 120, 0.5); }

.btn-outline-warning {
  color: #FFD422;
  border-color: #FFD422; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #FFD422;
    border-color: #FFD422; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 212, 34, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFD422;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFD422;
    border-color: #FFD422; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 212, 34, 0.5); }

.btn-outline-danger {
  color: #FFEEEB;
  border-color: #FFEEEB; }
  .btn-outline-danger:hover {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FFEEEB;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #254050;
  border-color: #254050; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #254050;
    border-color: #254050; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(37, 64, 80, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #254050;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #254050;
    border-color: #254050; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(37, 64, 80, 0.5); }

.btn-outline-error {
  color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-outline-error:hover {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-outline-error:focus, .btn-outline-error.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }
  .btn-outline-error.disabled, .btn-outline-error:disabled {
    color: #FF3E1D;
    background-color: transparent; }
  .btn-outline-error:not(:disabled):not(.disabled):active, .btn-outline-error:not(:disabled):not(.disabled).active,
  .show > .btn-outline-error.dropdown-toggle {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
    .btn-outline-error:not(:disabled):not(.disabled):active:focus, .btn-outline-error:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-error.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.btn-outline-logo {
  color: #35425B;
  border-color: #35425B; }
  .btn-outline-logo:hover {
    color: #fff;
    background-color: #35425B;
    border-color: #35425B; }
  .btn-outline-logo:focus, .btn-outline-logo.focus {
    box-shadow: 0 0 0 0.2rem rgba(53, 66, 91, 0.5); }
  .btn-outline-logo.disabled, .btn-outline-logo:disabled {
    color: #35425B;
    background-color: transparent; }
  .btn-outline-logo:not(:disabled):not(.disabled):active, .btn-outline-logo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-logo.dropdown-toggle {
    color: #fff;
    background-color: #35425B;
    border-color: #35425B; }
    .btn-outline-logo:not(:disabled):not(.disabled):active:focus, .btn-outline-logo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-logo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(53, 66, 91, 0.5); }

.btn-outline-gray {
  color: #778699;
  border-color: #778699; }
  .btn-outline-gray:hover {
    color: #fff;
    background-color: #778699;
    border-color: #778699; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(119, 134, 153, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #778699;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #fff;
    background-color: #778699;
    border-color: #778699; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 134, 153, 0.5); }

.btn-outline-bg {
  color: #F7F7F9;
  border-color: #F7F7F9; }
  .btn-outline-bg:hover {
    color: #212529;
    background-color: #F7F7F9;
    border-color: #F7F7F9; }
  .btn-outline-bg:focus, .btn-outline-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 249, 0.5); }
  .btn-outline-bg.disabled, .btn-outline-bg:disabled {
    color: #F7F7F9;
    background-color: transparent; }
  .btn-outline-bg:not(:disabled):not(.disabled):active, .btn-outline-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-bg.dropdown-toggle {
    color: #212529;
    background-color: #F7F7F9;
    border-color: #F7F7F9; }
    .btn-outline-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 247, 249, 0.5); }

.btn-outline-title {
  color: #2C3F58;
  border-color: #2C3F58; }
  .btn-outline-title:hover {
    color: #fff;
    background-color: #2C3F58;
    border-color: #2C3F58; }
  .btn-outline-title:focus, .btn-outline-title.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }
  .btn-outline-title.disabled, .btn-outline-title:disabled {
    color: #2C3F58;
    background-color: transparent; }
  .btn-outline-title:not(:disabled):not(.disabled):active, .btn-outline-title:not(:disabled):not(.disabled).active,
  .show > .btn-outline-title.dropdown-toggle {
    color: #fff;
    background-color: #2C3F58;
    border-color: #2C3F58; }
    .btn-outline-title:not(:disabled):not(.disabled):active:focus, .btn-outline-title:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-title.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }

.btn-outline-transparent {
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4); }
  .btn-outline-transparent:hover {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4); }
  .btn-outline-transparent:focus, .btn-outline-transparent.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-transparent.disabled, .btn-outline-transparent:disabled {
    color: rgba(255, 255, 255, 0.4);
    background-color: transparent; }
  .btn-outline-transparent:not(:disabled):not(.disabled):active, .btn-outline-transparent:not(:disabled):not(.disabled).active,
  .show > .btn-outline-transparent.dropdown-toggle {
    color: #212529;
    background-color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4); }
    .btn-outline-transparent:not(:disabled):not(.disabled):active:focus, .btn-outline-transparent:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-transparent.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-mustard {
  color: #DFA536;
  border-color: #DFA536; }
  .btn-outline-mustard:hover {
    color: #212529;
    background-color: #DFA536;
    border-color: #DFA536; }
  .btn-outline-mustard:focus, .btn-outline-mustard.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 165, 54, 0.5); }
  .btn-outline-mustard.disabled, .btn-outline-mustard:disabled {
    color: #DFA536;
    background-color: transparent; }
  .btn-outline-mustard:not(:disabled):not(.disabled):active, .btn-outline-mustard:not(:disabled):not(.disabled).active,
  .show > .btn-outline-mustard.dropdown-toggle {
    color: #212529;
    background-color: #DFA536;
    border-color: #DFA536; }
    .btn-outline-mustard:not(:disabled):not(.disabled):active:focus, .btn-outline-mustard:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-mustard.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 165, 54, 0.5); }

.btn-outline-gold {
  color: #FF7733;
  border-color: #FF7733; }
  .btn-outline-gold:hover {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-outline-gold:focus, .btn-outline-gold.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }
  .btn-outline-gold.disabled, .btn-outline-gold:disabled {
    color: #FF7733;
    background-color: transparent; }
  .btn-outline-gold:not(:disabled):not(.disabled):active, .btn-outline-gold:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gold.dropdown-toggle {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
    .btn-outline-gold:not(:disabled):not(.disabled):active:focus, .btn-outline-gold:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gold.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.btn-outline-pause {
  color: #F0F4F8;
  border-color: #F0F4F8; }
  .btn-outline-pause:hover {
    color: #212529;
    background-color: #F0F4F8;
    border-color: #F0F4F8; }
  .btn-outline-pause:focus, .btn-outline-pause.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 244, 248, 0.5); }
  .btn-outline-pause.disabled, .btn-outline-pause:disabled {
    color: #F0F4F8;
    background-color: transparent; }
  .btn-outline-pause:not(:disabled):not(.disabled):active, .btn-outline-pause:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pause.dropdown-toggle {
    color: #212529;
    background-color: #F0F4F8;
    border-color: #F0F4F8; }
    .btn-outline-pause:not(:disabled):not(.disabled):active:focus, .btn-outline-pause:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pause.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 244, 248, 0.5); }

.btn-outline-cancel {
  color: #D7E1EA;
  border-color: #D7E1EA; }
  .btn-outline-cancel:hover {
    color: #212529;
    background-color: #D7E1EA;
    border-color: #D7E1EA; }
  .btn-outline-cancel:focus, .btn-outline-cancel.focus {
    box-shadow: 0 0 0 0.2rem rgba(215, 225, 234, 0.5); }
  .btn-outline-cancel.disabled, .btn-outline-cancel:disabled {
    color: #D7E1EA;
    background-color: transparent; }
  .btn-outline-cancel:not(:disabled):not(.disabled):active, .btn-outline-cancel:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cancel.dropdown-toggle {
    color: #212529;
    background-color: #D7E1EA;
    border-color: #D7E1EA; }
    .btn-outline-cancel:not(:disabled):not(.disabled):active:focus, .btn-outline-cancel:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cancel.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(215, 225, 234, 0.5); }

.btn-outline-input {
  color: #F2F2FF;
  border-color: #F2F2FF; }
  .btn-outline-input:hover {
    color: #212529;
    background-color: #F2F2FF;
    border-color: #F2F2FF; }
  .btn-outline-input:focus, .btn-outline-input.focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 255, 0.5); }
  .btn-outline-input.disabled, .btn-outline-input:disabled {
    color: #F2F2FF;
    background-color: transparent; }
  .btn-outline-input:not(:disabled):not(.disabled):active, .btn-outline-input:not(:disabled):not(.disabled).active,
  .show > .btn-outline-input.dropdown-toggle {
    color: #212529;
    background-color: #F2F2FF;
    border-color: #F2F2FF; }
    .btn-outline-input:not(:disabled):not(.disabled):active:focus, .btn-outline-input:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-input.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 242, 255, 0.5); }

.btn-outline-chars {
  color: #8FC813;
  border-color: #8FC813; }
  .btn-outline-chars:hover {
    color: #212529;
    background-color: #8FC813;
    border-color: #8FC813; }
  .btn-outline-chars:focus, .btn-outline-chars.focus {
    box-shadow: 0 0 0 0.2rem rgba(143, 200, 19, 0.5); }
  .btn-outline-chars.disabled, .btn-outline-chars:disabled {
    color: #8FC813;
    background-color: transparent; }
  .btn-outline-chars:not(:disabled):not(.disabled):active, .btn-outline-chars:not(:disabled):not(.disabled).active,
  .show > .btn-outline-chars.dropdown-toggle {
    color: #212529;
    background-color: #8FC813;
    border-color: #8FC813; }
    .btn-outline-chars:not(:disabled):not(.disabled):active:focus, .btn-outline-chars:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-chars.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(143, 200, 19, 0.5); }

.btn-outline-incomplete {
  color: #E7AD00;
  border-color: #E7AD00; }
  .btn-outline-incomplete:hover {
    color: #212529;
    background-color: #E7AD00;
    border-color: #E7AD00; }
  .btn-outline-incomplete:focus, .btn-outline-incomplete.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 173, 0, 0.5); }
  .btn-outline-incomplete.disabled, .btn-outline-incomplete:disabled {
    color: #E7AD00;
    background-color: transparent; }
  .btn-outline-incomplete:not(:disabled):not(.disabled):active, .btn-outline-incomplete:not(:disabled):not(.disabled).active,
  .show > .btn-outline-incomplete.dropdown-toggle {
    color: #212529;
    background-color: #E7AD00;
    border-color: #E7AD00; }
    .btn-outline-incomplete:not(:disabled):not(.disabled):active:focus, .btn-outline-incomplete:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-incomplete.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 173, 0, 0.5); }

.btn-outline-disabled {
  color: #D6DAE3;
  border-color: #D6DAE3; }
  .btn-outline-disabled:hover {
    color: #212529;
    background-color: #D6DAE3;
    border-color: #D6DAE3; }
  .btn-outline-disabled:focus, .btn-outline-disabled.focus {
    box-shadow: 0 0 0 0.2rem rgba(214, 218, 227, 0.5); }
  .btn-outline-disabled.disabled, .btn-outline-disabled:disabled {
    color: #D6DAE3;
    background-color: transparent; }
  .btn-outline-disabled:not(:disabled):not(.disabled):active, .btn-outline-disabled:not(:disabled):not(.disabled).active,
  .show > .btn-outline-disabled.dropdown-toggle {
    color: #212529;
    background-color: #D6DAE3;
    border-color: #D6DAE3; }
    .btn-outline-disabled:not(:disabled):not(.disabled):active:focus, .btn-outline-disabled:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-disabled.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 218, 227, 0.5); }

.btn-outline-empty {
  color: #DCE1E7;
  border-color: #DCE1E7; }
  .btn-outline-empty:hover {
    color: #212529;
    background-color: #DCE1E7;
    border-color: #DCE1E7; }
  .btn-outline-empty:focus, .btn-outline-empty.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 225, 231, 0.5); }
  .btn-outline-empty.disabled, .btn-outline-empty:disabled {
    color: #DCE1E7;
    background-color: transparent; }
  .btn-outline-empty:not(:disabled):not(.disabled):active, .btn-outline-empty:not(:disabled):not(.disabled).active,
  .show > .btn-outline-empty.dropdown-toggle {
    color: #212529;
    background-color: #DCE1E7;
    border-color: #DCE1E7; }
    .btn-outline-empty:not(:disabled):not(.disabled):active:focus, .btn-outline-empty:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-empty.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 225, 231, 0.5); }

.btn-outline-incompleteText {
  color: #E6AC00;
  border-color: #E6AC00; }
  .btn-outline-incompleteText:hover {
    color: #212529;
    background-color: #E6AC00;
    border-color: #E6AC00; }
  .btn-outline-incompleteText:focus, .btn-outline-incompleteText.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 172, 0, 0.5); }
  .btn-outline-incompleteText.disabled, .btn-outline-incompleteText:disabled {
    color: #E6AC00;
    background-color: transparent; }
  .btn-outline-incompleteText:not(:disabled):not(.disabled):active, .btn-outline-incompleteText:not(:disabled):not(.disabled).active,
  .show > .btn-outline-incompleteText.dropdown-toggle {
    color: #212529;
    background-color: #E6AC00;
    border-color: #E6AC00; }
    .btn-outline-incompleteText:not(:disabled):not(.disabled):active:focus, .btn-outline-incompleteText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-incompleteText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 172, 0, 0.5); }

.btn-outline-missedBack {
  color: #FFEEEB;
  border-color: #FFEEEB; }
  .btn-outline-missedBack:hover {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
  .btn-outline-missedBack:focus, .btn-outline-missedBack.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }
  .btn-outline-missedBack.disabled, .btn-outline-missedBack:disabled {
    color: #FFEEEB;
    background-color: transparent; }
  .btn-outline-missedBack:not(:disabled):not(.disabled):active, .btn-outline-missedBack:not(:disabled):not(.disabled).active,
  .show > .btn-outline-missedBack.dropdown-toggle {
    color: #212529;
    background-color: #FFEEEB;
    border-color: #FFEEEB; }
    .btn-outline-missedBack:not(:disabled):not(.disabled):active:focus, .btn-outline-missedBack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-missedBack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }

.btn-outline-missedText {
  color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-outline-missedText:hover {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-outline-missedText:focus, .btn-outline-missedText.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }
  .btn-outline-missedText.disabled, .btn-outline-missedText:disabled {
    color: #FF3E1D;
    background-color: transparent; }
  .btn-outline-missedText:not(:disabled):not(.disabled):active, .btn-outline-missedText:not(:disabled):not(.disabled).active,
  .show > .btn-outline-missedText.dropdown-toggle {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
    .btn-outline-missedText:not(:disabled):not(.disabled):active:focus, .btn-outline-missedText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-missedText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.btn-outline-attendedBack {
  color: #F3FCD4;
  border-color: #F3FCD4; }
  .btn-outline-attendedBack:hover {
    color: #212529;
    background-color: #F3FCD4;
    border-color: #F3FCD4; }
  .btn-outline-attendedBack:focus, .btn-outline-attendedBack.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 252, 212, 0.5); }
  .btn-outline-attendedBack.disabled, .btn-outline-attendedBack:disabled {
    color: #F3FCD4;
    background-color: transparent; }
  .btn-outline-attendedBack:not(:disabled):not(.disabled):active, .btn-outline-attendedBack:not(:disabled):not(.disabled).active,
  .show > .btn-outline-attendedBack.dropdown-toggle {
    color: #212529;
    background-color: #F3FCD4;
    border-color: #F3FCD4; }
    .btn-outline-attendedBack:not(:disabled):not(.disabled):active:focus, .btn-outline-attendedBack:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-attendedBack.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 252, 212, 0.5); }

.btn-outline-attendedText {
  color: #81B023;
  border-color: #81B023; }
  .btn-outline-attendedText:hover {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
  .btn-outline-attendedText:focus, .btn-outline-attendedText.focus {
    box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }
  .btn-outline-attendedText.disabled, .btn-outline-attendedText:disabled {
    color: #81B023;
    background-color: transparent; }
  .btn-outline-attendedText:not(:disabled):not(.disabled):active, .btn-outline-attendedText:not(:disabled):not(.disabled).active,
  .show > .btn-outline-attendedText.dropdown-toggle {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
    .btn-outline-attendedText:not(:disabled):not(.disabled):active:focus, .btn-outline-attendedText:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-attendedText.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }

.btn-outline-counseling {
  color: #2c3f58;
  border-color: #2c3f58; }
  .btn-outline-counseling:hover {
    color: #fff;
    background-color: #2c3f58;
    border-color: #2c3f58; }
  .btn-outline-counseling:focus, .btn-outline-counseling.focus {
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }
  .btn-outline-counseling.disabled, .btn-outline-counseling:disabled {
    color: #2c3f58;
    background-color: transparent; }
  .btn-outline-counseling:not(:disabled):not(.disabled):active, .btn-outline-counseling:not(:disabled):not(.disabled).active,
  .show > .btn-outline-counseling.dropdown-toggle {
    color: #fff;
    background-color: #2c3f58;
    border-color: #2c3f58; }
    .btn-outline-counseling:not(:disabled):not(.disabled):active:focus, .btn-outline-counseling:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-counseling.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }

.btn-outline-wishlist {
  color: grey;
  border-color: grey; }
  .btn-outline-wishlist:hover {
    color: #fff;
    background-color: grey;
    border-color: grey; }
  .btn-outline-wishlist:focus, .btn-outline-wishlist.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }
  .btn-outline-wishlist.disabled, .btn-outline-wishlist:disabled {
    color: grey;
    background-color: transparent; }
  .btn-outline-wishlist:not(:disabled):not(.disabled):active, .btn-outline-wishlist:not(:disabled):not(.disabled).active,
  .show > .btn-outline-wishlist.dropdown-toggle {
    color: #fff;
    background-color: grey;
    border-color: grey; }
    .btn-outline-wishlist:not(:disabled):not(.disabled):active:focus, .btn-outline-wishlist:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-wishlist.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }

.btn-outline-inProgress {
  color: #B44BE1;
  border-color: #B44BE1; }
  .btn-outline-inProgress:hover {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-outline-inProgress:focus, .btn-outline-inProgress.focus {
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }
  .btn-outline-inProgress.disabled, .btn-outline-inProgress:disabled {
    color: #B44BE1;
    background-color: transparent; }
  .btn-outline-inProgress:not(:disabled):not(.disabled):active, .btn-outline-inProgress:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inProgress.dropdown-toggle {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
    .btn-outline-inProgress:not(:disabled):not(.disabled):active:focus, .btn-outline-inProgress:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inProgress.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.btn-outline-applied {
  color: #ffd322;
  border-color: #ffd322; }
  .btn-outline-applied:hover {
    color: #212529;
    background-color: #ffd322;
    border-color: #ffd322; }
  .btn-outline-applied:focus, .btn-outline-applied.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 211, 34, 0.5); }
  .btn-outline-applied.disabled, .btn-outline-applied:disabled {
    color: #ffd322;
    background-color: transparent; }
  .btn-outline-applied:not(:disabled):not(.disabled):active, .btn-outline-applied:not(:disabled):not(.disabled).active,
  .show > .btn-outline-applied.dropdown-toggle {
    color: #212529;
    background-color: #ffd322;
    border-color: #ffd322; }
    .btn-outline-applied:not(:disabled):not(.disabled):active:focus, .btn-outline-applied:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-applied.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 211, 34, 0.5); }

.btn-outline-admitted {
  color: #81B023;
  border-color: #81B023; }
  .btn-outline-admitted:hover {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
  .btn-outline-admitted:focus, .btn-outline-admitted.focus {
    box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }
  .btn-outline-admitted.disabled, .btn-outline-admitted:disabled {
    color: #81B023;
    background-color: transparent; }
  .btn-outline-admitted:not(:disabled):not(.disabled):active, .btn-outline-admitted:not(:disabled):not(.disabled).active,
  .show > .btn-outline-admitted.dropdown-toggle {
    color: #fff;
    background-color: #81B023;
    border-color: #81B023; }
    .btn-outline-admitted:not(:disabled):not(.disabled):active:focus, .btn-outline-admitted:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-admitted.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }

.btn-outline-rejected {
  color: #FF3E1D;
  border-color: #FF3E1D; }
  .btn-outline-rejected:hover {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
  .btn-outline-rejected:focus, .btn-outline-rejected.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }
  .btn-outline-rejected.disabled, .btn-outline-rejected:disabled {
    color: #FF3E1D;
    background-color: transparent; }
  .btn-outline-rejected:not(:disabled):not(.disabled):active, .btn-outline-rejected:not(:disabled):not(.disabled).active,
  .show > .btn-outline-rejected.dropdown-toggle {
    color: #fff;
    background-color: #FF3E1D;
    border-color: #FF3E1D; }
    .btn-outline-rejected:not(:disabled):not(.disabled):active:focus, .btn-outline-rejected:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-rejected.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.btn-outline-waitlistedDiffered {
  color: blue;
  border-color: blue; }
  .btn-outline-waitlistedDiffered:hover {
    color: #fff;
    background-color: blue;
    border-color: blue; }
  .btn-outline-waitlistedDiffered:focus, .btn-outline-waitlistedDiffered.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }
  .btn-outline-waitlistedDiffered.disabled, .btn-outline-waitlistedDiffered:disabled {
    color: blue;
    background-color: transparent; }
  .btn-outline-waitlistedDiffered:not(:disabled):not(.disabled):active, .btn-outline-waitlistedDiffered:not(:disabled):not(.disabled).active,
  .show > .btn-outline-waitlistedDiffered.dropdown-toggle {
    color: #fff;
    background-color: blue;
    border-color: blue; }
    .btn-outline-waitlistedDiffered:not(:disabled):not(.disabled):active:focus, .btn-outline-waitlistedDiffered:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-waitlistedDiffered.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }

.btn-outline-withdrawn {
  color: gray;
  border-color: gray; }
  .btn-outline-withdrawn:hover {
    color: #fff;
    background-color: gray;
    border-color: gray; }
  .btn-outline-withdrawn:focus, .btn-outline-withdrawn.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }
  .btn-outline-withdrawn.disabled, .btn-outline-withdrawn:disabled {
    color: gray;
    background-color: transparent; }
  .btn-outline-withdrawn:not(:disabled):not(.disabled):active, .btn-outline-withdrawn:not(:disabled):not(.disabled).active,
  .show > .btn-outline-withdrawn.dropdown-toggle {
    color: #fff;
    background-color: gray;
    border-color: gray; }
    .btn-outline-withdrawn:not(:disabled):not(.disabled):active:focus, .btn-outline-withdrawn:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-withdrawn.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }

.btn-outline-ielts {
  color: #696CFF;
  border-color: #696CFF; }
  .btn-outline-ielts:hover {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
  .btn-outline-ielts:focus, .btn-outline-ielts.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }
  .btn-outline-ielts.disabled, .btn-outline-ielts:disabled {
    color: #696CFF;
    background-color: transparent; }
  .btn-outline-ielts:not(:disabled):not(.disabled):active, .btn-outline-ielts:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ielts.dropdown-toggle {
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
    .btn-outline-ielts:not(:disabled):not(.disabled):active:focus, .btn-outline-ielts:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ielts.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }

.btn-outline-toefl {
  color: #FF7733;
  border-color: #FF7733; }
  .btn-outline-toefl:hover {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-outline-toefl:focus, .btn-outline-toefl.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }
  .btn-outline-toefl.disabled, .btn-outline-toefl:disabled {
    color: #FF7733;
    background-color: transparent; }
  .btn-outline-toefl:not(:disabled):not(.disabled):active, .btn-outline-toefl:not(:disabled):not(.disabled).active,
  .show > .btn-outline-toefl.dropdown-toggle {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
    .btn-outline-toefl:not(:disabled):not(.disabled):active:focus, .btn-outline-toefl:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-toefl.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.btn-outline-sat {
  color: #B44BE1;
  border-color: #B44BE1; }
  .btn-outline-sat:hover {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-outline-sat:focus, .btn-outline-sat.focus {
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }
  .btn-outline-sat.disabled, .btn-outline-sat:disabled {
    color: #B44BE1;
    background-color: transparent; }
  .btn-outline-sat:not(:disabled):not(.disabled):active, .btn-outline-sat:not(:disabled):not(.disabled).active,
  .show > .btn-outline-sat.dropdown-toggle {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
    .btn-outline-sat:not(:disabled):not(.disabled):active:focus, .btn-outline-sat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-sat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.btn-outline-act {
  color: #38A895;
  border-color: #38A895; }
  .btn-outline-act:hover {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
  .btn-outline-act:focus, .btn-outline-act.focus {
    box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }
  .btn-outline-act.disabled, .btn-outline-act:disabled {
    color: #38A895;
    background-color: transparent; }
  .btn-outline-act:not(:disabled):not(.disabled):active, .btn-outline-act:not(:disabled):not(.disabled).active,
  .show > .btn-outline-act.dropdown-toggle {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
    .btn-outline-act:not(:disabled):not(.disabled):active:focus, .btn-outline-act:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-act.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }

.btn-outline-ap {
  color: #FF7733;
  border-color: #FF7733; }
  .btn-outline-ap:hover {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
  .btn-outline-ap:focus, .btn-outline-ap.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }
  .btn-outline-ap.disabled, .btn-outline-ap:disabled {
    color: #FF7733;
    background-color: transparent; }
  .btn-outline-ap:not(:disabled):not(.disabled):active, .btn-outline-ap:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ap.dropdown-toggle {
    color: #212529;
    background-color: #FF7733;
    border-color: #FF7733; }
    .btn-outline-ap:not(:disabled):not(.disabled):active:focus, .btn-outline-ap:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ap.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.btn-outline-duolingo {
  color: #5B5D5F;
  border-color: #5B5D5F; }
  .btn-outline-duolingo:hover {
    color: #fff;
    background-color: #5B5D5F;
    border-color: #5B5D5F; }
  .btn-outline-duolingo:focus, .btn-outline-duolingo.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 93, 95, 0.5); }
  .btn-outline-duolingo.disabled, .btn-outline-duolingo:disabled {
    color: #5B5D5F;
    background-color: transparent; }
  .btn-outline-duolingo:not(:disabled):not(.disabled):active, .btn-outline-duolingo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-duolingo.dropdown-toggle {
    color: #fff;
    background-color: #5B5D5F;
    border-color: #5B5D5F; }
    .btn-outline-duolingo:not(:disabled):not(.disabled):active:focus, .btn-outline-duolingo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-duolingo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 93, 95, 0.5); }

.btn-outline-ssat {
  color: #B44BE1;
  border-color: #B44BE1; }
  .btn-outline-ssat:hover {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
  .btn-outline-ssat:focus, .btn-outline-ssat.focus {
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }
  .btn-outline-ssat.disabled, .btn-outline-ssat:disabled {
    color: #B44BE1;
    background-color: transparent; }
  .btn-outline-ssat:not(:disabled):not(.disabled):active, .btn-outline-ssat:not(:disabled):not(.disabled).active,
  .show > .btn-outline-ssat.dropdown-toggle {
    color: #fff;
    background-color: #B44BE1;
    border-color: #B44BE1; }
    .btn-outline-ssat:not(:disabled):not(.disabled):active:focus, .btn-outline-ssat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-ssat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.btn-outline-gre {
  color: #38A895;
  border-color: #38A895; }
  .btn-outline-gre:hover {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
  .btn-outline-gre:focus, .btn-outline-gre.focus {
    box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }
  .btn-outline-gre.disabled, .btn-outline-gre:disabled {
    color: #38A895;
    background-color: transparent; }
  .btn-outline-gre:not(:disabled):not(.disabled):active, .btn-outline-gre:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gre.dropdown-toggle {
    color: #fff;
    background-color: #38A895;
    border-color: #38A895; }
    .btn-outline-gre:not(:disabled):not(.disabled):active:focus, .btn-outline-gre:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gre.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }

.btn-outline-lsat {
  color: #472B39;
  border-color: #472B39; }
  .btn-outline-lsat:hover {
    color: #fff;
    background-color: #472B39;
    border-color: #472B39; }
  .btn-outline-lsat:focus, .btn-outline-lsat.focus {
    box-shadow: 0 0 0 0.2rem rgba(71, 43, 57, 0.5); }
  .btn-outline-lsat.disabled, .btn-outline-lsat:disabled {
    color: #472B39;
    background-color: transparent; }
  .btn-outline-lsat:not(:disabled):not(.disabled):active, .btn-outline-lsat:not(:disabled):not(.disabled).active,
  .show > .btn-outline-lsat.dropdown-toggle {
    color: #fff;
    background-color: #472B39;
    border-color: #472B39; }
    .btn-outline-lsat:not(:disabled):not(.disabled):active:focus, .btn-outline-lsat:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-lsat.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 43, 57, 0.5); }

.btn-outline-isee {
  color: #3F472B;
  border-color: #3F472B; }
  .btn-outline-isee:hover {
    color: #fff;
    background-color: #3F472B;
    border-color: #3F472B; }
  .btn-outline-isee:focus, .btn-outline-isee.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 71, 43, 0.5); }
  .btn-outline-isee.disabled, .btn-outline-isee:disabled {
    color: #3F472B;
    background-color: transparent; }
  .btn-outline-isee:not(:disabled):not(.disabled):active, .btn-outline-isee:not(:disabled):not(.disabled).active,
  .show > .btn-outline-isee.dropdown-toggle {
    color: #fff;
    background-color: #3F472B;
    border-color: #3F472B; }
    .btn-outline-isee:not(:disabled):not(.disabled):active:focus, .btn-outline-isee:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-isee.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 71, 43, 0.5); }

.btn-link {
  font-weight: 500;
  color: #696CFF;
  text-decoration: none; }
  .btn-link:hover {
    color: #1d21ff;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2C3F78;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.125rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 500;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #696CFF; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #696CFF;
    background-color: #696CFF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #e9e9ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #696CFF;
  background-color: #696CFF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(105, 108, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(105, 108, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(105, 108, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(105, 108, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #e9e9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #e9e9ff;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #696CFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #696CFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #696CFF;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #696CFF; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.125rem 0.25rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-xs, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0.25rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 0.75rem;
      margin-bottom: 0;
      margin-left: 0.75rem; } }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #696CFF;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1d21ff;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #696CFF;
  border-color: #696CFF; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #696CFF; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #363aff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #72809d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #5a6782; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 128, 157, 0.5); }

.badge-success {
  color: #212529;
  background-color: #A7E521; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #88bd16; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(167, 229, 33, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2C3F78; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1e2b53; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 120, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FFD422; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #eec000; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 212, 34, 0.5); }

.badge-danger {
  color: #212529;
  background-color: #FFEEEB; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #212529;
    background-color: #ffc3b8; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #254050; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #15242d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(37, 64, 80, 0.5); }

.badge-error {
  color: #fff;
  background-color: #FF3E1D; }
  a.badge-error:hover, a.badge-error:focus {
    color: #fff;
    background-color: #e92200; }
  a.badge-error:focus, a.badge-error.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.badge-logo {
  color: #fff;
  background-color: #35425B; }
  a.badge-logo:hover, a.badge-logo:focus {
    color: #fff;
    background-color: #222b3b; }
  a.badge-logo:focus, a.badge-logo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(53, 66, 91, 0.5); }

.badge-gray {
  color: #fff;
  background-color: #778699; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #fff;
    background-color: #5f6d7e; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(119, 134, 153, 0.5); }

.badge-bg {
  color: #212529;
  background-color: #F7F7F9; }
  a.badge-bg:hover, a.badge-bg:focus {
    color: #212529;
    background-color: #dadae3; }
  a.badge-bg:focus, a.badge-bg.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 249, 0.5); }

.badge-title {
  color: #fff;
  background-color: #2C3F58; }
  a.badge-title:hover, a.badge-title:focus {
    color: #fff;
    background-color: #1b2736; }
  a.badge-title:focus, a.badge-title.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }

.badge-transparent {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.4); }
  a.badge-transparent:hover, a.badge-transparent:focus {
    color: #212529;
    background-color: rgba(230, 230, 230, 0.4); }
  a.badge-transparent:focus, a.badge-transparent.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-mustard {
  color: #212529;
  background-color: #DFA536; }
  a.badge-mustard:hover, a.badge-mustard:focus {
    color: #212529;
    background-color: #c38b1f; }
  a.badge-mustard:focus, a.badge-mustard.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(223, 165, 54, 0.5); }

.badge-gold {
  color: #212529;
  background-color: #FF7733; }
  a.badge-gold:hover, a.badge-gold:focus {
    color: #212529;
    background-color: #ff5500; }
  a.badge-gold:focus, a.badge-gold.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.badge-pause {
  color: #212529;
  background-color: #F0F4F8; }
  a.badge-pause:hover, a.badge-pause:focus {
    color: #212529;
    background-color: #cddbe8; }
  a.badge-pause:focus, a.badge-pause.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 244, 248, 0.5); }

.badge-cancel {
  color: #212529;
  background-color: #D7E1EA; }
  a.badge-cancel:hover, a.badge-cancel:focus {
    color: #212529;
    background-color: #b6c8d8; }
  a.badge-cancel:focus, a.badge-cancel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(215, 225, 234, 0.5); }

.badge-input {
  color: #212529;
  background-color: #F2F2FF; }
  a.badge-input:hover, a.badge-input:focus {
    color: #212529;
    background-color: #bfbfff; }
  a.badge-input:focus, a.badge-input.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 255, 0.5); }

.badge-chars {
  color: #212529;
  background-color: #8FC813; }
  a.badge-chars:hover, a.badge-chars:focus {
    color: #212529;
    background-color: #6e990f; }
  a.badge-chars:focus, a.badge-chars.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(143, 200, 19, 0.5); }

.badge-incomplete {
  color: #212529;
  background-color: #E7AD00; }
  a.badge-incomplete:hover, a.badge-incomplete:focus {
    color: #212529;
    background-color: #b48700; }
  a.badge-incomplete:focus, a.badge-incomplete.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 173, 0, 0.5); }

.badge-disabled {
  color: #212529;
  background-color: #D6DAE3; }
  a.badge-disabled:hover, a.badge-disabled:focus {
    color: #212529;
    background-color: #b8bfce; }
  a.badge-disabled:focus, a.badge-disabled.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(214, 218, 227, 0.5); }

.badge-empty {
  color: #212529;
  background-color: #DCE1E7; }
  a.badge-empty:hover, a.badge-empty:focus {
    color: #212529;
    background-color: #bec7d2; }
  a.badge-empty:focus, a.badge-empty.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 225, 231, 0.5); }

.badge-incompleteText {
  color: #212529;
  background-color: #E6AC00; }
  a.badge-incompleteText:hover, a.badge-incompleteText:focus {
    color: #212529;
    background-color: #b38600; }
  a.badge-incompleteText:focus, a.badge-incompleteText.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(230, 172, 0, 0.5); }

.badge-missedBack {
  color: #212529;
  background-color: #FFEEEB; }
  a.badge-missedBack:hover, a.badge-missedBack:focus {
    color: #212529;
    background-color: #ffc3b8; }
  a.badge-missedBack:focus, a.badge-missedBack.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 238, 235, 0.5); }

.badge-missedText {
  color: #fff;
  background-color: #FF3E1D; }
  a.badge-missedText:hover, a.badge-missedText:focus {
    color: #fff;
    background-color: #e92200; }
  a.badge-missedText:focus, a.badge-missedText.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.badge-attendedBack {
  color: #212529;
  background-color: #F3FCD4; }
  a.badge-attendedBack:hover, a.badge-attendedBack:focus {
    color: #212529;
    background-color: #e6f9a4; }
  a.badge-attendedBack:focus, a.badge-attendedBack.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 252, 212, 0.5); }

.badge-attendedText {
  color: #fff;
  background-color: #81B023; }
  a.badge-attendedText:hover, a.badge-attendedText:focus {
    color: #fff;
    background-color: #62851b; }
  a.badge-attendedText:focus, a.badge-attendedText.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }

.badge-counseling {
  color: #fff;
  background-color: #2c3f58; }
  a.badge-counseling:hover, a.badge-counseling:focus {
    color: #fff;
    background-color: #1b2736; }
  a.badge-counseling:focus, a.badge-counseling.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(44, 63, 88, 0.5); }

.badge-wishlist {
  color: #fff;
  background-color: grey; }
  a.badge-wishlist:hover, a.badge-wishlist:focus {
    color: #fff;
    background-color: #676767; }
  a.badge-wishlist:focus, a.badge-wishlist.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }

.badge-inProgress {
  color: #fff;
  background-color: #B44BE1; }
  a.badge-inProgress:hover, a.badge-inProgress:focus {
    color: #fff;
    background-color: #a024d5; }
  a.badge-inProgress:focus, a.badge-inProgress.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.badge-applied {
  color: #212529;
  background-color: #ffd322; }
  a.badge-applied:hover, a.badge-applied:focus {
    color: #212529;
    background-color: #eebf00; }
  a.badge-applied:focus, a.badge-applied.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 211, 34, 0.5); }

.badge-admitted {
  color: #fff;
  background-color: #81B023; }
  a.badge-admitted:hover, a.badge-admitted:focus {
    color: #fff;
    background-color: #62851b; }
  a.badge-admitted:focus, a.badge-admitted.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(129, 176, 35, 0.5); }

.badge-rejected {
  color: #fff;
  background-color: #FF3E1D; }
  a.badge-rejected:hover, a.badge-rejected:focus {
    color: #fff;
    background-color: #e92200; }
  a.badge-rejected:focus, a.badge-rejected.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 62, 29, 0.5); }

.badge-waitlistedDiffered {
  color: #fff;
  background-color: blue; }
  a.badge-waitlistedDiffered:hover, a.badge-waitlistedDiffered:focus {
    color: #fff;
    background-color: #0000cc; }
  a.badge-waitlistedDiffered:focus, a.badge-waitlistedDiffered.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }

.badge-withdrawn {
  color: #fff;
  background-color: gray; }
  a.badge-withdrawn:hover, a.badge-withdrawn:focus {
    color: #fff;
    background-color: #676767; }
  a.badge-withdrawn:focus, a.badge-withdrawn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 128, 128, 0.5); }

.badge-ielts {
  color: #fff;
  background-color: #696CFF; }
  a.badge-ielts:hover, a.badge-ielts:focus {
    color: #fff;
    background-color: #363aff; }
  a.badge-ielts:focus, a.badge-ielts.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 108, 255, 0.5); }

.badge-toefl {
  color: #212529;
  background-color: #FF7733; }
  a.badge-toefl:hover, a.badge-toefl:focus {
    color: #212529;
    background-color: #ff5500; }
  a.badge-toefl:focus, a.badge-toefl.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.badge-sat {
  color: #fff;
  background-color: #B44BE1; }
  a.badge-sat:hover, a.badge-sat:focus {
    color: #fff;
    background-color: #a024d5; }
  a.badge-sat:focus, a.badge-sat.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.badge-act {
  color: #fff;
  background-color: #38A895; }
  a.badge-act:hover, a.badge-act:focus {
    color: #fff;
    background-color: #2b8273; }
  a.badge-act:focus, a.badge-act.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }

.badge-ap {
  color: #212529;
  background-color: #FF7733; }
  a.badge-ap:hover, a.badge-ap:focus {
    color: #212529;
    background-color: #ff5500; }
  a.badge-ap:focus, a.badge-ap.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 119, 51, 0.5); }

.badge-duolingo {
  color: #fff;
  background-color: #5B5D5F; }
  a.badge-duolingo:hover, a.badge-duolingo:focus {
    color: #fff;
    background-color: #424445; }
  a.badge-duolingo:focus, a.badge-duolingo.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(91, 93, 95, 0.5); }

.badge-ssat {
  color: #fff;
  background-color: #B44BE1; }
  a.badge-ssat:hover, a.badge-ssat:focus {
    color: #fff;
    background-color: #a024d5; }
  a.badge-ssat:focus, a.badge-ssat.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(180, 75, 225, 0.5); }

.badge-gre {
  color: #fff;
  background-color: #38A895; }
  a.badge-gre:hover, a.badge-gre:focus {
    color: #fff;
    background-color: #2b8273; }
  a.badge-gre:focus, a.badge-gre.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(56, 168, 149, 0.5); }

.badge-lsat {
  color: #fff;
  background-color: #472B39; }
  a.badge-lsat:hover, a.badge-lsat:focus {
    color: #fff;
    background-color: #271820; }
  a.badge-lsat:focus, a.badge-lsat.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(71, 43, 57, 0.5); }

.badge-isee {
  color: #fff;
  background-color: #3F472B; }
  a.badge-isee:hover, a.badge-isee:focus {
    color: #fff;
    background-color: #232718; }
  a.badge-isee:focus, a.badge-isee.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(63, 71, 43, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #373885;
  background-color: #e1e2ff;
  border-color: #d5d6ff; }
  .alert-primary hr {
    border-top-color: #bcbdff; }
  .alert-primary .alert-link {
    color: #282961; }

.alert-secondary {
  color: #3b4352;
  background-color: #e3e6eb;
  border-color: #d8dbe4; }
  .alert-secondary hr {
    border-top-color: #c9cdda; }
  .alert-secondary .alert-link {
    color: #262b34; }

.alert-success {
  color: #577711;
  background-color: #edfad3;
  border-color: #e6f8c1; }
  .alert-success hr {
    border-top-color: #ddf5aa; }
  .alert-success .alert-link {
    color: #364a0b; }

.alert-info {
  color: #17213e;
  background-color: #d5d9e4;
  border-color: #c4c9d9; }
  .alert-info hr {
    border-top-color: #b4bbcf; }
  .alert-info .alert-link {
    color: #090d19; }

.alert-warning {
  color: #856e12;
  background-color: #fff6d3;
  border-color: #fff3c1; }
  .alert-warning hr {
    border-top-color: #ffeea8; }
  .alert-warning .alert-link {
    color: #58490c; }

.alert-danger {
  color: #857c7a;
  background-color: #fffcfb;
  border-color: #fffaf9; }
  .alert-danger hr {
    border-top-color: #ffe5e0; }
  .alert-danger .alert-link {
    color: #6a6362; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #13212a;
  background-color: #d3d9dc;
  border-color: #c2cace; }
  .alert-dark hr {
    border-top-color: #b4bec3; }
  .alert-dark .alert-link {
    color: #030507; }

.alert-error {
  color: #85200f;
  background-color: #ffd8d2;
  border-color: #ffc9c0; }
  .alert-error hr {
    border-top-color: #ffb3a7; }
  .alert-error .alert-link {
    color: #57150a; }

.alert-logo {
  color: #1c222f;
  background-color: #d7d9de;
  border-color: #c6cad1; }
  .alert-logo hr {
    border-top-color: #b8bdc6; }
  .alert-logo .alert-link {
    color: #090b0f; }

.alert-gray {
  color: #3e4650;
  background-color: #e4e7eb;
  border-color: #d9dde2; }
  .alert-gray hr {
    border-top-color: #cbd0d7; }
  .alert-gray .alert-link {
    color: #282d33; }

.alert-bg {
  color: #808081;
  background-color: #fdfdfe;
  border-color: #fdfdfd; }
  .alert-bg hr {
    border-top-color: #f0f0f0; }
  .alert-bg .alert-link {
    color: #676767; }

.alert-title {
  color: #17212e;
  background-color: #d5d9de;
  border-color: #c4c9d0; }
  .alert-title hr {
    border-top-color: #b6bcc5; }
  .alert-title .alert-link {
    color: #06090c; }

.alert-transparent {
  color: rgba(54, 54, 54, 0.688);
  background-color: rgba(255, 255, 255, 0.88);
  border-color: rgba(255, 255, 255, 0.832); }
  .alert-transparent hr {
    border-top-color: rgba(242, 242, 242, 0.832); }
  .alert-transparent .alert-link {
    color: rgba(29, 29, 29, 0.688); }

.alert-mustard {
  color: #74561c;
  background-color: #f9edd7;
  border-color: #f6e6c7; }
  .alert-mustard hr {
    border-top-color: #f2dcb1; }
  .alert-mustard .alert-link {
    color: #4b3812; }

.alert-gold {
  color: #853e1b;
  background-color: #ffe4d6;
  border-color: #ffd9c6; }
  .alert-gold hr {
    border-top-color: #ffc8ad; }
  .alert-gold .alert-link {
    color: #5b2a12; }

.alert-pause {
  color: #7d7f81;
  background-color: #fcfdfe;
  border-color: #fbfcfd; }
  .alert-pause hr {
    border-top-color: #eaeff5; }
  .alert-pause .alert-link {
    color: #646667; }

.alert-cancel {
  color: #70757a;
  background-color: #f7f9fb;
  border-color: #f4f7f9; }
  .alert-cancel hr {
    border-top-color: #e4ebf0; }
  .alert-cancel .alert-link {
    color: #585c5f; }

.alert-input {
  color: #7e7e85;
  background-color: #fcfcff;
  border-color: #fbfbff; }
  .alert-input hr {
    border-top-color: #e2e2ff; }
  .alert-input .alert-link {
    color: #65656b; }

.alert-chars {
  color: #4a680a;
  background-color: #e9f4d0;
  border-color: #e0f0bd; }
  .alert-chars hr {
    border-top-color: #d6eba8; }
  .alert-chars .alert-link {
    color: #293906; }

.alert-incomplete {
  color: #785a00;
  background-color: #faefcc;
  border-color: #f8e8b8; }
  .alert-incomplete hr {
    border-top-color: #f6e0a1; }
  .alert-incomplete .alert-link {
    color: #453400; }

.alert-disabled {
  color: #6f7176;
  background-color: #f7f8f9;
  border-color: #f4f5f7; }
  .alert-disabled hr {
    border-top-color: #e5e8ec; }
  .alert-disabled .alert-link {
    color: #56585c; }

.alert-empty {
  color: #727578;
  background-color: #f8f9fa;
  border-color: #f5f7f8; }
  .alert-empty hr {
    border-top-color: #e6ebee; }
  .alert-empty .alert-link {
    color: #595c5e; }

.alert-incompleteText {
  color: #785900;
  background-color: #faeecc;
  border-color: #f8e8b8; }
  .alert-incompleteText hr {
    border-top-color: #f6e0a1; }
  .alert-incompleteText .alert-link {
    color: #453300; }

.alert-missedBack {
  color: #857c7a;
  background-color: #fffcfb;
  border-color: #fffaf9; }
  .alert-missedBack hr {
    border-top-color: #ffe5e0; }
  .alert-missedBack .alert-link {
    color: #6a6362; }

.alert-missedText {
  color: #85200f;
  background-color: #ffd8d2;
  border-color: #ffc9c0; }
  .alert-missedText hr {
    border-top-color: #ffb3a7; }
  .alert-missedText .alert-link {
    color: #57150a; }

.alert-attendedBack {
  color: #7e836e;
  background-color: #fdfef6;
  border-color: #fcfef3; }
  .alert-attendedBack hr {
    border-top-color: #f6fcdb; }
  .alert-attendedBack .alert-link {
    color: #636757; }

.alert-attendedText {
  color: #435c12;
  background-color: #e6efd3;
  border-color: #dce9c1; }
  .alert-attendedText hr {
    border-top-color: #d1e2ae; }
  .alert-attendedText .alert-link {
    color: #24310a; }

.alert-counseling {
  color: #17212e;
  background-color: #d5d9de;
  border-color: #c4c9d0; }
  .alert-counseling hr {
    border-top-color: #b6bcc5; }
  .alert-counseling .alert-link {
    color: #06090c; }

.alert-wishlist {
  color: #434343;
  background-color: #e6e6e6;
  border-color: #dbdbdb; }
  .alert-wishlist hr {
    border-top-color: #cecece; }
  .alert-wishlist .alert-link {
    color: #2a2a2a; }

.alert-inProgress {
  color: #5e2775;
  background-color: #f0dbf9;
  border-color: #eacdf7; }
  .alert-inProgress hr {
    border-top-color: #e1b7f3; }
  .alert-inProgress .alert-link {
    color: #3f1a4f; }

.alert-applied {
  color: #856e12;
  background-color: #fff6d3;
  border-color: #fff3c1; }
  .alert-applied hr {
    border-top-color: #ffeea8; }
  .alert-applied .alert-link {
    color: #58490c; }

.alert-admitted {
  color: #435c12;
  background-color: #e6efd3;
  border-color: #dce9c1; }
  .alert-admitted hr {
    border-top-color: #d1e2ae; }
  .alert-admitted .alert-link {
    color: #24310a; }

.alert-rejected {
  color: #85200f;
  background-color: #ffd8d2;
  border-color: #ffc9c0; }
  .alert-rejected hr {
    border-top-color: #ffb3a7; }
  .alert-rejected .alert-link {
    color: #57150a; }

.alert-waitlistedDiffered {
  color: #000085;
  background-color: #ccccff;
  border-color: #b8b8ff; }
  .alert-waitlistedDiffered hr {
    border-top-color: #9f9fff; }
  .alert-waitlistedDiffered .alert-link {
    color: #000052; }

.alert-withdrawn {
  color: #434343;
  background-color: #e6e6e6;
  border-color: #dbdbdb; }
  .alert-withdrawn hr {
    border-top-color: #cecece; }
  .alert-withdrawn .alert-link {
    color: #2a2a2a; }

.alert-ielts {
  color: #373885;
  background-color: #e1e2ff;
  border-color: #d5d6ff; }
  .alert-ielts hr {
    border-top-color: #bcbdff; }
  .alert-ielts .alert-link {
    color: #282961; }

.alert-toefl {
  color: #853e1b;
  background-color: #ffe4d6;
  border-color: #ffd9c6; }
  .alert-toefl hr {
    border-top-color: #ffc8ad; }
  .alert-toefl .alert-link {
    color: #5b2a12; }

.alert-sat {
  color: #5e2775;
  background-color: #f0dbf9;
  border-color: #eacdf7; }
  .alert-sat hr {
    border-top-color: #e1b7f3; }
  .alert-sat .alert-link {
    color: #3f1a4f; }

.alert-act {
  color: #1d574d;
  background-color: #d7eeea;
  border-color: #c7e7e1; }
  .alert-act hr {
    border-top-color: #b5dfd7; }
  .alert-act .alert-link {
    color: #10312b; }

.alert-ap {
  color: #853e1b;
  background-color: #ffe4d6;
  border-color: #ffd9c6; }
  .alert-ap hr {
    border-top-color: #ffc8ad; }
  .alert-ap .alert-link {
    color: #5b2a12; }

.alert-duolingo {
  color: #2f3031;
  background-color: #dedfdf;
  border-color: #d1d2d2; }
  .alert-duolingo hr {
    border-top-color: #c4c5c5; }
  .alert-duolingo .alert-link {
    color: #161717; }

.alert-ssat {
  color: #5e2775;
  background-color: #f0dbf9;
  border-color: #eacdf7; }
  .alert-ssat hr {
    border-top-color: #e1b7f3; }
  .alert-ssat .alert-link {
    color: #3f1a4f; }

.alert-gre {
  color: #1d574d;
  background-color: #d7eeea;
  border-color: #c7e7e1; }
  .alert-gre hr {
    border-top-color: #b5dfd7; }
  .alert-gre .alert-link {
    color: #10312b; }

.alert-lsat {
  color: #25161e;
  background-color: #dad5d7;
  border-color: #cbc4c8; }
  .alert-lsat hr {
    border-top-color: #bfb6bb; }
  .alert-lsat .alert-link {
    color: #050304; }

.alert-isee {
  color: #212516;
  background-color: #d9dad5;
  border-color: #c9cbc4; }
  .alert-isee hr {
    border-top-color: #bdbfb6; }
  .alert-isee .alert-link {
    color: #040503; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #696CFF;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #2C3F78;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #696CFF;
    border-color: #696CFF; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #373885;
  background-color: #d5d6ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #373885;
    background-color: #bcbdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #373885;
    border-color: #373885; }

.list-group-item-secondary {
  color: #3b4352;
  background-color: #d8dbe4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3b4352;
    background-color: #c9cdda; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3b4352;
    border-color: #3b4352; }

.list-group-item-success {
  color: #577711;
  background-color: #e6f8c1; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #577711;
    background-color: #ddf5aa; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #577711;
    border-color: #577711; }

.list-group-item-info {
  color: #17213e;
  background-color: #c4c9d9; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #17213e;
    background-color: #b4bbcf; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #17213e;
    border-color: #17213e; }

.list-group-item-warning {
  color: #856e12;
  background-color: #fff3c1; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856e12;
    background-color: #ffeea8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856e12;
    border-color: #856e12; }

.list-group-item-danger {
  color: #857c7a;
  background-color: #fffaf9; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #857c7a;
    background-color: #ffe5e0; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #857c7a;
    border-color: #857c7a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #13212a;
  background-color: #c2cace; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #13212a;
    background-color: #b4bec3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #13212a;
    border-color: #13212a; }

.list-group-item-error {
  color: #85200f;
  background-color: #ffc9c0; }
  .list-group-item-error.list-group-item-action:hover, .list-group-item-error.list-group-item-action:focus {
    color: #85200f;
    background-color: #ffb3a7; }
  .list-group-item-error.list-group-item-action.active {
    color: #fff;
    background-color: #85200f;
    border-color: #85200f; }

.list-group-item-logo {
  color: #1c222f;
  background-color: #c6cad1; }
  .list-group-item-logo.list-group-item-action:hover, .list-group-item-logo.list-group-item-action:focus {
    color: #1c222f;
    background-color: #b8bdc6; }
  .list-group-item-logo.list-group-item-action.active {
    color: #fff;
    background-color: #1c222f;
    border-color: #1c222f; }

.list-group-item-gray {
  color: #3e4650;
  background-color: #d9dde2; }
  .list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
    color: #3e4650;
    background-color: #cbd0d7; }
  .list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #3e4650;
    border-color: #3e4650; }

.list-group-item-bg {
  color: #808081;
  background-color: #fdfdfd; }
  .list-group-item-bg.list-group-item-action:hover, .list-group-item-bg.list-group-item-action:focus {
    color: #808081;
    background-color: #f0f0f0; }
  .list-group-item-bg.list-group-item-action.active {
    color: #fff;
    background-color: #808081;
    border-color: #808081; }

.list-group-item-title {
  color: #17212e;
  background-color: #c4c9d0; }
  .list-group-item-title.list-group-item-action:hover, .list-group-item-title.list-group-item-action:focus {
    color: #17212e;
    background-color: #b6bcc5; }
  .list-group-item-title.list-group-item-action.active {
    color: #fff;
    background-color: #17212e;
    border-color: #17212e; }

.list-group-item-transparent {
  color: rgba(54, 54, 54, 0.688);
  background-color: rgba(255, 255, 255, 0.832); }
  .list-group-item-transparent.list-group-item-action:hover, .list-group-item-transparent.list-group-item-action:focus {
    color: rgba(54, 54, 54, 0.688);
    background-color: rgba(242, 242, 242, 0.832); }
  .list-group-item-transparent.list-group-item-action.active {
    color: #fff;
    background-color: rgba(54, 54, 54, 0.688);
    border-color: rgba(54, 54, 54, 0.688); }

.list-group-item-mustard {
  color: #74561c;
  background-color: #f6e6c7; }
  .list-group-item-mustard.list-group-item-action:hover, .list-group-item-mustard.list-group-item-action:focus {
    color: #74561c;
    background-color: #f2dcb1; }
  .list-group-item-mustard.list-group-item-action.active {
    color: #fff;
    background-color: #74561c;
    border-color: #74561c; }

.list-group-item-gold {
  color: #853e1b;
  background-color: #ffd9c6; }
  .list-group-item-gold.list-group-item-action:hover, .list-group-item-gold.list-group-item-action:focus {
    color: #853e1b;
    background-color: #ffc8ad; }
  .list-group-item-gold.list-group-item-action.active {
    color: #fff;
    background-color: #853e1b;
    border-color: #853e1b; }

.list-group-item-pause {
  color: #7d7f81;
  background-color: #fbfcfd; }
  .list-group-item-pause.list-group-item-action:hover, .list-group-item-pause.list-group-item-action:focus {
    color: #7d7f81;
    background-color: #eaeff5; }
  .list-group-item-pause.list-group-item-action.active {
    color: #fff;
    background-color: #7d7f81;
    border-color: #7d7f81; }

.list-group-item-cancel {
  color: #70757a;
  background-color: #f4f7f9; }
  .list-group-item-cancel.list-group-item-action:hover, .list-group-item-cancel.list-group-item-action:focus {
    color: #70757a;
    background-color: #e4ebf0; }
  .list-group-item-cancel.list-group-item-action.active {
    color: #fff;
    background-color: #70757a;
    border-color: #70757a; }

.list-group-item-input {
  color: #7e7e85;
  background-color: #fbfbff; }
  .list-group-item-input.list-group-item-action:hover, .list-group-item-input.list-group-item-action:focus {
    color: #7e7e85;
    background-color: #e2e2ff; }
  .list-group-item-input.list-group-item-action.active {
    color: #fff;
    background-color: #7e7e85;
    border-color: #7e7e85; }

.list-group-item-chars {
  color: #4a680a;
  background-color: #e0f0bd; }
  .list-group-item-chars.list-group-item-action:hover, .list-group-item-chars.list-group-item-action:focus {
    color: #4a680a;
    background-color: #d6eba8; }
  .list-group-item-chars.list-group-item-action.active {
    color: #fff;
    background-color: #4a680a;
    border-color: #4a680a; }

.list-group-item-incomplete {
  color: #785a00;
  background-color: #f8e8b8; }
  .list-group-item-incomplete.list-group-item-action:hover, .list-group-item-incomplete.list-group-item-action:focus {
    color: #785a00;
    background-color: #f6e0a1; }
  .list-group-item-incomplete.list-group-item-action.active {
    color: #fff;
    background-color: #785a00;
    border-color: #785a00; }

.list-group-item-disabled {
  color: #6f7176;
  background-color: #f4f5f7; }
  .list-group-item-disabled.list-group-item-action:hover, .list-group-item-disabled.list-group-item-action:focus {
    color: #6f7176;
    background-color: #e5e8ec; }
  .list-group-item-disabled.list-group-item-action.active {
    color: #fff;
    background-color: #6f7176;
    border-color: #6f7176; }

.list-group-item-empty {
  color: #727578;
  background-color: #f5f7f8; }
  .list-group-item-empty.list-group-item-action:hover, .list-group-item-empty.list-group-item-action:focus {
    color: #727578;
    background-color: #e6ebee; }
  .list-group-item-empty.list-group-item-action.active {
    color: #fff;
    background-color: #727578;
    border-color: #727578; }

.list-group-item-incompleteText {
  color: #785900;
  background-color: #f8e8b8; }
  .list-group-item-incompleteText.list-group-item-action:hover, .list-group-item-incompleteText.list-group-item-action:focus {
    color: #785900;
    background-color: #f6e0a1; }
  .list-group-item-incompleteText.list-group-item-action.active {
    color: #fff;
    background-color: #785900;
    border-color: #785900; }

.list-group-item-missedBack {
  color: #857c7a;
  background-color: #fffaf9; }
  .list-group-item-missedBack.list-group-item-action:hover, .list-group-item-missedBack.list-group-item-action:focus {
    color: #857c7a;
    background-color: #ffe5e0; }
  .list-group-item-missedBack.list-group-item-action.active {
    color: #fff;
    background-color: #857c7a;
    border-color: #857c7a; }

.list-group-item-missedText {
  color: #85200f;
  background-color: #ffc9c0; }
  .list-group-item-missedText.list-group-item-action:hover, .list-group-item-missedText.list-group-item-action:focus {
    color: #85200f;
    background-color: #ffb3a7; }
  .list-group-item-missedText.list-group-item-action.active {
    color: #fff;
    background-color: #85200f;
    border-color: #85200f; }

.list-group-item-attendedBack {
  color: #7e836e;
  background-color: #fcfef3; }
  .list-group-item-attendedBack.list-group-item-action:hover, .list-group-item-attendedBack.list-group-item-action:focus {
    color: #7e836e;
    background-color: #f6fcdb; }
  .list-group-item-attendedBack.list-group-item-action.active {
    color: #fff;
    background-color: #7e836e;
    border-color: #7e836e; }

.list-group-item-attendedText {
  color: #435c12;
  background-color: #dce9c1; }
  .list-group-item-attendedText.list-group-item-action:hover, .list-group-item-attendedText.list-group-item-action:focus {
    color: #435c12;
    background-color: #d1e2ae; }
  .list-group-item-attendedText.list-group-item-action.active {
    color: #fff;
    background-color: #435c12;
    border-color: #435c12; }

.list-group-item-counseling {
  color: #17212e;
  background-color: #c4c9d0; }
  .list-group-item-counseling.list-group-item-action:hover, .list-group-item-counseling.list-group-item-action:focus {
    color: #17212e;
    background-color: #b6bcc5; }
  .list-group-item-counseling.list-group-item-action.active {
    color: #fff;
    background-color: #17212e;
    border-color: #17212e; }

.list-group-item-wishlist {
  color: #434343;
  background-color: #dbdbdb; }
  .list-group-item-wishlist.list-group-item-action:hover, .list-group-item-wishlist.list-group-item-action:focus {
    color: #434343;
    background-color: #cecece; }
  .list-group-item-wishlist.list-group-item-action.active {
    color: #fff;
    background-color: #434343;
    border-color: #434343; }

.list-group-item-inProgress {
  color: #5e2775;
  background-color: #eacdf7; }
  .list-group-item-inProgress.list-group-item-action:hover, .list-group-item-inProgress.list-group-item-action:focus {
    color: #5e2775;
    background-color: #e1b7f3; }
  .list-group-item-inProgress.list-group-item-action.active {
    color: #fff;
    background-color: #5e2775;
    border-color: #5e2775; }

.list-group-item-applied {
  color: #856e12;
  background-color: #fff3c1; }
  .list-group-item-applied.list-group-item-action:hover, .list-group-item-applied.list-group-item-action:focus {
    color: #856e12;
    background-color: #ffeea8; }
  .list-group-item-applied.list-group-item-action.active {
    color: #fff;
    background-color: #856e12;
    border-color: #856e12; }

.list-group-item-admitted {
  color: #435c12;
  background-color: #dce9c1; }
  .list-group-item-admitted.list-group-item-action:hover, .list-group-item-admitted.list-group-item-action:focus {
    color: #435c12;
    background-color: #d1e2ae; }
  .list-group-item-admitted.list-group-item-action.active {
    color: #fff;
    background-color: #435c12;
    border-color: #435c12; }

.list-group-item-rejected {
  color: #85200f;
  background-color: #ffc9c0; }
  .list-group-item-rejected.list-group-item-action:hover, .list-group-item-rejected.list-group-item-action:focus {
    color: #85200f;
    background-color: #ffb3a7; }
  .list-group-item-rejected.list-group-item-action.active {
    color: #fff;
    background-color: #85200f;
    border-color: #85200f; }

.list-group-item-waitlistedDiffered {
  color: #000085;
  background-color: #b8b8ff; }
  .list-group-item-waitlistedDiffered.list-group-item-action:hover, .list-group-item-waitlistedDiffered.list-group-item-action:focus {
    color: #000085;
    background-color: #9f9fff; }
  .list-group-item-waitlistedDiffered.list-group-item-action.active {
    color: #fff;
    background-color: #000085;
    border-color: #000085; }

.list-group-item-withdrawn {
  color: #434343;
  background-color: #dbdbdb; }
  .list-group-item-withdrawn.list-group-item-action:hover, .list-group-item-withdrawn.list-group-item-action:focus {
    color: #434343;
    background-color: #cecece; }
  .list-group-item-withdrawn.list-group-item-action.active {
    color: #fff;
    background-color: #434343;
    border-color: #434343; }

.list-group-item-ielts {
  color: #373885;
  background-color: #d5d6ff; }
  .list-group-item-ielts.list-group-item-action:hover, .list-group-item-ielts.list-group-item-action:focus {
    color: #373885;
    background-color: #bcbdff; }
  .list-group-item-ielts.list-group-item-action.active {
    color: #fff;
    background-color: #373885;
    border-color: #373885; }

.list-group-item-toefl {
  color: #853e1b;
  background-color: #ffd9c6; }
  .list-group-item-toefl.list-group-item-action:hover, .list-group-item-toefl.list-group-item-action:focus {
    color: #853e1b;
    background-color: #ffc8ad; }
  .list-group-item-toefl.list-group-item-action.active {
    color: #fff;
    background-color: #853e1b;
    border-color: #853e1b; }

.list-group-item-sat {
  color: #5e2775;
  background-color: #eacdf7; }
  .list-group-item-sat.list-group-item-action:hover, .list-group-item-sat.list-group-item-action:focus {
    color: #5e2775;
    background-color: #e1b7f3; }
  .list-group-item-sat.list-group-item-action.active {
    color: #fff;
    background-color: #5e2775;
    border-color: #5e2775; }

.list-group-item-act {
  color: #1d574d;
  background-color: #c7e7e1; }
  .list-group-item-act.list-group-item-action:hover, .list-group-item-act.list-group-item-action:focus {
    color: #1d574d;
    background-color: #b5dfd7; }
  .list-group-item-act.list-group-item-action.active {
    color: #fff;
    background-color: #1d574d;
    border-color: #1d574d; }

.list-group-item-ap {
  color: #853e1b;
  background-color: #ffd9c6; }
  .list-group-item-ap.list-group-item-action:hover, .list-group-item-ap.list-group-item-action:focus {
    color: #853e1b;
    background-color: #ffc8ad; }
  .list-group-item-ap.list-group-item-action.active {
    color: #fff;
    background-color: #853e1b;
    border-color: #853e1b; }

.list-group-item-duolingo {
  color: #2f3031;
  background-color: #d1d2d2; }
  .list-group-item-duolingo.list-group-item-action:hover, .list-group-item-duolingo.list-group-item-action:focus {
    color: #2f3031;
    background-color: #c4c5c5; }
  .list-group-item-duolingo.list-group-item-action.active {
    color: #fff;
    background-color: #2f3031;
    border-color: #2f3031; }

.list-group-item-ssat {
  color: #5e2775;
  background-color: #eacdf7; }
  .list-group-item-ssat.list-group-item-action:hover, .list-group-item-ssat.list-group-item-action:focus {
    color: #5e2775;
    background-color: #e1b7f3; }
  .list-group-item-ssat.list-group-item-action.active {
    color: #fff;
    background-color: #5e2775;
    border-color: #5e2775; }

.list-group-item-gre {
  color: #1d574d;
  background-color: #c7e7e1; }
  .list-group-item-gre.list-group-item-action:hover, .list-group-item-gre.list-group-item-action:focus {
    color: #1d574d;
    background-color: #b5dfd7; }
  .list-group-item-gre.list-group-item-action.active {
    color: #fff;
    background-color: #1d574d;
    border-color: #1d574d; }

.list-group-item-lsat {
  color: #25161e;
  background-color: #cbc4c8; }
  .list-group-item-lsat.list-group-item-action:hover, .list-group-item-lsat.list-group-item-action:focus {
    color: #25161e;
    background-color: #bfb6bb; }
  .list-group-item-lsat.list-group-item-action.active {
    color: #fff;
    background-color: #25161e;
    border-color: #25161e; }

.list-group-item-isee {
  color: #212516;
  background-color: #c9cbc4; }
  .list-group-item-isee.list-group-item-action:hover, .list-group-item-isee.list-group-item-action:focus {
    color: #212516;
    background-color: #bdbfb6; }
  .list-group-item-isee.list-group-item-action.active {
    color: #fff;
    background-color: #212516;
    border-color: #212516; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #2C3F78;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2C3F78; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #696CFF !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #363aff !important; }

.bg-secondary {
  background-color: #72809d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5a6782 !important; }

.bg-success {
  background-color: #A7E521 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #88bd16 !important; }

.bg-info {
  background-color: #2C3F78 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1e2b53 !important; }

.bg-warning {
  background-color: #FFD422 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #eec000 !important; }

.bg-danger {
  background-color: #FFEEEB !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ffc3b8 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #254050 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #15242d !important; }

.bg-error {
  background-color: #FF3E1D !important; }

a.bg-error:hover, a.bg-error:focus,
button.bg-error:hover,
button.bg-error:focus {
  background-color: #e92200 !important; }

.bg-logo {
  background-color: #35425B !important; }

a.bg-logo:hover, a.bg-logo:focus,
button.bg-logo:hover,
button.bg-logo:focus {
  background-color: #222b3b !important; }

.bg-gray {
  background-color: #778699 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #5f6d7e !important; }

.bg-bg {
  background-color: #F7F7F9 !important; }

a.bg-bg:hover, a.bg-bg:focus,
button.bg-bg:hover,
button.bg-bg:focus {
  background-color: #dadae3 !important; }

.bg-title {
  background-color: #2C3F58 !important; }

a.bg-title:hover, a.bg-title:focus,
button.bg-title:hover,
button.bg-title:focus {
  background-color: #1b2736 !important; }

.bg-transparent {
  background-color: rgba(255, 255, 255, 0.4) !important; }

a.bg-transparent:hover, a.bg-transparent:focus,
button.bg-transparent:hover,
button.bg-transparent:focus {
  background-color: rgba(230, 230, 230, 0.4) !important; }

.bg-mustard {
  background-color: #DFA536 !important; }

a.bg-mustard:hover, a.bg-mustard:focus,
button.bg-mustard:hover,
button.bg-mustard:focus {
  background-color: #c38b1f !important; }

.bg-gold {
  background-color: #FF7733 !important; }

a.bg-gold:hover, a.bg-gold:focus,
button.bg-gold:hover,
button.bg-gold:focus {
  background-color: #ff5500 !important; }

.bg-pause {
  background-color: #F0F4F8 !important; }

a.bg-pause:hover, a.bg-pause:focus,
button.bg-pause:hover,
button.bg-pause:focus {
  background-color: #cddbe8 !important; }

.bg-cancel {
  background-color: #D7E1EA !important; }

a.bg-cancel:hover, a.bg-cancel:focus,
button.bg-cancel:hover,
button.bg-cancel:focus {
  background-color: #b6c8d8 !important; }

.bg-input {
  background-color: #F2F2FF !important; }

a.bg-input:hover, a.bg-input:focus,
button.bg-input:hover,
button.bg-input:focus {
  background-color: #bfbfff !important; }

.bg-chars {
  background-color: #8FC813 !important; }

a.bg-chars:hover, a.bg-chars:focus,
button.bg-chars:hover,
button.bg-chars:focus {
  background-color: #6e990f !important; }

.bg-incomplete {
  background-color: #E7AD00 !important; }

a.bg-incomplete:hover, a.bg-incomplete:focus,
button.bg-incomplete:hover,
button.bg-incomplete:focus {
  background-color: #b48700 !important; }

.bg-disabled {
  background-color: #D6DAE3 !important; }

a.bg-disabled:hover, a.bg-disabled:focus,
button.bg-disabled:hover,
button.bg-disabled:focus {
  background-color: #b8bfce !important; }

.bg-empty {
  background-color: #DCE1E7 !important; }

a.bg-empty:hover, a.bg-empty:focus,
button.bg-empty:hover,
button.bg-empty:focus {
  background-color: #bec7d2 !important; }

.bg-incompleteText {
  background-color: #E6AC00 !important; }

a.bg-incompleteText:hover, a.bg-incompleteText:focus,
button.bg-incompleteText:hover,
button.bg-incompleteText:focus {
  background-color: #b38600 !important; }

.bg-missedBack {
  background-color: #FFEEEB !important; }

a.bg-missedBack:hover, a.bg-missedBack:focus,
button.bg-missedBack:hover,
button.bg-missedBack:focus {
  background-color: #ffc3b8 !important; }

.bg-missedText {
  background-color: #FF3E1D !important; }

a.bg-missedText:hover, a.bg-missedText:focus,
button.bg-missedText:hover,
button.bg-missedText:focus {
  background-color: #e92200 !important; }

.bg-attendedBack {
  background-color: #F3FCD4 !important; }

a.bg-attendedBack:hover, a.bg-attendedBack:focus,
button.bg-attendedBack:hover,
button.bg-attendedBack:focus {
  background-color: #e6f9a4 !important; }

.bg-attendedText {
  background-color: #81B023 !important; }

a.bg-attendedText:hover, a.bg-attendedText:focus,
button.bg-attendedText:hover,
button.bg-attendedText:focus {
  background-color: #62851b !important; }

.bg-counseling {
  background-color: #2c3f58 !important; }

a.bg-counseling:hover, a.bg-counseling:focus,
button.bg-counseling:hover,
button.bg-counseling:focus {
  background-color: #1b2736 !important; }

.bg-wishlist {
  background-color: grey !important; }

a.bg-wishlist:hover, a.bg-wishlist:focus,
button.bg-wishlist:hover,
button.bg-wishlist:focus {
  background-color: #676767 !important; }

.bg-inProgress {
  background-color: #B44BE1 !important; }

a.bg-inProgress:hover, a.bg-inProgress:focus,
button.bg-inProgress:hover,
button.bg-inProgress:focus {
  background-color: #a024d5 !important; }

.bg-applied {
  background-color: #ffd322 !important; }

a.bg-applied:hover, a.bg-applied:focus,
button.bg-applied:hover,
button.bg-applied:focus {
  background-color: #eebf00 !important; }

.bg-admitted {
  background-color: #81B023 !important; }

a.bg-admitted:hover, a.bg-admitted:focus,
button.bg-admitted:hover,
button.bg-admitted:focus {
  background-color: #62851b !important; }

.bg-rejected {
  background-color: #FF3E1D !important; }

a.bg-rejected:hover, a.bg-rejected:focus,
button.bg-rejected:hover,
button.bg-rejected:focus {
  background-color: #e92200 !important; }

.bg-waitlistedDiffered {
  background-color: blue !important; }

a.bg-waitlistedDiffered:hover, a.bg-waitlistedDiffered:focus,
button.bg-waitlistedDiffered:hover,
button.bg-waitlistedDiffered:focus {
  background-color: #0000cc !important; }

.bg-withdrawn {
  background-color: gray !important; }

a.bg-withdrawn:hover, a.bg-withdrawn:focus,
button.bg-withdrawn:hover,
button.bg-withdrawn:focus {
  background-color: #676767 !important; }

.bg-ielts {
  background-color: #696CFF !important; }

a.bg-ielts:hover, a.bg-ielts:focus,
button.bg-ielts:hover,
button.bg-ielts:focus {
  background-color: #363aff !important; }

.bg-toefl {
  background-color: #FF7733 !important; }

a.bg-toefl:hover, a.bg-toefl:focus,
button.bg-toefl:hover,
button.bg-toefl:focus {
  background-color: #ff5500 !important; }

.bg-sat {
  background-color: #B44BE1 !important; }

a.bg-sat:hover, a.bg-sat:focus,
button.bg-sat:hover,
button.bg-sat:focus {
  background-color: #a024d5 !important; }

.bg-act {
  background-color: #38A895 !important; }

a.bg-act:hover, a.bg-act:focus,
button.bg-act:hover,
button.bg-act:focus {
  background-color: #2b8273 !important; }

.bg-ap {
  background-color: #FF7733 !important; }

a.bg-ap:hover, a.bg-ap:focus,
button.bg-ap:hover,
button.bg-ap:focus {
  background-color: #ff5500 !important; }

.bg-duolingo {
  background-color: #5B5D5F !important; }

a.bg-duolingo:hover, a.bg-duolingo:focus,
button.bg-duolingo:hover,
button.bg-duolingo:focus {
  background-color: #424445 !important; }

.bg-ssat {
  background-color: #B44BE1 !important; }

a.bg-ssat:hover, a.bg-ssat:focus,
button.bg-ssat:hover,
button.bg-ssat:focus {
  background-color: #a024d5 !important; }

.bg-gre {
  background-color: #38A895 !important; }

a.bg-gre:hover, a.bg-gre:focus,
button.bg-gre:hover,
button.bg-gre:focus {
  background-color: #2b8273 !important; }

.bg-lsat {
  background-color: #472B39 !important; }

a.bg-lsat:hover, a.bg-lsat:focus,
button.bg-lsat:hover,
button.bg-lsat:focus {
  background-color: #271820 !important; }

.bg-isee {
  background-color: #3F472B !important; }

a.bg-isee:hover, a.bg-isee:focus,
button.bg-isee:hover,
button.bg-isee:focus {
  background-color: #232718 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #696CFF !important; }

.border-secondary {
  border-color: #72809d !important; }

.border-success {
  border-color: #A7E521 !important; }

.border-info {
  border-color: #2C3F78 !important; }

.border-warning {
  border-color: #FFD422 !important; }

.border-danger {
  border-color: #FFEEEB !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #254050 !important; }

.border-error {
  border-color: #FF3E1D !important; }

.border-logo {
  border-color: #35425B !important; }

.border-gray {
  border-color: #778699 !important; }

.border-bg {
  border-color: #F7F7F9 !important; }

.border-title {
  border-color: #2C3F58 !important; }

.border-transparent {
  border-color: rgba(255, 255, 255, 0.4) !important; }

.border-mustard {
  border-color: #DFA536 !important; }

.border-gold {
  border-color: #FF7733 !important; }

.border-pause {
  border-color: #F0F4F8 !important; }

.border-cancel {
  border-color: #D7E1EA !important; }

.border-input {
  border-color: #F2F2FF !important; }

.border-chars {
  border-color: #8FC813 !important; }

.border-incomplete {
  border-color: #E7AD00 !important; }

.border-disabled {
  border-color: #D6DAE3 !important; }

.border-empty {
  border-color: #DCE1E7 !important; }

.border-incompleteText {
  border-color: #E6AC00 !important; }

.border-missedBack {
  border-color: #FFEEEB !important; }

.border-missedText {
  border-color: #FF3E1D !important; }

.border-attendedBack {
  border-color: #F3FCD4 !important; }

.border-attendedText {
  border-color: #81B023 !important; }

.border-counseling {
  border-color: #2c3f58 !important; }

.border-wishlist {
  border-color: grey !important; }

.border-inProgress {
  border-color: #B44BE1 !important; }

.border-applied {
  border-color: #ffd322 !important; }

.border-admitted {
  border-color: #81B023 !important; }

.border-rejected {
  border-color: #FF3E1D !important; }

.border-waitlistedDiffered {
  border-color: blue !important; }

.border-withdrawn {
  border-color: gray !important; }

.border-ielts {
  border-color: #696CFF !important; }

.border-toefl {
  border-color: #FF7733 !important; }

.border-sat {
  border-color: #B44BE1 !important; }

.border-act {
  border-color: #38A895 !important; }

.border-ap {
  border-color: #FF7733 !important; }

.border-duolingo {
  border-color: #5B5D5F !important; }

.border-ssat {
  border-color: #B44BE1 !important; }

.border-gre {
  border-color: #38A895 !important; }

.border-lsat {
  border-color: #472B39 !important; }

.border-isee {
  border-color: #3F472B !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 0.75rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.75rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important; }

.m-4 {
  margin: 1rem !important; }

.mt-4,
.my-4 {
  margin-top: 1rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1rem !important; }

.m-5 {
  margin: 1.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 1.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important; }

.m-6 {
  margin: 2rem !important; }

.mt-6,
.my-6 {
  margin-top: 2rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2rem !important; }

.m-7 {
  margin: 2.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 2.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 2.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 2.5rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 7.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 7.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 7.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 7.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 7.5rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10,
.my-10 {
  margin-top: 10rem !important; }

.mr-10,
.mx-10 {
  margin-right: 10rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 10rem !important; }

.ml-10,
.mx-10 {
  margin-left: 10rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 0.75rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.75rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.75rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.75rem !important; }

.p-4 {
  padding: 1rem !important; }

.pt-4,
.py-4 {
  padding-top: 1rem !important; }

.pr-4,
.px-4 {
  padding-right: 1rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1rem !important; }

.pl-4,
.px-4 {
  padding-left: 1rem !important; }

.p-5 {
  padding: 1.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 1.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 1.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 1.5rem !important; }

.p-6 {
  padding: 2rem !important; }

.pt-6,
.py-6 {
  padding-top: 2rem !important; }

.pr-6,
.px-6 {
  padding-right: 2rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2rem !important; }

.pl-6,
.px-6 {
  padding-left: 2rem !important; }

.p-7 {
  padding: 2.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 2.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 2.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 2.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 2.5rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 7.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 7.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 7.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 7.5rem !important; }

.pl-9,
.px-9 {
  padding-left: 7.5rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10,
.py-10 {
  padding-top: 10rem !important; }

.pr-10,
.px-10 {
  padding-right: 10rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 10rem !important; }

.pl-10,
.px-10 {
  padding-left: 10rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -0.75rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important; }

.m-n4 {
  margin: -1rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important; }

.m-n5 {
  margin: -1.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important; }

.m-n6 {
  margin: -2rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -2rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important; }

.m-n7 {
  margin: -2.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -2.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -2.5rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -7.5rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -7.5rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -7.5rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -7.5rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -7.5rem !important; }

.m-n10 {
  margin: -10rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -10rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 0.75rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important; }
  .m-sm-4 {
    margin: 1rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important; }
  .m-sm-5 {
    margin: 1.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important; }
  .m-sm-6 {
    margin: 2rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important; }
  .m-sm-7 {
    margin: 2.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 2.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 2.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 2.5rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 7.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7.5rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 0.75rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important; }
  .p-sm-4 {
    padding: 1rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important; }
  .p-sm-5 {
    padding: 1.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important; }
  .p-sm-6 {
    padding: 2rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important; }
  .p-sm-7 {
    padding: 2.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 2.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 2.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 2.5rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 7.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7.5rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -0.75rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important; }
  .m-sm-n4 {
    margin: -1rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1rem !important; }
  .m-sm-n5 {
    margin: -1.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.5rem !important; }
  .m-sm-n6 {
    margin: -2rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2rem !important; }
  .m-sm-n7 {
    margin: -2.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -2.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -2.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -2.5rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -7.5rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -7.5rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -7.5rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -7.5rem !important; }
  .m-sm-n10 {
    margin: -10rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 0.75rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important; }
  .m-md-4 {
    margin: 1rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important; }
  .m-md-5 {
    margin: 1.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important; }
  .m-md-6 {
    margin: 2rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2rem !important; }
  .m-md-7 {
    margin: 2.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 2.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 2.5rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 7.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7.5rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 0.75rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important; }
  .p-md-4 {
    padding: 1rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important; }
  .p-md-5 {
    padding: 1.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important; }
  .p-md-6 {
    padding: 2rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2rem !important; }
  .p-md-7 {
    padding: 2.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 2.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 2.5rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 7.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7.5rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7.5rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -0.75rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important; }
  .m-md-n4 {
    margin: -1rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important; }
  .m-md-n5 {
    margin: -1.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important; }
  .m-md-n6 {
    margin: -2rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important; }
  .m-md-n7 {
    margin: -2.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -2.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -2.5rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -7.5rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -7.5rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -7.5rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -7.5rem !important; }
  .m-md-n10 {
    margin: -10rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 0.75rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important; }
  .m-lg-4 {
    margin: 1rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important; }
  .m-lg-5 {
    margin: 1.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important; }
  .m-lg-6 {
    margin: 2rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important; }
  .m-lg-7 {
    margin: 2.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 2.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 2.5rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 7.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7.5rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 0.75rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important; }
  .p-lg-4 {
    padding: 1rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important; }
  .p-lg-5 {
    padding: 1.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important; }
  .p-lg-6 {
    padding: 2rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important; }
  .p-lg-7 {
    padding: 2.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 2.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 2.5rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 7.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7.5rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -0.75rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important; }
  .m-lg-n4 {
    margin: -1rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important; }
  .m-lg-n5 {
    margin: -1.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important; }
  .m-lg-n6 {
    margin: -2rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important; }
  .m-lg-n7 {
    margin: -2.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.5rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -7.5rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -7.5rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -7.5rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -7.5rem !important; }
  .m-lg-n10 {
    margin: -10rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 0.75rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important; }
  .m-xl-4 {
    margin: 1rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important; }
  .m-xl-5 {
    margin: 1.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important; }
  .m-xl-6 {
    margin: 2rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important; }
  .m-xl-7 {
    margin: 2.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 2.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 2.5rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 7.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7.5rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 0.75rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important; }
  .p-xl-4 {
    padding: 1rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important; }
  .p-xl-5 {
    padding: 1.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important; }
  .p-xl-6 {
    padding: 2rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important; }
  .p-xl-7 {
    padding: 2.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 2.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 2.5rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 7.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7.5rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -0.75rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important; }
  .m-xl-n4 {
    margin: -1rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important; }
  .m-xl-n5 {
    margin: -1.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important; }
  .m-xl-n6 {
    margin: -2rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important; }
  .m-xl-n7 {
    margin: -2.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.5rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -7.5rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -7.5rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -7.5rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -7.5rem !important; }
  .m-xl-n10 {
    margin: -10rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 400 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 500 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #696CFF !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1d21ff !important; }

.text-secondary {
  color: #72809d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #505b73 !important; }

.text-success {
  color: #A7E521 !important; }

a.text-success:hover, a.text-success:focus {
  color: #78a613 !important; }

.text-info {
  color: #2C3F78 !important; }

a.text-info:hover, a.text-info:focus {
  color: #172240 !important; }

.text-warning {
  color: #FFD422 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d5ab00 !important; }

.text-danger {
  color: #FFEEEB !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ffad9f !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #254050 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d161c !important; }

.text-error {
  color: #FF3E1D !important; }

a.text-error:hover, a.text-error:focus {
  color: #d01e00 !important; }

.text-logo {
  color: #35425B !important; }

a.text-logo:hover, a.text-logo:focus {
  color: #191f2b !important; }

.text-gray {
  color: #778699 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #546070 !important; }

.text-bg {
  color: #F7F7F9 !important; }

a.text-bg:hover, a.text-bg:focus {
  color: #cbcbd8 !important; }

.text-title {
  color: #2C3F58 !important; }

a.text-title:hover, a.text-title:focus {
  color: #131a25 !important; }

.text-transparent {
  color: rgba(255, 255, 255, 0.4) !important; }

a.text-transparent:hover, a.text-transparent:focus {
  color: rgba(217, 217, 217, 0.4) !important; }

.text-mustard {
  color: #DFA536 !important; }

a.text-mustard:hover, a.text-mustard:focus {
  color: #ad7b1c !important; }

.text-gold {
  color: #FF7733 !important; }

a.text-gold:hover, a.text-gold:focus {
  color: #e64d00 !important; }

.text-pause {
  color: #F0F4F8 !important; }

a.text-pause:hover, a.text-pause:focus {
  color: #bccee0 !important; }

.text-cancel {
  color: #D7E1EA !important; }

a.text-cancel:hover, a.text-cancel:focus {
  color: #a5bbd0 !important; }

.text-input {
  color: #F2F2FF !important; }

a.text-input:hover, a.text-input:focus {
  color: #a6a6ff !important; }

.text-chars {
  color: #8FC813 !important; }

a.text-chars:hover, a.text-chars:focus {
  color: #5d820c !important; }

.text-incomplete {
  color: #E7AD00 !important; }

a.text-incomplete:hover, a.text-incomplete:focus {
  color: #9b7400 !important; }

.text-disabled {
  color: #D6DAE3 !important; }

a.text-disabled:hover, a.text-disabled:focus {
  color: #a9b1c4 !important; }

.text-empty {
  color: #DCE1E7 !important; }

a.text-empty:hover, a.text-empty:focus {
  color: #afbac8 !important; }

.text-incompleteText {
  color: #E6AC00 !important; }

a.text-incompleteText:hover, a.text-incompleteText:focus {
  color: #9a7300 !important; }

.text-missedBack {
  color: #FFEEEB !important; }

a.text-missedBack:hover, a.text-missedBack:focus {
  color: #ffad9f !important; }

.text-missedText {
  color: #FF3E1D !important; }

a.text-missedText:hover, a.text-missedText:focus {
  color: #d01e00 !important; }

.text-attendedBack {
  color: #F3FCD4 !important; }

a.text-attendedBack:hover, a.text-attendedBack:focus {
  color: #dff78c !important; }

.text-attendedText {
  color: #81B023 !important; }

a.text-attendedText:hover, a.text-attendedText:focus {
  color: #527016 !important; }

.text-counseling {
  color: #2c3f58 !important; }

a.text-counseling:hover, a.text-counseling:focus {
  color: #131a25 !important; }

.text-wishlist {
  color: grey !important; }

a.text-wishlist:hover, a.text-wishlist:focus {
  color: #5a5a5a !important; }

.text-inProgress {
  color: #B44BE1 !important; }

a.text-inProgress:hover, a.text-inProgress:focus {
  color: #9020c0 !important; }

.text-applied {
  color: #ffd322 !important; }

a.text-applied:hover, a.text-applied:focus {
  color: #d5aa00 !important; }

.text-admitted {
  color: #81B023 !important; }

a.text-admitted:hover, a.text-admitted:focus {
  color: #527016 !important; }

.text-rejected {
  color: #FF3E1D !important; }

a.text-rejected:hover, a.text-rejected:focus {
  color: #d01e00 !important; }

.text-waitlistedDiffered {
  color: blue !important; }

a.text-waitlistedDiffered:hover, a.text-waitlistedDiffered:focus {
  color: #0000b3 !important; }

.text-withdrawn {
  color: gray !important; }

a.text-withdrawn:hover, a.text-withdrawn:focus {
  color: #5a5a5a !important; }

.text-ielts {
  color: #696CFF !important; }

a.text-ielts:hover, a.text-ielts:focus {
  color: #1d21ff !important; }

.text-toefl {
  color: #FF7733 !important; }

a.text-toefl:hover, a.text-toefl:focus {
  color: #e64d00 !important; }

.text-sat {
  color: #B44BE1 !important; }

a.text-sat:hover, a.text-sat:focus {
  color: #9020c0 !important; }

.text-act {
  color: #38A895 !important; }

a.text-act:hover, a.text-act:focus {
  color: #256f62 !important; }

.text-ap {
  color: #FF7733 !important; }

a.text-ap:hover, a.text-ap:focus {
  color: #e64d00 !important; }

.text-duolingo {
  color: #5B5D5F !important; }

a.text-duolingo:hover, a.text-duolingo:focus {
  color: #363738 !important; }

.text-ssat {
  color: #B44BE1 !important; }

a.text-ssat:hover, a.text-ssat:focus {
  color: #9020c0 !important; }

.text-gre {
  color: #38A895 !important; }

a.text-gre:hover, a.text-gre:focus {
  color: #256f62 !important; }

.text-lsat {
  color: #472B39 !important; }

a.text-lsat:hover, a.text-lsat:focus {
  color: #170e13 !important; }

.text-isee {
  color: #3F472B !important; }

a.text-isee:hover, a.text-isee:focus {
  color: #15170e !important; }

.text-body {
  color: #2C3F78 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.znk-zones {
  max-height: 350px;
  overflow-y: auto;
  scrollbar-width: none; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-new-message-badge {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .znk-new-message-badge span {
    background-color: #2c3f58;
    color: white;
    border-radius: 12px;
    cursor: pointer;
    padding: 0px 10px;
    text-align: center; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-sidebar {
  margin: auto 0 auto auto;
  height: 100%;
  display: flex; }
  .modal-sidebar.fixed-head-modal {
    max-height: 100vh; }
  .modal.fade .modal-sidebar {
    transform: translate(0, 50px); }
    @media (min-width: 768px) {
      .modal.fade .modal-sidebar {
        transform: translate(467px, 0); } }
  .modal.show .modal-sidebar {
    transform: none; }
  @media (min-width: 768px) {
    .modal-sidebar {
      min-height: 100%;
      max-width: none;
      width: 46.6vw; } }
  .modal-sidebar .modal-content {
    height: fit-content;
    max-height: 100vh;
    border: none;
    border-radius: 1rem 1rem 0 0;
    margin: auto 0 0 0; }
    @media (min-width: 768px) {
      .modal-sidebar .modal-content {
        max-height: none;
        border-radius: 1rem 0 0 1rem;
        margin: auto 0 auto auto;
        height: 100%;
        padding: 0 1rem; } }
    @media (min-width: 1100px) {
      .modal-sidebar .modal-content {
        padding: 0 5rem; }
        .modal-desktop-half .modal-sidebar .modal-content {
          padding: 0 1.5rem; } }
    @media (min-width: 1440px) {
      .modal-sidebar .modal-content {
        padding: 0 10rem; }
        .modal-desktop-half .modal-sidebar .modal-content {
          padding: 0 1.5rem; } }
    .modal-sidebar .modal-content nav {
      background-color: #ffffff !important;
      align-items: center;
      justify-content: space-between; }
      .modal-sidebar .modal-content nav .navbar-brand {
        display: none; }
      .modal-sidebar .modal-content nav svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-sidebar .modal-content .modal-header {
      border: none; }
      @media (min-width: 768px) {
        .modal-sidebar .modal-content .modal-header {
          margin-top: 1rem; } }
      .modal-sidebar .modal-content .modal-header svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-sidebar .modal-content .modal-body {
      overflow: hidden scroll; }
      .modal-sidebar .modal-content .modal-body::-webkit-scrollbar {
        width: 0rem; }
    .modal-sidebar .modal-content .modal-footer {
      border: none; }
  .modal-sidebar .full-payslip.modal-content {
    width: 810px; }
  @media (min-width: 992px) {
    .modal-sidebar {
      max-width: none;
      width: 53.4vw; }
      .modal-sidebar.modal-desktop-half {
        min-width: 400px;
        max-width: 26.7vw; }
        .modal-sidebar.modal-desktop-half .modal-content {
          padding: 0 1rem; }
      .modal-sidebar.modal-desktop-medium {
        min-width: 600px;
        max-width: 40vw; }
        .modal-sidebar.modal-desktop-medium .modal-content {
          padding: 0 1.5rem; } }
  @media (min-width: 1200px) {
    .modal-sidebar {
      width: 61.2vw; }
      .modal-sidebar.modal-desktop-half {
        max-width: 31vw; }
      .modal-sidebar.modal-desktop-medium {
        max-width: 45vw; } }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 375px;
  margin-bottom: auto !important;
  margin-left: auto !important;
  margin-right: auto !important; }
  .modal-centered.full {
    max-width: 70% !important; }
    .modal-centered.full .modal-content {
      max-width: 70% !important;
      width: 70%; }
  .modal-centered.medium {
    max-width: 50% !important; }
    .modal-centered.medium .modal-content {
      max-width: 50% !important;
      width: 50%; }
  .modal-centered.modal-unavailability, .modal-centered.modal-fit {
    max-width: fit-content; }
  .modal.fade .modal-centered {
    transform: translate(0, -50px); }
  .modal.show .modal-centered {
    transform: none; }
  .modal-centered .modal-content {
    padding: 16px;
    border-radius: 7px !important;
    border: none; }
    .modal-centered .modal-content .modal-header {
      min-height: 64px;
      border: none; }
      .modal-centered .modal-content .modal-header svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-centered .modal-content .modal-body h2 {
      line-height: 30px; }
    .modal-centered .modal-content .modal-body h4 {
      line-height: 26px; }
    .modal-centered .modal-content .modal-footer {
      display: none;
      border: none; }
  .modal-centered.modal-confirm .modal-body {
    overflow: unset; }

.contentHeight {
  height: 100%; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-botbar {
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  margin: auto 0 0 0;
  max-height: 100vh; }
  .modal.fade .modal-botbar {
    transform: translate(0, 50px); }
  .modal.show .modal-botbar {
    transform: none; }
  .modal-botbar .modal-content {
    position: relative;
    border-radius: 16px;
    width: 100%;
    padding: 0 16px;
    bottom: 0;
    overflow-y: scroll;
    height: 100%; }
    .modal-botbar .modal-content .modal-header {
      max-height: 64px;
      border: none; }
      .modal-botbar .modal-content .modal-header svg {
        align-self: center;
        cursor: pointer;
        color: #ccd1dc; }
    .modal-botbar .modal-content .modal-body {
      overflow-y: none; }
    .modal-botbar .modal-content .modal-footer {
      border: none; }
  .modal-botbar.modal-hauto .modal-content {
    height: auto !important;
    overflow-y: hidden; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-colored .modal-content {
  padding: 0 0 !important;
  overflow-x: hidden;
  border-radius: 1rem 1rem 0 0; }
  @media (min-width: 768px) {
    .modal-colored .modal-content {
      border-radius: 1rem 0 0 1rem; } }
  .modal-colored .modal-content .modal-header {
    display: flex;
    flex-direction: column-reverse;
    padding: 2px 15px 15px 15px;
    border-radius: 10px 10px 0 0;
    border: none;
    color: white !important;
    max-height: none;
    margin-top: 0.5rem; }
    .modal-colored .modal-content .modal-header svg {
      cursor: pointer; }
    .modal-colored .modal-content .modal-header .modal-title {
      width: 100%; }
  .modal-colored .modal-content .modal-body {
    border-radius: 0.75rem 0.75rem 0 0;
    background-color: white; }
    @media (min-width: 768px) {
      .modal-colored .modal-content .modal-body {
        border-radius: 0.75rem 0.75rem 0 0.75rem;
        padding: 0; } }
  .modal-colored .modal-content .modal-footer {
    display: none; }

.modal-colored.counceling-modal .modal-content {
  background-color: #35425B; }

.modal-colored.counceling-modal .modal-header h1,
.modal-colored.counceling-modal .modal-header h2,
.modal-colored.counceling-modal .modal-header h3,
.modal-colored.counceling-modal .modal-header h4,
.modal-colored.counceling-modal .modal-header h5,
.modal-colored.counceling-modal .modal-header span {
  color: white; }

.modal-colored.counceling-modal .modal-header svg {
  color: white;
  opacity: 0.7; }

.modal-colored.input-modal .modal-content {
  background-color: white; }

.modal-colored.input-modal .modal-header {
  background-color: white;
  -webkit-box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1);
  -moz-box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1);
  box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1); }
  .modal-colored.input-modal .modal-header h1,
  .modal-colored.input-modal .modal-header h2,
  .modal-colored.input-modal .modal-header h3,
  .modal-colored.input-modal .modal-header h4,
  .modal-colored.input-modal .modal-header h5,
  .modal-colored.input-modal .modal-header span {
    color: black; }
  .modal-colored.input-modal .modal-header svg {
    color: black;
    opacity: 0.7; }

.modal-colored.input-modal .modal-body {
  overflow: unset;
  z-index: 5; }

.modal-colored.scheduled-modal .shape {
  background-color: white; }

.modal-colored.scheduled-modal .znk-icon-action-modal:hover {
  background-color: #606d8a; }

.modal-colored.scheduled-modal .modal-content {
  background-color: #72809d; }

.modal-colored.scheduled-modal.listItemBorders .borderTop {
  border-color: rgba(255, 255, 255, 0.5); }

.modal-colored.scheduled-modal .modal-header {
  background-color: #72809d; }
  .modal-colored.scheduled-modal .modal-header h1,
  .modal-colored.scheduled-modal .modal-header h2,
  .modal-colored.scheduled-modal .modal-header h3,
  .modal-colored.scheduled-modal .modal-header h4,
  .modal-colored.scheduled-modal .modal-header h5,
  .modal-colored.scheduled-modal .modal-header span {
    color: white; }
  .modal-colored.scheduled-modal .modal-header svg {
    color: white;
    opacity: 0.7; }

.modal-colored.attended-modal .modal-content {
  background: white; }

.modal-colored.attended-modal .modal-header h1,
.modal-colored.attended-modal .modal-header h2,
.modal-colored.attended-modal .modal-header h3,
.modal-colored.attended-modal .modal-header h4,
.modal-colored.attended-modal .modal-header h5,
.modal-colored.attended-modal .modal-header span, .modal-colored.attended-modal .modal-header div {
  color: #2C3F58; }
  .modal-colored.attended-modal .modal-header h1.badge,
  .modal-colored.attended-modal .modal-header h2.badge,
  .modal-colored.attended-modal .modal-header h3.badge,
  .modal-colored.attended-modal .modal-header h4.badge,
  .modal-colored.attended-modal .modal-header h5.badge,
  .modal-colored.attended-modal .modal-header span.badge, .modal-colored.attended-modal .modal-header div.badge {
    color: white; }
  .modal-colored.attended-modal .modal-header h1 .znk-rating .edit label,
  .modal-colored.attended-modal .modal-header h2 .znk-rating .edit label,
  .modal-colored.attended-modal .modal-header h3 .znk-rating .edit label,
  .modal-colored.attended-modal .modal-header h4 .znk-rating .edit label,
  .modal-colored.attended-modal .modal-header h5 .znk-rating .edit label,
  .modal-colored.attended-modal .modal-header span .znk-rating .edit label, .modal-colored.attended-modal .modal-header div .znk-rating .edit label {
    color: #2C3F58; }
    .modal-colored.attended-modal .modal-header h1 .znk-rating .edit label .dot,
    .modal-colored.attended-modal .modal-header h2 .znk-rating .edit label .dot,
    .modal-colored.attended-modal .modal-header h3 .znk-rating .edit label .dot,
    .modal-colored.attended-modal .modal-header h4 .znk-rating .edit label .dot,
    .modal-colored.attended-modal .modal-header h5 .znk-rating .edit label .dot,
    .modal-colored.attended-modal .modal-header span .znk-rating .edit label .dot, .modal-colored.attended-modal .modal-header div .znk-rating .edit label .dot {
      color: #2C3F58; }
  .modal-colored.attended-modal .modal-header h1 .znk-rating .edit svg,
  .modal-colored.attended-modal .modal-header h2 .znk-rating .edit svg,
  .modal-colored.attended-modal .modal-header h3 .znk-rating .edit svg,
  .modal-colored.attended-modal .modal-header h4 .znk-rating .edit svg,
  .modal-colored.attended-modal .modal-header h5 .znk-rating .edit svg,
  .modal-colored.attended-modal .modal-header span .znk-rating .edit svg, .modal-colored.attended-modal .modal-header div .znk-rating .edit svg {
    color: #2C3F58; }

.modal-colored.attended-modal .modal-header svg {
  color: #2C3F58; }

.modal-colored.attended-modal .modal-header.listItemBorders .borderTop {
  border-color: rgba(129, 176, 35, 0.5); }

.modal-colored.missed-modal .modal-content {
  background-color: white; }

.modal-colored.missed-modal .modal-header {
  background-color: white; }
  .modal-colored.missed-modal .modal-header h1,
  .modal-colored.missed-modal .modal-header h2,
  .modal-colored.missed-modal .modal-header h3,
  .modal-colored.missed-modal .modal-header h4,
  .modal-colored.missed-modal .modal-header h5,
  .modal-colored.missed-modal .modal-header span {
    color: #2C3F58; }
  .modal-colored.missed-modal .modal-header svg {
    color: #2C3F58; }

.modal-colored.error-modal .modal-content {
  background: #FFF3F0; }

.modal-colored.error-modal .modal-header {
  background: #FFF3F0; }
  .modal-colored.error-modal .modal-header h1,
  .modal-colored.error-modal .modal-header h2,
  .modal-colored.error-modal .modal-header h3,
  .modal-colored.error-modal .modal-header h4,
  .modal-colored.error-modal .modal-header h5,
  .modal-colored.error-modal .modal-header span {
    color: #FF3E1D; }
  .modal-colored.error-modal .modal-header.listItemBorders .borderTop {
    border-color: rgba(255, 62, 29, 0.5); }

.modal-colored.paid-modal .modal-content {
  background-color: #F3FCD4; }

.modal-colored.paid-modal .modal-header {
  background-color: #F3FCD4; }
  .modal-colored.paid-modal .modal-header h1,
  .modal-colored.paid-modal .modal-header h2,
  .modal-colored.paid-modal .modal-header h3,
  .modal-colored.paid-modal .modal-header h4,
  .modal-colored.paid-modal .modal-header h5,
  .modal-colored.paid-modal .modal-header span {
    color: #81B023; }

.modal-colored.canceled-modal .shape {
  background-color: #778699; }

.modal-colored.canceled-modal .modal-content {
  background-color: #F0F4F8; }

.modal-colored.canceled-modal .modal-header {
  background-color: #F0F4F8; }
  .modal-colored.canceled-modal .modal-header h1,
  .modal-colored.canceled-modal .modal-header h2,
  .modal-colored.canceled-modal .modal-header h3,
  .modal-colored.canceled-modal .modal-header h4,
  .modal-colored.canceled-modal .modal-header h5,
  .modal-colored.canceled-modal .modal-header span {
    color: #778699; }

.modal-colored.upcoming-modal .modal-content {
  background-color: #696CFF; }

.modal-colored.upcoming-modal .modal-header {
  background-color: #696CFF; }
  .modal-colored.upcoming-modal .modal-header h1,
  .modal-colored.upcoming-modal .modal-header h2,
  .modal-colored.upcoming-modal .modal-header h3,
  .modal-colored.upcoming-modal .modal-header h4,
  .modal-colored.upcoming-modal .modal-header h5,
  .modal-colored.upcoming-modal .modal-header span {
    color: white; }
  .modal-colored.upcoming-modal .modal-header svg {
    color: white;
    opacity: 0.7; }
  .modal-colored.upcoming-modal .modal-header.listItemBorders .borderTop {
    border-color: rgba(255, 255, 255, 0.5); }

.modal-colored.proposed-modal .modal-content {
  background-color: white; }

.modal-colored.proposed-modal .modal-header {
  background-color: white; }
  .modal-colored.proposed-modal .modal-header h1,
  .modal-colored.proposed-modal .modal-header h2,
  .modal-colored.proposed-modal .modal-header h3,
  .modal-colored.proposed-modal .modal-header h4,
  .modal-colored.proposed-modal .modal-header h5,
  .modal-colored.proposed-modal .modal-header span {
    color: #72809d; }
  .modal-colored.proposed-modal .modal-header svg {
    color: #72809d;
    opacity: 0.7; }

.modal-colored.white-modal .modal-content {
  background-color: white; }

.modal-colored.white-modal .modal-header {
  background-color: white;
  box-shadow: none; }
  .modal-colored.white-modal .modal-header h1,
  .modal-colored.white-modal .modal-header h2,
  .modal-colored.white-modal .modal-header h3,
  .modal-colored.white-modal .modal-header h4,
  .modal-colored.white-modal .modal-header h5,
  .modal-colored.white-modal .modal-header span {
    color: black; }
  .modal-colored.white-modal .modal-header svg {
    color: black;
    opacity: 0.7; }

.modal-colored.whiteblue-modal .modal-content {
  border: 1px solid #696CFF;
  background-color: white; }

.modal-colored.whiteblue-modal .modal-header {
  background-color: white; }
  .modal-colored.whiteblue-modal .modal-header h1,
  .modal-colored.whiteblue-modal .modal-header h2,
  .modal-colored.whiteblue-modal .modal-header h3,
  .modal-colored.whiteblue-modal .modal-header h4,
  .modal-colored.whiteblue-modal .modal-header h5,
  .modal-colored.whiteblue-modal .modal-header span {
    color: #696CFF; }
  .modal-colored.whiteblue-modal .modal-header svg {
    color: #696CFF;
    opacity: 0.7; }

.modal-colored.incomplete-modal .modal-content {
  background: linear-gradient(90deg, rgba(255, 212, 34, 0.5), rgba(255, 212, 34, 0.5)), linear-gradient(90deg, white, white); }

.modal-colored.incomplete-modal .modal-header h1,
.modal-colored.incomplete-modal .modal-header h2,
.modal-colored.incomplete-modal .modal-header h3,
.modal-colored.incomplete-modal .modal-header h4,
.modal-colored.incomplete-modal .modal-header h5,
.modal-colored.incomplete-modal .modal-header span {
  color: #bb9700; }
  .modal-colored.incomplete-modal .modal-header h1 .znk-rating .edit label,
  .modal-colored.incomplete-modal .modal-header h2 .znk-rating .edit label,
  .modal-colored.incomplete-modal .modal-header h3 .znk-rating .edit label,
  .modal-colored.incomplete-modal .modal-header h4 .znk-rating .edit label,
  .modal-colored.incomplete-modal .modal-header h5 .znk-rating .edit label,
  .modal-colored.incomplete-modal .modal-header span .znk-rating .edit label {
    color: #bb9700; }
  .modal-colored.incomplete-modal .modal-header h1 .znk-rating .edit svg,
  .modal-colored.incomplete-modal .modal-header h2 .znk-rating .edit svg,
  .modal-colored.incomplete-modal .modal-header h3 .znk-rating .edit svg,
  .modal-colored.incomplete-modal .modal-header h4 .znk-rating .edit svg,
  .modal-colored.incomplete-modal .modal-header h5 .znk-rating .edit svg,
  .modal-colored.incomplete-modal .modal-header span .znk-rating .edit svg {
    color: #bb9700; }

.modal-colored.incomplete-modal .modal-header svg {
  color: white;
  opacity: 0.7; }

.modal-colored.info-modal .modal-content {
  background-color: #17a1e6; }

.modal-colored.info-modal .modal-header {
  background-color: #17a1e6; }
  .modal-colored.info-modal .modal-header h1,
  .modal-colored.info-modal .modal-header h2,
  .modal-colored.info-modal .modal-header h3,
  .modal-colored.info-modal .modal-header h4,
  .modal-colored.info-modal .modal-header h5,
  .modal-colored.info-modal .modal-header span {
    color: white; }
  .modal-colored.info-modal .modal-header svg {
    color: white;
    opacity: 0.7; }

.modal-colored.warning-modal .modal-content {
  background-color: #FFB519; }

.modal-colored.warning-modal .modal-header {
  background-color: #FFB519; }
  .modal-colored.warning-modal .modal-header h1, .modal-colored.warning-modal .modal-header h2, .modal-colored.warning-modal .modal-header h3, .modal-colored.warning-modal .modal-header h4, .modal-colored.warning-modal .modal-header h5, .modal-colored.warning-modal .modal-header span {
    color: white; }
  .modal-colored.warning-modal .modal-header svg {
    color: white;
    opacity: 0.7; }

.modal-colored.pending-modal .modal-content {
  background-color: #35425B; }

.modal-colored.pending-modal .modal-header {
  background-color: #35425B; }
  .modal-colored.pending-modal .modal-header h1, .modal-colored.pending-modal .modal-header h2, .modal-colored.pending-modal .modal-header h3, .modal-colored.pending-modal .modal-header h4, .modal-colored.pending-modal .modal-header h5, .modal-colored.pending-modal .modal-header span {
    color: white; }
  .modal-colored.pending-modal .modal-header svg {
    color: white;
    opacity: 0.7; }

.modal-colored.pause-modal .modal-content {
  background-color: #F0F4F8; }

.modal-colored.pause-modal .modal-header {
  background-color: #F0F4F8; }
  .modal-colored.pause-modal .modal-header h1, .modal-colored.pause-modal .modal-header h2, .modal-colored.pause-modal .modal-header h3, .modal-colored.pause-modal .modal-header h4, .modal-colored.pause-modal .modal-header h5, .modal-colored.pause-modal .modal-header span {
    color: #778699; }
  .modal-colored.pause-modal .modal-header svg {
    color: #778699;
    opacity: 0.7; }

.modal-colored.exam-modal .modal-content {
  background-color: #b54ae2; }

.modal-colored.exam-modal .modal-header {
  background-color: #b54ae2; }

.modal-colored.college-modal .modal-content {
  background-color: #f8f8f8; }

.modal-colored.college-modal .modal-header {
  background-color: #f8f8f8; }

.modal-colored.scheduled-counseling-modal .shape {
  background-color: white; }

.modal-colored.scheduled-counseling-modal .znk-icon-action-modal:hover {
  background-color: #606d8a; }

.modal-colored.scheduled-counseling-modal .modal-content {
  background-color: #35425B; }

.modal-colored.scheduled-counseling-modal.listItemBorders .borderTop {
  border-color: rgba(255, 255, 255, 0.5); }

.modal-colored.scheduled-counseling-modal .modal-header {
  background-color: #35425B; }
  .modal-colored.scheduled-counseling-modal .modal-header h1,
  .modal-colored.scheduled-counseling-modal .modal-header h2,
  .modal-colored.scheduled-counseling-modal .modal-header h3,
  .modal-colored.scheduled-counseling-modal .modal-header h4,
  .modal-colored.scheduled-counseling-modal .modal-header h5,
  .modal-colored.scheduled-counseling-modal .modal-header span {
    color: white; }
  .modal-colored.scheduled-counseling-modal .modal-header svg {
    color: white;
    opacity: 0.7; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-float .modal-content {
  padding: 0px !important;
  margin: 10px !important;
  background-color: transparent;
  border: none; }
  @media (max-width: 768px) {
    .modal-float .modal-content {
      padding: 0 0; } }
  .modal-float .modal-content .modal-header {
    display: none; }
  .modal-float .modal-content .modal-body {
    border-radius: 12px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    overflow: hidden;
    height: inherit;
    background-color: transparent; }
    .modal-float .modal-content .modal-body * {
      border-radius: 0; }
    .modal-float .modal-content .modal-body .modal-selectable {
      cursor: pointer;
      border-top: 1px solid rgba(44, 63, 88, 0.1);
      text-align: center;
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: center; }
      .modal-float .modal-content .modal-body .modal-selectable.error {
        color: #FF3E1D; }
        .modal-float .modal-content .modal-body .modal-selectable.error .dot {
          background-color: #FF3E1D !important; }
      .modal-float .modal-content .modal-body .modal-selectable.primary {
        color: #696CFF; }
        .modal-float .modal-content .modal-body .modal-selectable.primary .dot {
          background-color: #696CFF !important; }
  .modal-float .modal-content .modal-footer {
    padding: 0px !important;
    border: transparent;
    margin-top: 0.25rem !important; }
    .modal-float .modal-content .modal-footer * {
      margin: 0px !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  display: flex;
  max-width: 375px;
  min-width: 200px; }
  .modal-corner.modal-corner-top-right {
    top: 20px; }
  .modal.fade .modal-corner {
    transform: translate(0, -50px); }
  .modal.show .modal-corner {
    transform: none; }
  .modal-corner .modal-content {
    border-radius: 2%;
    border: none;
    padding: 16px;
    padding-top: 0px; }
    .modal-corner .modal-content .modal-header {
      height: auto;
      border: none; }
      .modal-corner .modal-content .modal-header svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-corner .modal-content .modal-body h2 {
      line-height: 30px; }
    .modal-corner .modal-content .modal-body h4 {
      line-height: 26px; }
    .modal-corner .modal-content .modal-footer {
      display: none;
      border: none; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-custom-content .modal-content {
  padding: 0 0 0 24px !important; }

.modal-backdrop.show.no-op {
  opacity: 0; }

.modal.fade.show {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important; }
  @media (min-width: 768px) {
    .modal.fade.show {
      overflow-y: auto; } }
  .modal.fade.show .modal-content::-webkit-scrollbar {
    width: 0rem; }
  .modal.fade.show::-webkit-scrollbar {
    width: 0rem; }

.modal-content .modal-close-absolute {
  position: absolute;
  top: 25px;
  right: 30px; }

.modal-border-radius .modal-content {
  border-radius: 1rem 1rem 0 0; }

.modal-widespread {
  min-width: 35vw !important; }

.modal-sticky {
  padding-top: 0px !important;
  height: 100%;
  display: flex; }
  .modal-sticky.fixed-head-modal {
    max-height: 100vh; }
  @media (min-width: 768px) {
    .modal-sticky {
      min-height: 100%; } }
  .modal-sticky .modal-content {
    overflow: hidden;
    margin: 0;
    padding: 0;
    height: fit-content;
    max-height: 100vh;
    border: none; }
    @media (min-width: 768px) {
      .modal-sticky .modal-content {
        max-height: none;
        height: 100%; } }
    .modal-sticky .modal-content .modal-body {
      overflow: hidden scroll; }
      .modal-sticky .modal-content .modal-body::-webkit-scrollbar {
        width: 0rem; }
    .modal-sticky .modal-content .modal-footer {
      border: none; }
    .modal-sticky .modal-content .modal-scroll {
      overflow-y: overlay;
      overflow-x: hidden; }
      .modal-sticky .modal-content .modal-scroll .modal-header-disapear {
        opacity: 1;
        transition: 0.5s; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t11 {
          opacity: 0; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t10 {
          opacity: 0.08; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t9 {
          opacity: 0.16; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t8 {
          opacity: 0.24; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t7 {
          opacity: 0.32; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t6 {
          opacity: 0.40; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t5 {
          opacity: 0.48; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t4 {
          opacity: 0.56; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t3 {
          opacity: 0.74; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t2 {
          opacity: 0.82; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t1 {
          opacity: 0.90; }
        .modal-sticky .modal-content .modal-scroll .modal-header-disapear.t0 {
          opacity: 1; }
      .modal-sticky .modal-content .modal-scroll .modal-header {
        z-index: 10;
        position: sticky;
        top: -45%;
        min-height: unset; }
      .modal-sticky .modal-content .modal-scroll .modal-body {
        overflow: unset;
        z-index: 5; }
      .modal-sticky .modal-content .modal-scroll .modal-header-sticky {
        position: absolute;
        top: -12%;
        z-index: 20;
        width: 100%;
        height: fit-content;
        transition: 0.5s; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t0 {
          top: -12%;
          opacity: 0; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t1 {
          top: -11%;
          opacity: 0.08; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t2 {
          top: -10%;
          opacity: 0.16; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t3 {
          top: -9%;
          opacity: 0.24; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t4 {
          top: -8%;
          opacity: 0.32; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t5 {
          top: -7%;
          opacity: 0.40; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t6 {
          top: -6%;
          opacity: 0.48; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t7 {
          top: -5%;
          opacity: 0.56; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t8 {
          top: -4%;
          opacity: 0.74; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t9 {
          top: -3%;
          opacity: 0.82; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t10 {
          top: -2%;
          opacity: 0.90; }
        .modal-sticky .modal-content .modal-scroll .modal-header-sticky.t11 {
          top: 0%;
          opacity: 1; }

@keyframes appearsAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes disappearsAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    display: none; } }

.znk-modal-blur-section {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  animation-name: appearsAnimation;
  animation-duration: 0.5s; }
  .znk-modal-blur-section.is-blur {
    backdrop-filter: blur(10px); }

.modal-noborder-bottom .modal-content {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.navbar .nav .nav-item .nav-link.active {
  color: #696CFF; }

.navbar .nav .nav-item .nav-link.base {
  color: #72809d; }

.navbar .nav .nav-item .nav-link.nav-link-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 25%;
  width: 40px;
  height: 40px;
  margin: 2px 0px; }
  .navbar .nav .nav-item .nav-link.nav-link-option:hover {
    background-color: #F0F4F8; }

.navbar.brandless .navbar-brand {
  display: none; }

.navbar.tabTexts svg {
  color: #5a6782;
  justify-self: flex-end; }

.navbar.tabTexts .nav {
  overflow-x: auto; }
  .navbar.tabTexts .nav .nav-item .nav-link {
    font-size: 1.125rem;
    line-height: 28px;
    font-weight: 700;
    padding: 0;
    margin: 0; }
    .navbar.tabTexts .nav .nav-item .nav-link.active {
      color: #35425B; }
    .navbar.tabTexts .nav .nav-item .nav-link.base {
      color: #72809d; }

.navPublic .navbar {
  display: flex;
  padding: 0;
  align-items: flex-end; }
  .navPublic .navbar .navbar-brand {
    justify-content: flex-start; }
  .navPublic .navbar .nav {
    display: flex;
    justify-content: flex-start;
    flex-direction: row; }
    .navPublic .navbar .nav .nav-item {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      flex-direction: column; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.badge {
  font-size: 0.75rem;
  font-weight: 400;
  opacity: 0.8; }
  .badge.badge-pill {
    border-radius: 12px;
    line-height: 16px;
    padding: 0.25rem 0.75rem 0.125rem 0.75rem;
    color: #ffffff;
    font-weight: 500;
    line-height: 22px;
    font-size: 0.875rem; }
    .badge.badge-pill.badge-secondary {
      background-color: #F0F4F8;
      color: #35425B !important;
      border-radius: 10px;
      line-height: 22px;
      font-weight: 400;
      font-size: 0.875rem; }
      .badge.badge-pill.badge-secondary.active {
        background-color: #72809d;
        color: white; }
    .badge.badge-pill.badge-success {
      background-color: #F3FCD4;
      color: #8FC813 !important; }
    .badge.badge-pill.badge-warning {
      background-color: #FFF5CB;
      color: #CC9900 !important; }
    .badge.badge-pill.badge-danger {
      background-color: #FFEEEB;
      color: #b61b00 !important; }
    .badge.badge-pill.badge-light {
      color: #2C3F78; }
    .badge.badge-pill.badge-inverse {
      background-color: #696CFF;
      color: #ffffff; }
    .badge.badge-pill.badge-primary-alt {
      background-color: #363aff !important; }
    .badge.badge-pill.badge-inProgress {
      background-color: #b44be1 !important; }
    .badge.badge-pill.badge-warning-alt {
      background-color: #ffd422 !important; }
    .badge.badge-pill.badge-success-alt {
      background-color: #a7e521 !important; }
    .badge.badge-pill.badge-option {
      background-color: rgba(105, 108, 255, 0.1);
      color: #696CFF; }
    .badge.badge-pill.badge-input {
      background-color: #F2F2FF;
      color: #696CFF !important; }
    .badge.badge-pill.badge-empty {
      background-color: transparent;
      border: 1px dashed #DCE1E7;
      color: #72809d; }
      .badge.badge-pill.badge-empty svg {
        color: #72809d; }
  .badge.badge-pill.filters {
    line-height: 22px;
    font-size: 0.875rem;
    padding: 0.1875rem 0.75rem 0.1875rem 0.75rem; }
  .badge.badge-pill.number {
    font-size: 14px;
    padding: 0.5px 7px;
    line-height: 22px;
    font-weight: 500; }
  .badge.badge-pill.point {
    width: 10px;
    height: 10px;
    min-height: 10px;
    border: 2px solid #ffffff;
    padding: 5px 5px;
    line-height: 22px;
    font-weight: 500; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.navbar-brand {
  display: flex;
  align-items: flex-end;
  padding: 0 1rem 0.25rem 0.75rem;
  margin: 0; }
  @media (min-width: 768px) {
    .navbar-brand {
      margin-top: 1rem; } }
  .navbar-brand svg {
    color: rgba(114, 128, 157, 0.9);
    width: 32px;
    height: 22px; }
    .navbar-brand svg.small-size.znk-brand {
      width: 16px;
      height: 11px; }
    .navbar-brand svg.small-size.znk-text {
      height: 7.7px;
      width: 31.15px; }
    .navbar-brand svg.default-size.znk-brand {
      width: 32px;
      height: 22px; }
    .navbar-brand svg.default-size.znk-text {
      height: 15.4px;
      width: 62.3px; }
    .navbar-brand svg.medium-size.znk-brand {
      width: 48px;
      height: 33px; }
    .navbar-brand svg.medium-size.znk-text {
      height: 23.1px;
      width: 93.45px; }
    .navbar-brand svg.large-size.znk-brand {
      width: 64px;
      height: 44px; }
    .navbar-brand svg.large-size.znk-text {
      height: 30.8px;
      width: 124.6px; }
    .navbar-brand svg.extra-large-size.znk-brand {
      width: 80px;
      height: 55px; }
    .navbar-brand svg.extra-large-size.znk-text {
      height: 38.5px;
      width: 155.75px; }
    .navbar-brand svg.znk-text {
      align-self: center;
      height: 15.4px;
      width: 62.3px;
      margin-left: 0.5vw;
      display: none; }
      @media (min-width: 1400px) {
        .navbar-brand svg.znk-text {
          display: block; } }
    @media (min-width: 1400px) {
      .navbar-brand svg {
        color: #35425B; } }
  .navbar-brand .brand {
    display: inline; }
  .navbar-brand .logo {
    display: inline;
    color: #35425B;
    width: 88px; }
    @media (min-width: 768px) {
      .navbar-brand .logo {
        margin-left: 12.75px; } }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.loading {
  width: 43px;
  min-height: 10px;
  display: flex;
  justify-content: space-between; }
  .loading.inverse {
    background-color: transparent; }
    .loading.inverse .dot {
      background-color: #ffffff; }
  .loading.small-loading {
    width: 33px; }

@keyframes activityLoading {
  from {
    opacity: 1;
    width: 9px;
    height: 9px; }
  to {
    opacity: 0;
    width: 8px;
    height: 8px; } }
  .loading .dot {
    background-color: #35425B;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    animation-name: activityLoading;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-direction: alternate; }
    .loading .dot.f {
      animation-delay: -0.1s; }
    .loading .dot.l {
      animation-delay: 0.1s; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.bodyPublic {
  border-radius: 20px !important;
  overflow: scroll;
  overflow-x: hidden;
  background-color: white; }
  @media (min-width: 768px) {
    .bodyPublic {
      -webkit-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
      -moz-box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
      box-shadow: 0px 12px 32px 0px rgba(67, 86, 100, 0.1);
      border-radius: 20px; } }
  .bodyPublic .subBody {
    min-height: 100vh;
    height: 100%;
    border-radius: 20px; }

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent rgba(114, 128, 157, 0.3); }

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0.5rem; }

*::-webkit-scrollbar-track {
  background: transparent; }

*::-webkit-scrollbar-thumb {
  background-color: rgba(114, 128, 157, 0.3);
  border-radius: 16px;
  border: none; }
  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(114, 128, 157, 0.5);
    width: 0.625rem; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.timeline {
  border-left: 2px solid rgba(114, 128, 157, 0.5); }
  .timeline .timelineMonth {
    display: flex;
    flex-direction: column; }
    .timeline .timelineMonth .timelineMonthTitle {
      display: flex;
      align-items: center; }
      .timeline .timelineMonth .timelineMonthTitle .dotLeft {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        background-color: #696CFF;
        margin-left: -5px; }
      .timeline .timelineMonth .timelineMonthTitle .timelineMonthText {
        text-transform: uppercase;
        font-weight: 400;
        color: #72809d;
        font-size: 12px;
        line-height: 18px; }
    .timeline .timelineMonth .timelineItem {
      display: flex; }
      .timeline .timelineMonth .timelineItem .borderLeft {
        margin-left: -3px;
        border-radius: 2px;
        border: 2px solid #696CFF; }
      .timeline .timelineMonth .timelineItem .timelineBody {
        display: flex;
        flex-direction: column; }
        .timeline .timelineMonth .timelineItem .timelineBody .timelineBodyTitle {
          font-size: 16px;
          line-height: 20px; }
        .timeline .timelineMonth .timelineItem .timelineBody .timelineBodySubtext {
          font-weight: 400;
          color: #72809d;
          font-size: 12px;
          line-height: 18px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-timeline-plan {
  margin: 10px; }
  .znk-timeline-plan .timeline {
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .znk-timeline-plan .timeline .line {
      transition: 0.5s;
      background-color: rgba(114, 128, 157, 0.5);
      width: 3px;
      height: 100%;
      min-height: 20px;
      align-self: center;
      border-radius: 2px;
      margin-top: -3px;
      -webkit-border-top-left-radius: 0px;
      -webkit-border-top-right-radius: 0px;
      -webkit-border-bottom-right-radius: 0px;
      -webkit-border-bottom-right-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-topright: 0px;
      -moz-border-radius-bottomright: 0px;
      -moz-border-radius-bottomright: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px; }
      .znk-timeline-plan .timeline .line.no-border {
        border-radius: 0px; }
      .znk-timeline-plan .timeline .line.has-event {
        width: 5px; }
      .znk-timeline-plan .timeline .line.start {
        -webkit-border-top-right-radius: 2px;
        -webkit-border-top-left-radius: 2px;
        -moz-border-radius-topright: 2px;
        -moz-border-radius-topleft: 2px;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px; }
        .znk-timeline-plan .timeline .line.start .date-label {
          top: 0px; }
      .znk-timeline-plan .timeline .line.end {
        -webkit-border-bottom-right-radius: 2px;
        -webkit-border-bottom-left-radius: 2px;
        -moz-border-radius-bottomright: 2px;
        -moz-border-radius-bottomleft: 2px;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px; }
        .znk-timeline-plan .timeline .line.end .date-label {
          bottom: 0px; }
      .znk-timeline-plan .timeline .line.moved {
        margin-left: -20px !important;
        transform: scale(1.5, 1.3);
        z-index: 1000; }
        .znk-timeline-plan .timeline .line.moved .date-label {
          transform: scale(0.9, 1);
          font-size: 10px;
          opacity: 1; }
      .znk-timeline-plan .timeline .line .date-label {
        margin-left: -32px;
        transition: 0.5s;
        opacity: 0;
        font-size: 10px; }
        .znk-timeline-plan .timeline .line .date-label.show {
          margin-left: -46px;
          opacity: 1 !important;
          font-size: 13px; }
      .znk-timeline-plan .timeline .line.background-blue {
        background-color: #696CFF !important; }
      .znk-timeline-plan .timeline .line.background-black {
        background-color: #FFD422 !important; }
      .znk-timeline-plan .timeline .line.background-green {
        background-color: #FFEEEB !important; }
  .znk-timeline-plan .month {
    display: flex;
    flex-direction: row; }
    .znk-timeline-plan .month .label {
      white-space: nowrap; }
    .znk-timeline-plan .month .timeline-month .point-month {
      padding: 5px;
      border-radius: 50%;
      height: 8px;
      width: 8px;
      background-color: #696CFF;
      align-self: center;
      z-index: 10;
      margin-top: -2px;
      margin-bottom: -2px; }
    .znk-timeline-plan .month .timeline-month .line {
      z-index: 1;
      background-color: rgba(114, 128, 157, 0.5); }
  .znk-timeline-plan .event {
    display: flex;
    flex-direction: row; }
    .znk-timeline-plan .event .timeline-event .line-month {
      z-index: 1;
      background-color: rgba(114, 128, 157, 0.5); }
    .znk-timeline-plan .event .label {
      padding: 5px;
      border-radius: 10px; }
      .znk-timeline-plan .event .label:hover, .znk-timeline-plan .event .label.hover {
        background-color: #f0f0ff; }
        .znk-timeline-plan .event .label:hover span, .znk-timeline-plan .event .label.hover span {
          color: #696CFF; }

.znk-input {
  background-color: #F0F4F8;
  border-radius: 12px;
  border: none;
  color: #2C3F58;
  font-weight: 500;
  line-height: 1.5;
  height: 44px; }
  .znk-input.error {
    color: #FF3E1D; }

.znk-segment {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .znk-segment .left {
    width: calc(50% - 2px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .znk-segment .right {
    width: calc(50% - 2px);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

.znk-rating input[type="radio"] {
  display: none;
  margin: 0; }

.znk-rating .star {
  cursor: pointer;
  transition: color 200ms;
  color: gray; }
  .znk-rating .star.yellow {
    color: #FFD422; }
  .znk-rating .star.gray {
    color: #D7E1EA; }
  .znk-rating .star.mini {
    height: 16px;
    width: 16px; }

.znk-rating .start-edit {
  cursor: pointer;
  transition: color 200ms; }

.znk-rating :nth-child(2), .znk-rating :nth-child(3), .znk-rating :nth-child(3), .znk-rating :nth-child(4), .znk-rating :nth-child(5) {
  margin-left: 7px; }

.znk-rating .results {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .znk-rating .results span {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    color: #778699;
    margin-left: 10px; }

.znk-rating .edit :first-child {
  margin-left: -2px; }

.znk-rating .edit :nth-child(2), .znk-rating .edit :nth-child(3), .znk-rating .edit :nth-child(3), .znk-rating .edit :nth-child(4), .znk-rating .edit :nth-child(5) {
  margin-left: 4px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.CircularProgressbar .CircularProgressbar-text {
  fill: #2c3f58;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2; }

.CircularProgressbar .CircularProgressbar-path {
  stroke: #696CFF; }

.CircularProgressbar .CircularProgressbar-trail {
  fill: #d7e1ea; }

.znk-stats {
  max-width: 370px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center; }
  .znk-stats .stats-progress {
    width: 40%; }
    .znk-stats .stats-progress .CircularProgressbar {
      height: 100%; }
  .znk-stats .stats-list {
    width: 60%;
    list-style: none;
    padding-left: 0px;
    margin: 0; }
    .znk-stats .stats-list li {
      border-bottom: 1px solid rgba(44, 63, 89, 0.1);
      padding-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 24px;
      padding-top: 7px; }
      .znk-stats .stats-list li div {
        display: flex;
        align-items: center; }
        .znk-stats .stats-list li div span {
          margin-left: 12px; }
    .znk-stats .stats-list :last-child {
      border: none; }
  .znk-stats.znk-stats-font-small {
    font-size: 12px;
    line-height: 1; }
    .znk-stats.znk-stats-font-small .stats-progress {
      width: 40%;
      text-align: center;
      height: 100%;
      padding: 10px; }
    .znk-stats.znk-stats-font-small .stats-list {
      width: 60%; }
      .znk-stats.znk-stats-font-small .stats-list li {
        border-bottom: none;
        padding-left: 5px;
        padding-right: 2px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.avatar {
  cursor: default;
  display: flex;
  background-color: #696CFF;
  justify-content: center;
  align-items: center; }
  .avatar.avatar-hover-delete .znk-delete-symbol {
    opacity: 0; }
  .avatar.avatar-hover-delete:hover .znk-delete-symbol {
    opacity: 1; }
  .list-child .avatar {
    border: 1px solid white; }
  .avatar.avatar-extra-small {
    height: 22px;
    width: 22px;
    border-radius: 8px; }
    .avatar.avatar-extra-small span, .avatar.avatar-extra-small div {
      font-size: 9px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar.avatar-extra-small img {
      border-radius: 8px; }
  .avatar.avatar-small {
    height: 28px;
    width: 28px;
    border-radius: 10px; }
    .avatar.avatar-small span, .avatar.avatar-small div {
      font-size: 11px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar.avatar-small img {
      border-radius: 10px; }
  .avatar.avatar-medium {
    height: 36px;
    width: 36px;
    border-radius: 12px; }
    .avatar.avatar-medium span, .avatar.avatar-medium div {
      font-size: 14px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar.avatar-medium img {
      border-radius: 12px; }
  .avatar.avatar-large {
    height: 56px;
    width: 56px;
    border-radius: 19px; }
    .avatar.avatar-large span, .avatar.avatar-large div {
      font-size: 21px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar.avatar-large img {
      border-radius: 19px; }
  .avatar.avatar-extra {
    height: 128px;
    width: 128px;
    border-radius: 43px; }
    .avatar.avatar-extra span, .avatar.avatar-extra div {
      font-size: 50px;
      color: #f8f9fa; }
    .avatar.avatar-extra img {
      border-radius: 43px; }
  .avatar.noset-avatar {
    border: 2px dashed #DCE1E7;
    border-radius: 16px;
    background-color: transparent; }
    .avatar.noset-avatar span, .avatar.noset-avatar div {
      color: #DCE1E7; }
  .avatar.set-avatar {
    border: 2px solid #FFB519;
    border-radius: 16px;
    background-color: transparent; }
    .avatar.set-avatar span, .avatar.set-avatar div {
      color: #FFB519; }
  .avatar.scheduled-avatar {
    border: none;
    background-color: #72809D; }
    .avatar.scheduled-avatar span, .avatar.scheduled-avatar div {
      color: white; }
  .avatar.incomplete-avatar {
    border: none;
    background-color: #FFF5CB; }
    .avatar.incomplete-avatar span, .avatar.incomplete-avatar div {
      color: #E6AC00; }
  .avatar.selected-avatar {
    border: none;
    background-color: #FFB519; }
    .avatar.selected-avatar span, .avatar.selected-avatar div {
      color: white; }
  .avatar.noset-avatar-manual {
    border: 2px dashed #DCE1E7;
    border-radius: 16px;
    background-color: transparent; }
    .avatar.noset-avatar-manual span, .avatar.noset-avatar-manual div {
      color: #DCE1E7; }
  .avatar.set-avatar-manual {
    border: 2px solid #696CFF;
    border-radius: 16px;
    background-color: transparent; }
    .avatar.set-avatar-manual span, .avatar.set-avatar-manual div {
      color: #696CFF !important; }
  .avatar.scheduled-avatar-manual {
    border: none;
    background-color: #696CFF; }
    .avatar.scheduled-avatar-manual span, .avatar.scheduled-avatar-manual div {
      color: white; }
  .avatar.selected-avatar-manual {
    border: none;
    background-color: #696CFF; }
    .avatar.selected-avatar-manual span, .avatar.selected-avatar-manual div {
      color: white; }
  .avatar.avatar-span-white span, .avatar.avatar-span-white div {
    color: white !important; }

.znk-delete-symbol {
  color: white !important;
  background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  transition: .2s; }
  .znk-delete-symbol:hover {
    transform: scale(1.1); }
  .znk-delete-symbol svg {
    width: 10px !important;
    height: 10px !important; }

.namedText {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .namedText .textName {
    margin-top: 0.5rem; }
  .namedText.flex-row .textName {
    margin-top: 0;
    margin-left: 0.5rem; }
  .namedText.attended-avatar span, .namedText.attended-avatar div {
    color: #050601; }
  .namedText.attended-avatar .avatar span, .namedText.attended-avatar .avatar div {
    color: #050601; }
  .namedText.missed-avatar span, .namedText.missed-avatar div {
    color: #eb2300; }
  .namedText.missed-avatar .avatar span, .namedText.missed-avatar .avatar div {
    color: #eb2300; }

.img-avatar.avatar-small {
  height: 28px;
  width: 28px;
  border-radius: 10px; }

.avatar-small {
  width: 28px; }

.avatar-medium {
  width: 36px; }

.avatar-large {
  width: 56px; }

.avatar-extra {
  width: 128px; }

.test {
  border-radius: 0px; }

.cam-avatar-small {
  width: 14px;
  height: 14px; }

.cam-avatar-medium {
  width: 18px;
  height: 18px; }

.cam-avatar-large {
  width: 28px;
  height: 28px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.avatar-blank {
  display: flex;
  background-color: #d8e2eb;
  justify-content: center;
  align-items: center; }
  .avatar-blank.avatar-small {
    height: 28px;
    width: 28px;
    border-radius: 10px; }
    .avatar-blank.avatar-small span, .avatar-blank.avatar-small div {
      font-size: 11px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar-blank.avatar-small img {
      border-radius: 10px; }
    .avatar-blank.avatar-small .avatar-blank-icon {
      width: 11px;
      height: 11px; }
  .avatar-blank.avatar-medium {
    height: 36px;
    width: 36px;
    border-radius: 12px; }
    .avatar-blank.avatar-medium span, .avatar-blank.avatar-medium div {
      font-size: 14px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar-blank.avatar-medium img {
      border-radius: 12px; }
    .avatar-blank.avatar-medium .avatar-blank-icon {
      width: 14px;
      height: 14px; }
  .avatar-blank.avatar-large {
    height: 56px;
    width: 56px;
    border-radius: 19px; }
    .avatar-blank.avatar-large span, .avatar-blank.avatar-large div {
      font-size: 21px;
      color: #f8f9fa;
      margin-left: 0px !important; }
    .avatar-blank.avatar-large img {
      border-radius: 19px; }
    .avatar-blank.avatar-large .avatar-blank-icon {
      width: 21px;
      height: 21px; }
  .avatar-blank.avatar-extra {
    height: 128px;
    width: 128px;
    border-radius: 43px; }
    .avatar-blank.avatar-extra span, .avatar-blank.avatar-extra div {
      font-size: 50px;
      color: #f8f9fa; }
    .avatar-blank.avatar-extra img {
      border-radius: 43px; }
    .avatar-blank.avatar-extra .avatar-blank-icon {
      width: 50px;
      height: 50px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.custom-switch .custom-control-input ~ .custom-control-label {
  cursor: pointer; }
  .custom-switch .custom-control-input ~ .custom-control-label::before {
    background-color: #dbdfe6;
    border-color: #dbdfe6;
    border-radius: 12px; }
  .custom-switch .custom-control-input ~ .custom-control-label::after {
    background-color: #ffffff; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #696CFF;
  border-color: #696CFF; }

.custom-switch .custom-control-input:disabled ~ .custom-control-label {
  cursor: not-allowed; }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #eaecf1;
    border-color: #eaecf1; }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
    background-color: #dbdfe6; }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-color: #ffffff; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.custom-checkbox.custom-control {
  padding-left: 1.3rem; }

.custom-checkbox .custom-control-input + .custom-control-label::before {
  background-color: #ffffff;
  border: 2px solid #dbdfe6; }

.custom-checkbox .custom-control-input + .custom-control-label::after {
  cursor: pointer; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #696CFF;
  border: none; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='%23fff' d='M14.1823257,4.89689175 L6.59930836,12.6744535 C6.38806716,12.8911141 6.11074539,13 5.83342361,13 C5.55610183,13 5.27878005,12.8911141 5.06753885,12.6744535 L1.81767426,9.34121274 C1.39410858,8.90678037 1.39410858,8.20457765 1.81767426,7.77014527 C2.24123995,7.3357129 2.92587809,7.3357129 3.34944377,7.77014527 L5.83342361,10.3178523 L12.6505562,3.32582428 C13.0741219,2.89139191 13.7587601,2.89139191 14.1823257,3.32582428 C14.6058914,3.76025666 14.6058914,4.46245938 14.1823257,4.89689175 Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 75%; }

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f0f2f5; }

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::after {
  cursor: not-allowed; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #dbdfe6;
  border: none; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
  cursor: not-allowed; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.custom-radio.custom-control {
  padding-left: 1.3rem; }

.custom-radio .custom-control-input + .custom-control-label::before {
  background-color: #ffffff;
  border: 2px solid #dbdfe6; }

.custom-radio .custom-control-input + .custom-control-label::after {
  cursor: pointer; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border: 2px solid #696CFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23696CFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #eaecf1;
  border: 2px solid #dbdfe6; }

.custom-radio .custom-control-input:disabled ~ .custom-control-label::after {
  cursor: not-allowed; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #ffffff; }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23dbdfe6'/%3e%3c/svg%3e");
  cursor: not-allowed; }

.list-avatars {
  margin: 0;
  padding: 0;
  display: flex; }
  .list-avatars .list-child {
    -webkit-transition: .2s ease;
    transition: .2s ease; }
  .list-avatars.hover-active:hover .list-child {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .list-avatars.right .list-child {
    margin-left: -26px; }
    .list-avatars.right .list-child.avatar-medium {
      margin-left: -26px; }
    .list-avatars.right .list-child.avatar-small {
      margin-left: -20px; }
    .list-avatars.right .list-child:first-child {
      margin-left: 0; }
  .list-avatars.left {
    flex-direction: row-reverse; }
    .list-avatars.left .list-child {
      margin-right: -26px; }
      .list-avatars.left .list-child.avatar-medium {
        margin-right: -26px; }
      .list-avatars.left .list-child.avatar-small {
        margin-right: -20px; }
      .list-avatars.left .list-child:first-child {
        margin-right: 0; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.zkn-btn.btn {
  width: 200px;
  min-height: 48px;
  border-radius: 16px;
  color: #f8f9fa; }
  .zkn-btn.btn.btn-table {
    min-height: 0;
    line-height: 1.375rem;
    white-space: nowrap;
    width: fit-content;
    padding: 0.5rem 1rem; }

.zkn-btn.btn-width-auto {
  width: 150px !important; }

.zkn-btn.btn-white {
  border-radius: 18px;
  border: none;
  color: #778699;
  background-color: white; }

.zkn-btn.btn-social {
  border: 1px solid rgba(119, 134, 153, 0.2); }
  .zkn-btn.btn-social div {
    position: relative; }
    .zkn-btn.btn-social div svg {
      position: absolute;
      left: 0; }
    .zkn-btn.btn-social div span {
      position: relative;
      color: #2C3F78; }

.zkn-btn.znk-size-small {
  min-height: 26px; }

.zkn-btn.znk-size-medium {
  min-height: 44px; }

.zkn-btn.znk-size-large {
  min-height: 56px; }

.zkn-btn .znk-activity {
  display: flex;
  justify-content: center; }

.zkn-btn .znk-check {
  display: flex;
  justify-content: space-between; }
  .zkn-btn .znk-check span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5; }

.zkn-btn.btn-blue-border {
  background-color: white;
  border: 1.5px solid #696CFF;
  color: #696CFF; }

.zkn-btn.btn-gray-border {
  background-color: white;
  border: 2px solid #f3f5f7;
  color: #778699; }

.zkn-btn.btn-warning {
  background-color: #fff7d7;
  border: none;
  color: #E6AC00; }

.zkn-btn.btn-primary {
  background-color: #696CFF;
  border: none;
  color: white; }

.zkn-btn.btn-gray {
  background-color: #778699;
  border: none;
  color: white; }

.zkn-btn.btn-white {
  background-color: white;
  border: 1px solid #D6DAE3;
  color: #2C3F58; }

.zkn-btn.btn-counseling {
  background-color: #2c3f58;
  border: none;
  color: white; }

.znk-button-options-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: space-between; }
  .znk-button-options-content .znk-button-options-left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 80%;
    border-right: 1px solid white; }
  .znk-button-options-content .znk-button-options-right {
    width: 20%; }
    .znk-button-options-content .znk-button-options-right .zkn-btn {
      width: 100%;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; }

.znk-no-border {
  background-color: white !important;
  border: 0px !important; }

.znk-border-right {
  border: 0px;
  background-color: white !important; }

.znk-border-input-message {
  border-top: 1px solid #DCE1E7 !important;
  border-bottom: 1px solid #DCE1E7 !important;
  border-left: 1px solid #DCE1E7 !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px; }

.znk-border-input-send {
  border-top: 1px solid #DCE1E7 !important;
  border-bottom: 1px solid #DCE1E7 !important;
  border-right: 1px solid #DCE1E7 !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  color: #778699;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer; }

.znk-border-input-send-type {
  border-top: 1px solid #DCE1E7 !important;
  border-bottom: 1px solid #DCE1E7 !important;
  border-right: 1px solid #DCE1E7 !important;
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  color: #696CFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5; }

.znk-border-input-message:hover,
.znk-border-input-message:active,
.znk-border-input-message:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.calendarDay-week .calendarDay-number:hover {
  transition: 0s;
  background-color: #F0F4F8; }

.calendarDayContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  .calendarDayContent .calendarDay {
    width: 30px;
    height: 30px;
    border-radius: 50%; }
    .calendarDayContent .calendarDay span {
      text-align: center;
      line-height: 10px;
      font-size: 14px;
      color: #696CFF; }
      .calendarDayContent .calendarDay span.lessonDot {
        font-size: 25px;
        line-height: 0px; }
    .calendarDayContent .calendarDay.empty span {
      color: #72809d; }
    .calendarDayContent .calendarDay.available {
      background-color: #eeeeff; }
    .calendarDayContent .calendarDay.selected {
      background-color: #696CFF; }
      .calendarDayContent .calendarDay.selected span {
        color: #ffffff; }
      .calendarDayContent .calendarDay.selected.redDay {
        background-color: #FF3E1D; }
    .calendarDayContent .calendarDay.disabled span {
      color: #D7E1EA; }

.frame {
  overflow: hidden;
  min-width: 50px !important;
  transition: .4s;
  padding: 0;
  margin: 0; }
  .frame div {
    transition: .4s;
    position: relative;
    margin: 0;
    padding: 0; }

.dragIcon {
  display: flex;
  justify-content: center;
  margin: 5px;
  padding: 0;
  width: 100%; }
  .dragIcon div {
    height: 4px;
    width: 30px;
    border-radius: 30px;
    background-color: #eeeeff; }

.dragIcon:hover {
  cursor: pointer; }

.calendar {
  flex: 1; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

textarea {
  resize: none; }

.znk-input {
  background-color: #F0F4F8;
  border-radius: 12px;
  border: none;
  color: #2C3F58;
  font-weight: 500;
  line-height: 1.5; }
  .znk-input.placeholder-to-right {
    text-align: right; }
    .znk-input.placeholder-to-right:hover, .znk-input.placeholder-to-right:active, .znk-input.placeholder-to-right:focus {
      text-align: right; }
    .znk-input.placeholder-to-right::placeholder {
      text-align: right; }
  .znk-input.error {
    color: #FF3E1D; }
  .znk-input:hover, .znk-input:active, .znk-input:focus {
    background-color: #F0F4F8;
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important; }
  .znk-input::placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input::-webkit-input-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input::-moz-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input:-ms-input-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input:-moz-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input.noRight {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .znk-input.noLeft {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .znk-input.znk-table-input {
    border-radius: 16px;
    background-color: transparent; }

.znk-card .icon {
  background-color: #F0F4F8;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border: none;
  color: #2C3F58;
  min-height: 44px; }
  .znk-card .icon span {
    background-color: #F0F4F8; }

.znk-card .addon {
  color: #778699;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; }

.znk-no-border {
  background-color: #F0F4F8 !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; }

.znk-segment {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .znk-segment .left {
    width: calc(50% - 2px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .znk-segment .right {
    width: calc(50% - 2px);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

.znk-select.special {
  margin-left: 3px;
  margin-right: 3px; }
  .znk-select.special button {
    border-radius: 0px !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-list-option {
  width: 100%;
  background-color: #F0F4F8;
  line-height: 1.7;
  font-size: 1rem;
  padding: 0.625rem 0.75rem 0.625rem 1rem;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .znk-list-option span {
    margin-left: 4px;
    color: #778699;
    font-weight: 400; }
  .znk-list-option .icon-list-option {
    color: white; }
  .znk-list-option.processing {
    background-color: #D7E1EA; }
  .znk-list-option.selected {
    background-color: #696CFF; }
    .znk-list-option.selected span, .znk-list-option.selected small {
      color: #f8f9fa;
      font-weight: 500; }
    .znk-list-option.selected .znk-icon-service {
      background-color: #72809D;
      color: white; }
  .znk-list-option.selected-input span, .znk-list-option.selected-input small {
    color: #2C3F58;
    font-weight: 500; }
  .znk-list-option.selected-input .znk-icon-service {
    color: white; }
  .znk-list-option.selected-secondary {
    background-color: #FFB519; }
    .znk-list-option.selected-secondary span, .znk-list-option.selected-secondary small {
      color: #f8f9fa; }
    .znk-list-option.selected-secondary .znk-icon-service {
      color: white; }
  .znk-list-option.selected-counseling {
    background-color: #2c3f58; }
    .znk-list-option.selected-counseling span, .znk-list-option.selected-counseling small {
      color: #f8f9fa; }
    .znk-list-option.selected-counseling .znk-icon-service {
      color: white; }
    .znk-list-option.selected-counseling .znk-list-option-selected-container .znk-list-option-selected-inner {
      background-color: #2c3f58; }

.active {
  color: red; }

.default {
  color: #72809D; }

.notification {
  position: relative;
  width: 24px;
  height: 24px; }
  .notification #circle {
    position: absolute;
    top: 0;
    right: 0;
    background: #FF4343;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    box-shadow: 0 0 0 3px #FFFFFF;
    z-index: 1000; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-notification {
  width: 100%;
  line-height: 1.375rem;
  border-radius: 12px;
  font-size: 0.875rem;
  display: flex;
  padding: 0.75rem 1rem;
  align-items: center; }
  .znk-notification .znk-notif-label {
    flex: 1;
    padding-left: 0.5rem; }
  .znk-notification.znk-with-out {
    border: 1px dashed #72809d; }
    .znk-notification.znk-with-out span {
      color: #d7e1ea;
      line-height: 1.5;
      font-weight: 400; }
    .znk-notification.znk-with-out .znk-icon-out {
      color: #d7e1ea; }
  .znk-notification.znk-with-one {
    border: 1px solid rgba(204, 153, 0, 0.3);
    background-color: #fff5cb; }
    .znk-notification.znk-with-one span {
      color: #cc9900;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400; }
    .znk-notification.znk-with-one .znk-icon-one {
      color: #ffd422; }
  .znk-notification.znk-with-payments {
    border: 1px solid rgba(204, 153, 0, 0.3);
    background-color: #ff3e1d; }
    .znk-notification.znk-with-payments span {
      color: white;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400; }
    .znk-notification.znk-with-payments .znk-icon-one {
      color: white; }
  .znk-notification.znk-with-more {
    border: 1px solid rgba(53, 66, 91, 0.2);
    background-color: #ffffff; }
    .znk-notification.znk-with-more span {
      color: #ff3e1d;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 500; }
    .znk-notification.znk-with-more .znk-icon-more {
      color: #ffffff;
      background-color: #ff3e1d; }
    .znk-notification.znk-with-more .znk-icon-link {
      color: rgba(53, 66, 91, 0.5); }
  .znk-notification.znk-danger {
    background-color: #FF3E1D;
    color: white !important; }
    .znk-notification.znk-danger span {
      color: white !important;
      white-space: nowrap; }
    .znk-notification.znk-danger .znk-icon-one {
      color: white; }
  .znk-notification.znk-go {
    background-color: rgba(67, 179, 160, 0.1);
    border: 1px dashed rgba(67, 179, 160, 0.1); }
    .znk-notification.znk-go span {
      color: #43B3A0 !important;
      white-space: nowrap; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.listItem-primary:hover {
  background-color: #6266ee !important; }

.listItem-secondary:hover {
  background-color: #72809d !important; }

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer; }
  .listItem.listItem-primary {
    background-color: #696CFF; }
    .listItem.listItem-primary * {
      color: white; }
    .listItem.listItem-primary label {
      color: white;
      margin: 0; }
  .listItem.listItem-secondary {
    background-color: #72809d; }
    .listItem.listItem-secondary * {
      color: white; }
    .listItem.listItem-secondary label {
      color: white;
      margin: 0; }
  .listItem.listItem-white {
    background-color: white; }
    .listItem.listItem-white * {
      color: #696cff; }
    .listItem.listItem-white label {
      color: #696cff;
      margin: 0; }
  .listItem.Hover:hover, .listItem.isSelected {
    background-color: #f5f5ff;
    border-radius: 12px; }
  .listItem.secondaryBackground {
    background-color: #f8f9fa;
    border-radius: 12px; }
  .listItem.hover-no-border {
    border-radius: 0px !important; }
  .listItem.ColorHover:hover span {
    color: #696cff; }
    .listItem.ColorHover:hover span svg {
      color: #696cff; }
  .listItem.roundedItem {
    border-radius: 0.625rem;
    padding: 0.5rem 0.75rem;
    line-height: 22px;
    font-weight: 500;
    min-width: 172px;
    cursor: pointer; }
    .listItem.roundedItem div {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      color: #2C3F78; }
      .listItem.roundedItem div label {
        margin: 0; }
    .listItem.roundedItem:hover {
      background-color: rgba(105, 108, 255, 0.1); }
      .listItem.roundedItem:hover label {
        color: #696CFF; }
  .listItem div {
    color: #000000; }
  .listItem svg {
    color: #818ea7;
    height: 16px;
    width: 16px; }

.borderTop {
  width: 100vw;
  border-top: 1px solid rgba(114, 128, 157, 0.2); }
  @media (min-width: 768px) {
    .borderTop {
      width: 100%; }
      .borderTop.forceOverflow {
        width: 100vw; } }
  .borderTop.borderTop-white {
    border-top: 1px solid rgba(44, 63, 88, 0.1); }

.verticalLine {
  height: 100vh;
  border-top: 1px solid rgba(114, 128, 157, 0.2); }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.miniBlock {
  height: 106px;
  width: 162px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #35425B; }
  .miniBlock .mute {
    color: #ffffff;
    opacity: 0.5; }
  .miniBlock .miniBlockLabel {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .miniBlock .miniBlockLabel .badge {
      max-width: 25px; }
    .miniBlock .miniBlockLabel p {
      color: #ffffff;
      white-space: pre-line;
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: lighter; }
    .miniBlock .miniBlockLabel svg {
      opacity: 1; }
  .miniBlock svg {
    color: #ffffff;
    opacity: 0.5; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-actions {
  width: 100%;
  line-height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between; }
  .znk-actions .item-left .actions-icon {
    color: #72809D; }
  .znk-actions .item-left span {
    margin-left: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5; }
  .znk-actions .item-right .actions-icon {
    margin-left: 10px;
    color: #72809D; }
  .znk-actions .item-right span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5; }
  .znk-actions .actions-cursor {
    cursor: pointer; }
  .znk-actions.znk-actions-pendingPayment {
    background-color: rgba(255, 62, 29, 0.2); }
    .znk-actions.znk-actions-pendingPayment .item-right .actions-icon {
      color: #FF3E1D; }
    .znk-actions.znk-actions-pendingPayment .item-right span {
      margin-left: 0;
      color: #778699; }
    .znk-actions.znk-actions-pendingPayment .item-left .actions-icon {
      margin-left: 0;
      color: #FF3E1D; }
    .znk-actions.znk-actions-pendingPayment .item-left span {
      margin-left: 0;
      color: #254050; }
  .znk-actions.znk-actions-paid {
    background-color: rgba(167, 229, 33, 0.2); }
    .znk-actions.znk-actions-paid .item-right .actions-icon {
      color: #81B023; }
    .znk-actions.znk-actions-paid .item-right span {
      color: #778699; }
    .znk-actions.znk-actions-paid .item-left .actions-icon {
      margin-left: 0;
      color: #81B023; }
    .znk-actions.znk-actions-paid .item-left span {
      margin-left: 0;
      color: #254050; }
  .znk-actions.znk-actions-scheduled {
    background-color: #72809d !important; }
    .znk-actions.znk-actions-scheduled .item-right .actions-icon, .znk-actions.znk-actions-scheduled .item-right span {
      color: white !important; }
    .znk-actions.znk-actions-scheduled .item-left .actions-icon, .znk-actions.znk-actions-scheduled .item-left span {
      color: white !important; }
  .znk-actions.znk-actions-attended {
    background-color: rgba(167, 229, 33, 0.5) !important; }
    .znk-actions.znk-actions-attended .item-right .actions-icon, .znk-actions.znk-actions-attended .item-right span {
      color: white !important; }
    .znk-actions.znk-actions-attended .item-left .actions-icon, .znk-actions.znk-actions-attended .item-left span {
      color: white !important; }
  .znk-actions.znk-actions-missed {
    background-color: rgba(255, 62, 29, 0.5) !important; }
    .znk-actions.znk-actions-missed .item-right .actions-icon, .znk-actions.znk-actions-missed .item-right span {
      color: white !important; }
    .znk-actions.znk-actions-missed .item-left .actions-icon, .znk-actions.znk-actions-missed .item-left span {
      color: white !important; }
  .znk-actions.znk-actions-upcoming {
    background-color: #696CFF !important; }
    .znk-actions.znk-actions-upcoming .item-right .actions-icon, .znk-actions.znk-actions-upcoming .item-right span {
      color: white !important; }
    .znk-actions.znk-actions-upcoming .item-left .actions-icon, .znk-actions.znk-actions-upcoming .item-left span {
      color: white !important; }
  .znk-actions.znk-actions-proposed {
    background-color: transparent !important; }
    .znk-actions.znk-actions-proposed .item-right .actions-icon, .znk-actions.znk-actions-proposed .item-right span {
      color: black !important; }
    .znk-actions.znk-actions-proposed .item-left .actions-icon, .znk-actions.znk-actions-proposed .item-left span {
      color: black !important; }
  .znk-actions.znk-actions-incomplete {
    background-color: rgba(255, 212, 34, 0.5) !important; }
    .znk-actions.znk-actions-incomplete .item-right .actions-icon, .znk-actions.znk-actions-incomplete .item-right span {
      color: black !important; }
    .znk-actions.znk-actions-incomplete .item-left .actions-icon, .znk-actions.znk-actions-incomplete .item-left span {
      color: black !important; }
  .znk-actions.znk-actions-default {
    background-color: white !important; }
    .znk-actions.znk-actions-default .item-right .actions-icon, .znk-actions.znk-actions-default .item-right span {
      color: black !important; }
    .znk-actions.znk-actions-default .item-left .actions-icon, .znk-actions.znk-actions-default .item-left span {
      color: black !important; }
  .znk-actions.znk-actions-reminder-active {
    background-color: white !important;
    border: 1px solid #696CFF;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.5rem 1rem;
    line-height: 0.875rem; }
    .znk-actions.znk-actions-reminder-active .item-right .actions-icon, .znk-actions.znk-actions-reminder-active .item-right span {
      color: #696CFF !important; }
    .znk-actions.znk-actions-reminder-active .item-left .actions-icon, .znk-actions.znk-actions-reminder-active .item-left span {
      margin: 0;
      color: #696CFF !important; }
  .znk-actions.znk-actions-reminder {
    background-color: rgba(105, 108, 255, 0.5) !important;
    border: 1px solid #696CFF;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.625rem 1rem;
    line-height: 1rem; }
    .znk-actions.znk-actions-reminder .item-right .actions-icon, .znk-actions.znk-actions-reminder .item-right span {
      color: #696CFF !important; }
    .znk-actions.znk-actions-reminder .item-left .actions-icon, .znk-actions.znk-actions-reminder .item-left span {
      color: #696CFF !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.lessonCard {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  border-radius: 12px;
  width: 100%;
  margin: 0.25rem 0; }
  .lessonCard .lessonCardBody {
    display: flex;
    justify-content: space-between; }
    .lessonCard .lessonCardBody .lessonInfo {
      display: flex;
      font-size: 1rem;
      flex-direction: column;
      color: #ffffff;
      text-align: left; }
    .lessonCard .lessonCardBody .lessonAttendants .namedText {
      flex-direction: row;
      flex-flow: row-reverse; }
      .lessonCard .lessonCardBody .lessonAttendants .namedText .avatar {
        background-color: white; }
        .lessonCard .lessonCardBody .lessonAttendants .namedText .avatar span {
          color: #696CFF; }
      .lessonCard .lessonCardBody .lessonAttendants .namedText .textName {
        margin: 0 0.25rem 0 0; }
  .lessonCard .lessonCardFooter {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: space-between;
    color: #ffffff; }
    .lessonCard .lessonCardFooter span {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 20px; }
  .lessonCard.attended-card {
    background-color: #F3FCD4;
    border: 3px solid #F3FCD4; }
    .lessonCard.attended-card:active {
      border: 3px solid #e5f8ba; }
    .lessonCard.attended-card .lessonCardBody .lessonInfo {
      color: #81B023; }
    .lessonCard.attended-card .lessonCardBody .lessonAttendants .namedText span {
      color: #81B023; }
    .lessonCard.attended-card .lessonCardFooter {
      color: #81B023;
      border-color: #81B023; }
      .lessonCard.attended-card .lessonCardFooter .znk-rating .star.mini {
        color: #81B023; }
  .lessonCard.missed-card {
    background-color: #FFEEEB;
    border: 3px solid #FFEEEB; }
    .lessonCard.missed-card:active {
      border: 3px solid rgba(255, 62, 29, 0.1); }
    .lessonCard.missed-card .lessonCardBody .lessonInfo {
      color: #FF3E1D; }
    .lessonCard.missed-card .lessonCardBody .lessonAttendants .namedText span {
      color: #FF3E1D; }
    .lessonCard.missed-card .lessonCardFooter {
      color: #FF3E1D;
      border-color: #FF3E1D; }
      .lessonCard.missed-card .lessonCardFooter .znk-rating .star.mini {
        color: #FF3E1D; }
  .lessonCard.upcoming-card {
    border: 3px solid #696CFF;
    background-color: #696CFF; }
    .lessonCard.upcoming-card:active {
      border: 3px solid #d5d9e2; }
  .lessonCard.scheduled-card {
    border: 3px solid #72809d;
    background-color: #72809d; }
    .lessonCard.scheduled-card:active {
      border: 3px solid #d5d9e2; }
  .lessonCard.pending-card {
    background-color: transparent;
    border: 1px solid #696CFF; }
    .lessonCard.pending-card .lessonCardBody .lessonInfo {
      color: #0308ff; }
    .lessonCard.pending-card .lessonCardBody .lessonAttendants .avatar {
      background-color: #696CFF; }
      .lessonCard.pending-card .lessonCardBody .lessonAttendants .avatar span {
        color: white; }
    .lessonCard.pending-card .lessonCardBody .lessonAttendants .namedText .textName {
      color: #0308ff; }
  .lessonCard.incomplete-card {
    background-color: #FFF5CB;
    border: 3px solid #FFF5CB; }
    .lessonCard.incomplete-card:active {
      border: 3px solid rgba(230, 172, 0, 0.1); }
    .lessonCard.incomplete-card .lessonCardBody .lessonInfo {
      color: #E6AC00; }
    .lessonCard.incomplete-card .lessonCardBody .lessonAttendants .namedText span {
      color: #bb9700; }
    .lessonCard.incomplete-card .lessonCardFooter {
      border-color: rgba(255, 212, 34, 0.5);
      color: #bb9700; }
      .lessonCard.incomplete-card .lessonCardFooter .znk-rating .star.mini {
        color: #bb9700; }
  .lessonCard.incompleteLesson-card {
    background-color: #FFF5CB;
    border: 3px solid #FFF5CB; }
    .lessonCard.incompleteLesson-card:active {
      border: 3px solid #e6ac00; }
    .lessonCard.incompleteLesson-card .lessonCardBody .lessonInfo {
      color: #E6AC00; }
    .lessonCard.incompleteLesson-card .lessonCardBody .lessonAttendants .namedText span {
      color: #E6AC00; }
    .lessonCard.incompleteLesson-card .lessonCardFooter {
      border-color: #E6AC00;
      color: #E6AC00; }
      .lessonCard.incompleteLesson-card .lessonCardFooter .znk-rating .star.mini {
        color: #E6AC00; }
  .lessonCard.inprogress-card {
    background-color: rgba(255, 212, 34, 0.5);
    border: 3px solid rgba(255, 212, 34, 0.5); }
    .lessonCard.inprogress-card:active {
      border: 3px solid #ffd422; }
    .lessonCard.inprogress-card div {
      color: #bb9700; }
    .lessonCard.inprogress-card .lessonCardBody .lessonInfo {
      color: #bb9700; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.examCard {
  min-height: 126px;
  min-width: 92px;
  max-width: 92px;
  width: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #FF7733; }
  .examCard .examNumberCard {
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    justify-content: space-between;
    font-size: 24px; }
    .examCard .examNumberCard .examLogo {
      color: #ffffff; }
  .examCard .examTitle {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500; }
    .examCard .examTitle.medium {
      font-size: 12px; }
  .examCard .examSubTitle {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500; }
    .examCard .examSubTitle.medium {
      font-size: 12px; }
  .examCard.small {
    clip-path: polygon(85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
    border-radius: 5px;
    min-height: 60px;
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    height: 60px;
    padding: 1px; }
    .examCard.small:before {
      width: 10px;
      height: 10px; }
  .examCard.medium {
    clip-path: polygon(85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
    border-radius: 5px;
    min-height: 122px;
    min-width: 90px;
    max-width: 90px;
    width: 90px;
    height: 122px;
    padding: 1px; }
  .examCard.examCard-purple {
    background-color: #B44BE1; }
  .examCard.examCard-green {
    background-color: #38A895; }
  .examCard.examCard-orange {
    background-color: #FF7733; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.planCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  border-radius: 12px;
  font-size: 12px;
  line-height: 18px;
  background-color: #7B29CC;
  background-position: 90% -240%, 106% 136%;
  background-size: 135px, 76px;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .planCard {
      min-width: 300px; } }
  @media (min-width: 992px) {
    .planCard {
      min-width: fit-content; } }
  .planCard.pending {
    border: 2px solid #696CFF;
    color: #696CFF;
    background-color: white;
    background-position: 90% -156%, 95% 115%;
    background-size: 100px, 70px;
    background-repeat: no-repeat; }
  .planCard.counseling {
    border: 2px solid #2C3F58;
    color: #9aa1af;
    background-color: white;
    background-position: 90% -156%, 95% 202%;
    background-size: 100px, 70px;
    background-repeat: no-repeat; }
    .planCard.counseling .star {
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      background-color: #2C3F58;
      width: 18px;
      height: 18px; }
  .planCard.counseling-scheduled {
    color: white;
    background-color: #2c3f58;
    background-position: 90% -156%, 95% 145%;
    background-size: 100px, 70px;
    background-repeat: no-repeat; }
    .planCard.counseling-scheduled .star {
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      background-color: white;
      width: 18px;
      height: 18px; }
  .planCard.planUpcoming {
    opacity: 0.5; }
  .planCard .planTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px; }
  .planCard .planLessonsPerWeek {
    opacity: 0.8; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.serviceCard {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  border-radius: 12px;
  min-height: 261px;
  min-width: 334px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400; }
  .serviceCard .serviceTitle {
    font-weight: 700;
    font-size: 24px; }
  .serviceCard .serviceDate {
    font-size: 16px;
    line-height: 20px; }
  .serviceCard .serviceFooter {
    display: flex;
    color: #ffffff; }
  .serviceCard.enrolled {
    background-color: #1298B3 !important;
    background-position: 133% -34%, 38% 17%;
    background-size: 149px, 49px;
    background-repeat: no-repeat; }
  .serviceCard.recomendation {
    background-color: #FF8C19 !important;
    background-position: 40% -57%, 117% -26%;
    background-size: 144px, 102px;
    background-repeat: no-repeat; }

@keyframes appears {
  0% {
    opacity: 0;
    height: 0; }
  50% {
    opacity: .6;
    height: 40px; }
  100% {
    opacity: 1; } }

.znk-task {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  background-color: #F0F4F8; }
  .znk-task.task-appear-animarion {
    animation-name: appears;
    animation-duration: .4s; }
  .znk-task .task-left {
    display: flex;
    flex-direction: row;
    width: 70%; }
    .znk-task .task-left.dont-show-label-right {
      width: 90%; }
    .znk-task .task-left .star {
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      background-color: #2c3f58;
      display: inline-table;
      vertical-align: middle;
      width: 15px !important;
      max-width: 15px !important;
      min-width: 15px !important;
      height: 15px !important;
      align-self: center; }
    .znk-task .task-left span {
      color: #254050;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5; }
    .znk-task .task-left .label {
      display: flex;
      flex-direction: row; }
    .znk-task .task-left.no-icon span {
      margin-left: 0px; }
    .znk-task .task-left .task-icon {
      color: #FFB519; }
  .znk-task span {
    width: 10%;
    color: #254050;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5; }
  .znk-task span.late {
    color: red; }
  .znk-task .late {
    color: #35425B; }
  .znk-task.exam-modal {
    background-color: #b54ae2; }
  .znk-task .task-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 30%; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-task-ready {
  background-color: #f2f2ff;
  border-radius: 12px;
  padding: 15px; }
  .znk-task-ready p {
    font-size: 14px;
    margin: 0; }
  .znk-task-ready .icon-span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }

.znk-news {
  width: 334px;
  height: 250px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 60px; }
  .znk-news .container {
    display: flex;
    flex-direction: column; }
    .znk-news .container.znk-split {
      margin-top: 90px;
      height: 110px;
      background-color: #F0F4F8;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px; }
      .znk-news .container.znk-split .category {
        margin-top: 10px; }
  .znk-news .category {
    color: #7079FF;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5; }
  .znk-news .title {
    color: #2C3F58;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 10px; }
  .znk-news .new {
    color: #778699;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 10px; }
  .znk-news.znk-split {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 50px; }
  .znk-news.znk-text {
    background-color: #F0F4F8; }
  .znk-news.znk-image .category {
    color: #FFFFFF; }
  .znk-news.znk-image .title {
    color: #FFFFFF; }
  .znk-news.znk-image .new {
    color: #FFFFFF; }

.znk-select {
  width: 100%;
  min-height: 44px;
  border-radius: 12px; }
  .znk-select .selectedValue {
    background-color: #F0F4F8;
    border: none;
    color: #778699;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px !important; }
    .znk-select .selectedValue:active, .znk-select .selectedValue:focus, .znk-select .selectedValue.active, .znk-select .selectedValue.focus, .znk-select .selectedValue:after {
      background-color: #F0F4F8 !important;
      border: none !important;
      box-shadow: none !important;
      color: #778699 !important; }
    .znk-select .selectedValue.noRight {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important; }
    .znk-select .selectedValue.noLeft {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important; }
  .znk-select .list {
    width: 100%;
    background-color: #F0F4F8;
    border: none;
    border-radius: 12px; }
  .znk-select .both {
    border-radius: 12px !important; }
  .znk-select .rect {
    border-radius: 0px !important; }
  .znk-select .left {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important; }
  .znk-select .right {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important; }

.znk-list-item {
  padding-left: 10px;
  padding-right: 10px; }
  .znk-list-item.compact {
    padding-top: 12px;
    padding-bottom: 12px; }
  .znk-list-item.small {
    padding-top: 8px;
    padding-bottom: 8px; }
  .znk-list-item.micro {
    padding-top: 4px;
    padding-bottom: 4px; }

.full {
  border-color: rgba(44, 63, 88, 0.1);
  margin-top: 8px;
  margin-bottom: 4px; }

.divider {
  margin-left: 24px;
  border-color: rgba(44, 63, 88, 0.1);
  margin-top: 8px;
  margin-bottom: 4px; }

.middle {
  margin-left: 24px;
  margin-right: 24px;
  border-color: rgba(44, 63, 88, 0.1);
  margin-top: 8px;
  margin-bottom: 4px; }

.ld-full {
  border-color: rgba(53, 66, 91, 0.2);
  border-width: 2px; }

.ld-divider {
  margin-left: 24px;
  border-color: rgba(52, 66, 91, 0.2);
  border-width: 2px; }

.ld-middle {
  margin-left: 24px;
  margin-right: 24px;
  border-color: rgba(53, 66, 91, 0.2);
  border-width: 2px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-card {
  width: 100%;
  min-height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.75rem; }
  .znk-card .left {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .znk-card .left svg.normal {
      color: #254050; }
    .znk-card .left svg.warn {
      color: #FF3E1D; }
    .znk-card .left svg.green {
      color: #778699; }
    .znk-card .left svg.counseling {
      color: white; }
    .znk-card .left .text {
      line-break: auto; }
  .znk-card .text {
    margin-left: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5; }
  .znk-card.counseling {
    background-color: #35425B; }
  .znk-card.normal {
    background-color: #F0F4F8; }
  .znk-card.warn {
    background-color: #FFF3F0; }
  .znk-card.green {
    background-color: #F3FCD4; }
  .znk-card.upcomming {
    background-color: #696CFF;
    color: white; }
  .znk-card.pendingPayment {
    background-color: #F0F4F8; }
  .znk-card.paid {
    background-color: #F3FCD4; }
  .znk-card.unPaid {
    background-color: #FFEEEB; }
  .znk-card.empty {
    background-color: transparent;
    border: 1px solid #DCE1E7; }
  .znk-card .counseling-text {
    color: white; }
  .znk-card .normal-text {
    color: #254050; }
  .znk-card .warn-text {
    color: #FF3E1D; }
  .znk-card .unPaid-text {
    color: #FF3E1D; }
  .znk-card .green-text {
    color: #778699; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-invoice {
  width: 100%;
  min-height: 175px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column; }
  .znk-invoice .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 15px; }
  .znk-invoice.noHeadBorder .header {
    border-bottom: none;
    padding-bottom: 8px; }
  .znk-invoice.useBodyBorder .body {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding-top: 15px; }
  .znk-invoice .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    flex-grow: 1; }
    .znk-invoice .body .color-normal {
      color: white !important; }
    .znk-invoice .body .color-dark {
      color: #696CFF !important; }
    .znk-invoice .body .color-warn {
      color: #FF3E1D !important; }
    .znk-invoice .body .color-error {
      color: #FF3E1D !important; }
    .znk-invoice .body .color-paid {
      color: #81B023 !important; }
    .znk-invoice .body .color-canceled {
      color: #D7E1EA !important; }
  .znk-invoice .text {
    font-size: 14px; }
  .znk-invoice .text-pay {
    font-weight: 700;
    color: #FFFFFF; }
  .znk-invoice .month-pay {
    font-weight: 400;
    color: #FFFFFF; }
  .znk-invoice .card-pay {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5); }
  .znk-invoice .text-amount {
    font-size: 32px; }
  .znk-invoice.color-normal {
    background-color: #696CFF; }
    .znk-invoice.color-normal .text-pay {
      color: #FFFFFF; }
    .znk-invoice.color-normal .month-pay {
      color: #FFFFFF; }
    .znk-invoice.color-normal .text-amount {
      color: #FFFFFF; }
    .znk-invoice.color-normal .text-invoice {
      color: #FFFFFF; }
  .znk-invoice.color-dark {
    background-color: #35425B; }
    .znk-invoice.color-dark .text-pay {
      color: #FFFFFF; }
    .znk-invoice.color-dark .month-pay {
      color: #FFFFFF; }
    .znk-invoice.color-dark .text-amount {
      color: #FFFFFF; }
    .znk-invoice.color-dark .text-invoice {
      color: #FFFFFF; }
  .znk-invoice.color-warn {
    background-color: #FFF3F0; }
    .znk-invoice.color-warn .text-pay {
      color: #FF3E1D; }
    .znk-invoice.color-warn .month-pay {
      color: #FF3E1D; }
    .znk-invoice.color-warn .text-amount {
      color: #FF3E1D; }
    .znk-invoice.color-warn .text-invoice {
      color: #FF3E1D; }
  .znk-invoice.color-paid {
    background-color: #F3FCD4; }
    .znk-invoice.color-paid .text-pay {
      color: #81B023; }
    .znk-invoice.color-paid .month-pay {
      color: #81B023; }
    .znk-invoice.color-paid .text-amount {
      color: #81B023; }
    .znk-invoice.color-paid .text-invoice {
      color: #81B023; }
  .znk-invoice.color-canceled {
    background-color: #F0F4F8; }
    .znk-invoice.color-canceled .text-pay {
      color: #778699; }
    .znk-invoice.color-canceled .month-pay {
      color: #778699; }
    .znk-invoice.color-canceled .text-amount {
      color: #778699; }
    .znk-invoice.color-canceled .text-invoice {
      color: #778699; }

.znk-invoice-row {
  line-height: 1.375rem;
  font-size: 0.875rem;
  font-weight: 400;
  display: flex;
  border-radius: 0.75rem;
  padding: 0.5rem 0.75rem;
  margin: 0.25rem 0; }
  .znk-invoice-row :first-child {
    justify-content: flex-start !important; }
  .znk-invoice-row :last-child {
    justify-content: flex-end !important; }
  .znk-invoice-row span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; }
    .znk-invoice-row span svg {
      height: 1rem;
      width: 1rem; }
  .znk-invoice-row.pendingPayment {
    background-color: #FFF3F0;
    color: #778699; }
    .znk-invoice-row.pendingPayment svg {
      color: #FF3E1D; }
    .znk-invoice-row.pendingPayment .icon-pay {
      color: #FF3E1D; }
    .znk-invoice-row.pendingPayment .text-color {
      color: #778699; }
    .znk-invoice-row.pendingPayment .opacity {
      color: rgba(119, 134, 153, 0.6); }
    .znk-invoice-row.pendingPayment .hover-icon:hover {
      background-color: #ffd6cc; }
  .znk-invoice-row.procesingPayment {
    color: white; }
    .znk-invoice-row.procesingPayment svg {
      color: white; }
    .znk-invoice-row.procesingPayment .icon-pay {
      color: white; }
    .znk-invoice-row.procesingPayment.livePrep {
      background-color: #696CFF; }
      .znk-invoice-row.procesingPayment.livePrep .hover-icon:hover {
        background-color: #4549ff; }
    .znk-invoice-row.procesingPayment.counseling {
      background-color: #35425B; }
      .znk-invoice-row.procesingPayment.counseling .hover-icon:hover {
        background-color: #283244; }
  .znk-invoice-row.paid {
    background-color: #F3FCD4;
    color: #254050; }
    .znk-invoice-row.paid svg {
      color: #81B023; }
    .znk-invoice-row.paid .icon-pay {
      color: #81B023; }
    .znk-invoice-row.paid .text-color {
      color: #254050; }
    .znk-invoice-row.paid .opacity {
      color: rgba(37, 64, 80, 0.6); }
    .znk-invoice-row.paid .hover-icon:hover {
      background-color: #eafab3; }
  .znk-invoice-row.canceled {
    background-color: #F0F4F8;
    color: #778699; }
    .znk-invoice-row.canceled svg {
      color: #35425B; }
    .znk-invoice-row.canceled .icon-pay {
      color: #35425B; }
    .znk-invoice-row.canceled .text-color {
      color: #778699; }
    .znk-invoice-row.canceled .opacity {
      color: rgba(119, 134, 153, 0.6); }
    .znk-invoice-row.canceled .hover-icon:hover {
      background-color: #d8e2ed; }
  .znk-invoice-row.counseling {
    background-color: #35425B;
    color: #ffffff; }
    .znk-invoice-row.counseling svg {
      color: #ffffff; }
    .znk-invoice-row.counseling .icon-pay {
      color: #ffffff; }
    .znk-invoice-row.counseling .text-color {
      color: #ffffff; }
    .znk-invoice-row.counseling .opacity {
      color: rgba(255, 255, 255, 0.6); }
    .znk-invoice-row.counseling .hover-icon:hover {
      background-color: #283244; }
  .znk-invoice-row.livePrep, .znk-invoice-row.livePrep-exam, .znk-invoice-row.livePrep-lessons, .znk-invoice-row.livePrep-marathon {
    background-color: #696CFF;
    color: #ffffff; }
    .znk-invoice-row.livePrep svg, .znk-invoice-row.livePrep-exam svg, .znk-invoice-row.livePrep-lessons svg, .znk-invoice-row.livePrep-marathon svg {
      color: #ffffff; }
    .znk-invoice-row.livePrep .icon-pay, .znk-invoice-row.livePrep-exam .icon-pay, .znk-invoice-row.livePrep-lessons .icon-pay, .znk-invoice-row.livePrep-marathon .icon-pay {
      color: #ffffff; }
    .znk-invoice-row.livePrep .text-color, .znk-invoice-row.livePrep-exam .text-color, .znk-invoice-row.livePrep-lessons .text-color, .znk-invoice-row.livePrep-marathon .text-color {
      color: #ffffff; }
    .znk-invoice-row.livePrep .opacity, .znk-invoice-row.livePrep-exam .opacity, .znk-invoice-row.livePrep-lessons .opacity, .znk-invoice-row.livePrep-marathon .opacity {
      color: rgba(255, 255, 255, 0.6); }
    .znk-invoice-row.livePrep .hover-icon:hover, .znk-invoice-row.livePrep-exam .hover-icon:hover, .znk-invoice-row.livePrep-lessons .hover-icon:hover, .znk-invoice-row.livePrep-marathon .hover-icon:hover {
      background-color: #4549ff; }
  .znk-invoice-row.upcomming {
    background-color: #F0F4F8;
    color: #254050; }
    .znk-invoice-row.upcomming .svg {
      color: #35425B; }
    .znk-invoice-row.upcomming .icon-pay {
      color: #35425B; }
    .znk-invoice-row.upcomming .text-color {
      color: #254050; }
    .znk-invoice-row.upcomming .opacity {
      color: rgba(37, 64, 80, 0.6); }
    .znk-invoice-row.upcomming .hover-icon:hover {
      background-color: #d8e2ed; }
  .znk-invoice-row.draft {
    background-color: #72809d;
    color: #ffffff; }
    .znk-invoice-row.draft svg {
      color: #ffffff; }
    .znk-invoice-row.draft .icon-pay {
      color: #ffffff; }
    .znk-invoice-row.draft .text-color {
      color: #ffffff; }
    .znk-invoice-row.draft .opacity {
      color: rgba(255, 255, 255, 0.6); }
    .znk-invoice-row.draft .hover-icon:hover {
      background-color: #ededed; }
  .znk-invoice-row.unPaid {
    background-color: #FFF3F0;
    color: #254050; }
    .znk-invoice-row.unPaid svg {
      color: #FF3E1D; }
    .znk-invoice-row.unPaid .icon-pay {
      color: #FF3E1D; }
    .znk-invoice-row.unPaid .text-color {
      color: #254050; }
    .znk-invoice-row.unPaid .opacity {
      color: rgba(37, 64, 80, 0.6); }
    .znk-invoice-row.unPaid .hover-icon:hover {
      background-color: #ffd6cc; }

.text-canceled {
  text-decoration: line-through; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.dropdown {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important; }
  .dropdown .btn-secondary {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important; }
    .dropdown .btn-secondary:hover, .dropdown .btn-secondary:active, .dropdown .btn-secondary:focus, .dropdown .btn-secondary.active, .dropdown .btn-secondary.focus, .dropdown .btn-secondary:after {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important; }

.dropdown-menu {
  margin: 0.125rem;
  padding: 0;
  font-size: 0.75rem;
  min-width: 300px !important;
  border: none;
  left: -44px !important; }
  .dropdown-menu:hover, .dropdown-menu:active, .dropdown-menu:focus, .dropdown-menu.active, .dropdown-menu.focus, .dropdown-menu:after {
    outline: none; }
  .dropdown-menu.right-up-menu {
    left: 50% !important;
    top: 100% !important; }
  .dropdown-menu.show.fixed-left-menu {
    transform: translate3d(-85%, 0px, 0px) !important; }

.znk-new-dropdown-menu,
.ant-dropdown-menu-submenu,
.ant-dropdown-menu-submenu-open,
.ant-dropdown-menu-submenu-active,
.ant-dropdown-menu,
.ant-dropdown-menu-sub {
  border-radius: 10px !important;
  padding: 10px 10px !important; }

.ant-dropdown.min-width-none {
  min-width: auto !important; }

.ant-dropdown.ant-dropdown-arrow-note .ant-dropdown-arrow {
  background-color: #FFF5CB !important;
  border: 1px solid #FFF5CB;
  background: none; }
  .ant-dropdown.ant-dropdown-arrow-note .ant-dropdown-arrow::before {
    background-color: #FFF5CB !important;
    background: none; }

.ant-dropdown.ant-dropdown-arrow-note .ant-dropdown-menu {
  background-color: #FFF5CB !important; }

.ant-dropdown-menu-submenu, .ant-dropdown-menu-submenu-vertical {
  padding: 0px !important; }

.ant-dropdown-menu-note {
  background-color: #FFF5CB !important; }

.znk-new-menu-option,
.ant-dropdown-menu-item,
.ant-dropdown-menu-item-only-child,
.znk-new-menu-option {
  border-radius: 0.75rem;
  margin: 0 1rem;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  font-weight: 500;
  flex: 1;
  cursor: pointer; }
  .znk-new-menu-option:hover,
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item-only-child:hover,
  .znk-new-menu-option:hover {
    background-color: rgba(105, 108, 255, 0.1) !important;
    color: #696CFF !important; }
  .znk-new-menu-option.is-hover,
  .ant-dropdown-menu-item.is-hover,
  .ant-dropdown-menu-item-only-child.is-hover,
  .znk-new-menu-option.is-hover {
    background-color: rgba(105, 108, 255, 0.1) !important;
    color: #696CFF !important; }
  .znk-new-menu-option.danger-option:hover,
  .ant-dropdown-menu-item.danger-option:hover,
  .ant-dropdown-menu-item-only-child.danger-option:hover,
  .znk-new-menu-option.danger-option:hover {
    background-color: rgba(255, 62, 29, 0.1) !important;
    color: #FF3E1D !important; }
  .znk-new-menu-option.danger-option.is-hover,
  .ant-dropdown-menu-item.danger-option.is-hover,
  .ant-dropdown-menu-item-only-child.danger-option.is-hover,
  .znk-new-menu-option.danger-option.is-hover {
    background-color: rgba(255, 62, 29, 0.1) !important;
    color: #FF3E1D !important; }

.ant-dropdown-menu-submenu-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.75rem !important; }
  .ant-dropdown-menu-submenu-title:hover {
    background-color: rgba(105, 108, 255, 0.1) !important;
    color: #696CFF !important; }

.ant-dropdown-menu-submenu-expand-icon {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 10px; }

.ant-dropdown-arrow.ant-dropdown-arrow-note {
  border-color: #FFF5CB !important;
  background-color: #FFF5CB !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.info-desktop {
  width: 100%;
  overflow: hidden; }
  .info-desktop .info-content {
    border-radius: 12px;
    width: 100%;
    background-color: white;
    z-index: 10; }
  .info-desktop .info-body {
    background-color: white;
    z-index: 10;
    border-radius: 12px;
    overflow: hidden;
    margin-top: -20px; }
  .info-desktop .info-header {
    border-radius: 12px 12px 0 0;
    z-index: 5; }
  .info-desktop.invoice-info .info-content {
    background-color: #35425B; }
  .info-desktop.invoice-info .info-header {
    background-color: #35425B; }
    .info-desktop.invoice-info .info-header h1, .info-desktop.invoice-info .info-header h2, .info-desktop.invoice-info .info-header h3, .info-desktop.invoice-info .info-header h4, .info-desktop.invoice-info .info-header h5, .info-desktop.invoice-info .info-header span {
      color: #ffffff; }
    .info-desktop.invoice-info .info-header svg {
      color: #72809d;
      opacity: 0.7; }
  .info-desktop.scheduled-info .info-content {
    background-color: #72809d; }
  .info-desktop.scheduled-info .info-header {
    background-color: #72809d; }
    .info-desktop.scheduled-info .info-header h1, .info-desktop.scheduled-info .info-header h2, .info-desktop.scheduled-info .info-header h3, .info-desktop.scheduled-info .info-header h4, .info-desktop.scheduled-info .info-header h5, .info-desktop.scheduled-info .info-header span {
      color: white; }
    .info-desktop.scheduled-info .info-header svg {
      color: white;
      opacity: 0.7; }
  .info-desktop.attended-info .info-content {
    background: linear-gradient(90deg, rgba(167, 229, 33, 0.2), rgba(167, 229, 33, 0.2)), linear-gradient(90deg, white, white); }
  .info-desktop.attended-info .info-header {
    background: linear-gradient(90deg, rgba(167, 229, 33, 0.2), rgba(167, 229, 33, 0.2)), linear-gradient(90deg, white, white); }
    .info-desktop.attended-info .info-header h1, .info-desktop.attended-info .info-header h2, .info-desktop.attended-info .info-header h3, .info-desktop.attended-info .info-header h4, .info-desktop.attended-info .info-header h5, .info-desktop.attended-info .info-header span, .info-desktop.attended-info .info-header div {
      color: #81B023; }
      .info-desktop.attended-info .info-header h1.badge, .info-desktop.attended-info .info-header h2.badge, .info-desktop.attended-info .info-header h3.badge, .info-desktop.attended-info .info-header h4.badge, .info-desktop.attended-info .info-header h5.badge, .info-desktop.attended-info .info-header span.badge, .info-desktop.attended-info .info-header div.badge {
        color: white; }
    .info-desktop.attended-info .info-header .znk-rating .edit label {
      color: #81B023; }
      .info-desktop.attended-info .info-header .znk-rating .edit label .dot {
        color: #81B023; }
    .info-desktop.attended-info .info-header .znk-rating .edit svg {
      color: #81B023; }
    .info-desktop.attended-info .info-header svg {
      color: #81B023;
      opacity: 0.7; }
  .info-desktop.missed-info .info-content {
    background-color: rgba(255, 62, 29, 0.5); }
  .info-desktop.missed-info .info-header {
    background-color: rgba(255, 62, 29, 0.5); }
    .info-desktop.missed-info .info-header h1, .info-desktop.missed-info .info-header h2, .info-desktop.missed-info .info-header h3, .info-desktop.missed-info .info-header h4, .info-desktop.missed-info .info-header h5, .info-desktop.missed-info .info-header span {
      color: white; }
    .info-desktop.missed-info .info-header svg {
      color: white;
      opacity: 0.7; }
  .info-desktop.upcoming-info .info-content {
    background-color: #696CFF; }
  .info-desktop.upcoming-info .info-header {
    background-color: #696CFF; }
    .info-desktop.upcoming-info .info-header h1, .info-desktop.upcoming-info .info-header h2, .info-desktop.upcoming-info .info-header h3, .info-desktop.upcoming-info .info-header h4, .info-desktop.upcoming-info .info-header h5, .info-desktop.upcoming-info .info-header span {
      color: white; }
    .info-desktop.upcoming-info .info-header svg {
      color: white;
      opacity: 0.7; }
  .info-desktop.proposed-info .info-content {
    background-color: white; }
  .info-desktop.proposed-info .info-header {
    background-color: white; }
    .info-desktop.proposed-info .info-header h1, .info-desktop.proposed-info .info-header h2, .info-desktop.proposed-info .info-header h3, .info-desktop.proposed-info .info-header h4, .info-desktop.proposed-info .info-header h5, .info-desktop.proposed-info .info-header span {
      color: #72809d; }
    .info-desktop.proposed-info .info-header svg {
      color: #72809d;
      opacity: 0.7; }
  .info-desktop.white-info .info-content {
    background-color: white; }
  .info-desktop.white-info .info-header {
    background-color: white; }
    .info-desktop.white-info .info-header h1, .info-desktop.white-info .info-header h2, .info-desktop.white-info .info-header h3, .info-desktop.white-info .info-header h4, .info-desktop.white-info .info-header h5, .info-desktop.white-info .info-header span {
      color: black; }
    .info-desktop.white-info .info-header svg {
      color: black;
      opacity: 0.7; }
  .info-desktop.incomplete-info .info-content {
    background-color: rgba(255, 212, 34, 0.5); }
  .info-desktop.incomplete-info .info-header {
    background-color: rgba(255, 212, 34, 0.5); }
    .info-desktop.incomplete-info .info-header h1, .info-desktop.incomplete-info .info-header h2, .info-desktop.incomplete-info .info-header h3, .info-desktop.incomplete-info .info-header h4, .info-desktop.incomplete-info .info-header h5, .info-desktop.incomplete-info .info-header span {
      color: white; }
    .info-desktop.incomplete-info .info-header svg {
      color: white;
      opacity: 0.7; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-toast {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: auto;
  border-radius: 5%;
  z-index: 10; }
  .znk-toast .znk-toast-body {
    padding: 10px 20px;
    min-width: 300px;
    display: inline-flex;
    justify-content: space-between; }
  .znk-toast.secondary-toast .znk-toast-body {
    background-color: #35425B;
    color: white; }
    .znk-toast.secondary-toast .znk-toast-body h1, .znk-toast.secondary-toast .znk-toast-body h2, .znk-toast.secondary-toast .znk-toast-body h3, .znk-toast.secondary-toast .znk-toast-body h4, .znk-toast.secondary-toast .znk-toast-body h5, .znk-toast.secondary-toast .znk-toast-body span {
      color: white; }
    .znk-toast.secondary-toast .znk-toast-body svg {
      color: white;
      opacity: 0.7; }
  .znk-toast.success-toast .znk-toast-body {
    background-color: rgba(167, 229, 33, 0.5); }
    .znk-toast.success-toast .znk-toast-body h1, .znk-toast.success-toast .znk-toast-body h2, .znk-toast.success-toast .znk-toast-body h3, .znk-toast.success-toast .znk-toast-body h4, .znk-toast.success-toast .znk-toast-body h5, .znk-toast.success-toast .znk-toast-body span {
      color: white; }
    .znk-toast.success-toast .znk-toast-body svg {
      color: white;
      opacity: 0.7; }
  .znk-toast.error-toast .znk-toast-body {
    background-color: rgba(255, 62, 29, 0.5); }
    .znk-toast.error-toast .znk-toast-body h1, .znk-toast.error-toast .znk-toast-body h2, .znk-toast.error-toast .znk-toast-body h3, .znk-toast.error-toast .znk-toast-body h4, .znk-toast.error-toast .znk-toast-body h5, .znk-toast.error-toast .znk-toast-body span {
      color: white; }
    .znk-toast.error-toast .znk-toast-body svg {
      color: white;
      opacity: 0.7; }
  .znk-toast.primary-toast .znk-toast-body {
    background-color: #696CFF; }
    .znk-toast.primary-toast .znk-toast-body h1, .znk-toast.primary-toast .znk-toast-body h2, .znk-toast.primary-toast .znk-toast-body h3, .znk-toast.primary-toast .znk-toast-body h4, .znk-toast.primary-toast .znk-toast-body h5, .znk-toast.primary-toast .znk-toast-body span {
      color: white; }
    .znk-toast.primary-toast .znk-toast-body svg {
      color: white;
      opacity: 0.7; }
  .znk-toast.white-toast .znk-toast-body {
    background-color: white; }
    .znk-toast.white-toast .znk-toast-body h1, .znk-toast.white-toast .znk-toast-body h2, .znk-toast.white-toast .znk-toast-body h3, .znk-toast.white-toast .znk-toast-body h4, .znk-toast.white-toast .znk-toast-body h5, .znk-toast.white-toast .znk-toast-body span {
      color: black; }
    .znk-toast.white-toast .znk-toast-body svg {
      color: black;
      opacity: 0.7; }
  .znk-toast.warning-toast .znk-toast-body {
    background-color: rgba(255, 212, 34, 0.5); }
    .znk-toast.warning-toast .znk-toast-body h1, .znk-toast.warning-toast .znk-toast-body h2, .znk-toast.warning-toast .znk-toast-body h3, .znk-toast.warning-toast .znk-toast-body h4, .znk-toast.warning-toast .znk-toast-body h5, .znk-toast.warning-toast .znk-toast-body span {
      color: white; }
    .znk-toast.warning-toast .znk-toast-body svg {
      color: white;
      opacity: 0.7; }
  .znk-toast .znk-toast-content {
    width: 100%; }

.znk-layout-payment {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 400px, 240px, 190px, 258px, 59px;
  background-position: 92% -40%, 102% 86%, 0% 30%, 0% 109%, 71% 30%;
  opacity: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.outline {
  border-radius: 15px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.frameDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  @media (min-width: 425px) {
    .frameDay {
      width: 40px;
      height: 40px; } }
  .frameDay.listItemDay {
    width: 28px;
    height: 28px; }
  .frameDay span {
    text-align: center;
    line-height: 10px;
    font-size: 14px;
    color: #696CFF; }
    .frameDay span.lessonDot {
      font-size: 25px;
      line-height: 0px; }
  .frameDay.blocked {
    background-color: #F0F4F8; }
  .frameDay.free {
    background-color: transparent;
    border: 2px dashed #F0F4F8; }
  .frameDay.checkPending {
    background-color: #FFB519;
    border: none; }
  .frameDay.check {
    background-color: #72809D;
    border: none; }
  .frameDay.blockedManual {
    background-color: #F0F4F8; }
  .frameDay.checkPendingManual {
    background-color: #696CFF;
    border: none; }
  .frameDay.checkManual {
    background-color: #72809D;
    border: none; }
  .frameDay.frameDay-load {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.znk-scheduled-calendar div .znk-scheduled-calendar-span-hour {
  white-space: nowrap; }

.znk-scheduled-calendar .znk-scheduled-calendar-span-header {
  position: absolute;
  top: -20px; }
  .znk-scheduled-calendar .znk-scheduled-calendar-span-header.znk-scheduled-calendar-span-header-day {
    top: -30px; }
  .znk-scheduled-calendar .znk-scheduled-calendar-span-header .znk-scheduled-calendar-span-day {
    text-align: center;
    width: 25px !important; }
    .znk-scheduled-calendar .znk-scheduled-calendar-span-header .znk-scheduled-calendar-span-day .znk-scheduled-calendar-span-day-title {
      white-space: nowrap; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.frameDay-for-finder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  @media (min-width: 425px) {
    .frameDay-for-finder {
      width: 40px;
      height: 40px; } }
  .frameDay-for-finder.listItemDay {
    width: 28px;
    height: 28px; }
  .frameDay-for-finder span {
    text-align: center;
    line-height: 10px;
    font-size: 14px;
    color: #696CFF; }
    .frameDay-for-finder span.lessonDot {
      font-size: 25px;
      line-height: 0px; }
  .frameDay-for-finder.blocked {
    background-color: #F0F4F8; }
  .frameDay-for-finder.free {
    background-color: transparent;
    border: 2px dashed #F0F4F8; }
  .frameDay-for-finder.checkPending {
    background-color: #FFB519;
    border: none; }
  .frameDay-for-finder.check {
    background-color: #72809D;
    border: none; }
  .frameDay-for-finder.blockedManual {
    background-color: #F0F4F8; }
  .frameDay-for-finder.checkPendingManual {
    background-color: #696CFF;
    border: none; }
  .frameDay-for-finder.checkManual {
    background-color: #72809D;
    border: none; }
  .frameDay-for-finder.matchBloc {
    background-color: #A7E52124;
    border: 2px solid #A7E521; }
    .frameDay-for-finder.matchBloc span {
      color: #A7E521 !important; }
  .frameDay-for-finder.unmatchBloc {
    background-color: #696CFF24;
    border: 2px dashed #696CFF; }
    .frameDay-for-finder.unmatchBloc span {
      color: #696CFF !important; }
  .frameDay-for-finder.frameDay-load-for-finder {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.znk-scheduled-calendar-for-finder div .znk-scheduled-calendar-span-hour-for-finder {
  white-space: nowrap; }

.znk-scheduled-calendar-for-finder .znk-scheduled-calendar-span-header-for-finder {
  position: absolute;
  top: -20px; }
  .znk-scheduled-calendar-for-finder .znk-scheduled-calendar-span-header-for-finder.znk-scheduled-calendar-span-header-day-for-finder {
    top: -30px; }
  .znk-scheduled-calendar-for-finder .znk-scheduled-calendar-span-header-for-finder .znk-scheduled-calendar-span-day-for-finder {
    text-align: center;
    width: 25px !important; }
    .znk-scheduled-calendar-for-finder .znk-scheduled-calendar-span-header-for-finder .znk-scheduled-calendar-span-day-for-finder .znk-scheduled-calendar-span-day-title-for-finder {
      white-space: nowrap; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.calendarAvailabilityDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  .calendarAvailabilityDay .icon-checkmark {
    display: none; }
  .calendarAvailabilityDay .icon-lesson {
    display: none; }
  .calendarAvailabilityDay .icon-remove {
    display: none; }
  .calendarAvailabilityDay.notAvailable {
    border: 2px dashed #DCE1E7; }
    .calendarAvailabilityDay.notAvailable:hover .icon-checkmark {
      display: block;
      color: #DCE1E7; }
    .calendarAvailabilityDay.notAvailable.selected {
      border-color: #A7E521;
      border-style: solid; }
      .calendarAvailabilityDay.notAvailable.selected .icon-checkmark {
        display: block;
        color: #A7E521; }
  .calendarAvailabilityDay.available {
    border: 2px solid #72809d; }
    .calendarAvailabilityDay.available .icon-checkmark {
      display: block;
      color: #72809d; }
    .calendarAvailabilityDay.available:hover {
      border-style: dashed;
      border-color: #FF3E1D; }
      .calendarAvailabilityDay.available:hover .icon-remove {
        display: block;
        color: #FF3E1D; }
      .calendarAvailabilityDay.available:hover .icon-checkmark {
        display: none; }
    .calendarAvailabilityDay.available.selected {
      border-style: solid;
      border-color: #FF3E1D; }
      .calendarAvailabilityDay.available.selected .icon-remove {
        display: block;
        color: #FF3E1D; }
      .calendarAvailabilityDay.available.selected .icon-checkmark {
        display: none; }
  .calendarAvailabilityDay.hasLesson {
    border: 2px solid #72809d; }
    .calendarAvailabilityDay.hasLesson .icon-lesson {
      display: block;
      color: #72809d; }

.calendar {
  flex: 1; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.calendarRecurringAvailabilityDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 40px;
  align-self: center;
  min-height: 40px;
  text-align: center;
  border-radius: 16px;
  margin: 1px;
  border: 2px dashed #DCE1E7; }
  .calendarRecurringAvailabilityDay .icon-checkmark {
    display: none; }
  .calendarRecurringAvailabilityDay:hover .icon-checkmark {
    display: block;
    color: #DCE1E7; }
  .calendarRecurringAvailabilityDay.selected {
    border-color: #696CFF;
    border-style: solid; }
    .calendarRecurringAvailabilityDay.selected .icon-checkmark {
      display: block;
      color: #696CFF; }

.calendar {
  flex: 1; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.calendarLessonsDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  .calendarLessonsDay .icon-available {
    display: none;
    color: #72809d;
    font-weight: 400; }
  .calendarLessonsDay .icon-checkmark {
    display: none;
    color: white; }
  .calendarLessonsDay.notAvailable {
    background-color: #F0F4F8; }
  .calendarLessonsDay.available {
    border: 2px dashed #DCE1E7; }
    .calendarLessonsDay.available .icon-available {
      display: block; }
    .calendarLessonsDay.available:hover {
      border-color: #696CFF; }
      .calendarLessonsDay.available:hover .icon-available {
        color: #696CFF; }
  .calendarLessonsDay.selectedLesson {
    background-color: #696CFF; }
    .calendarLessonsDay.selectedLesson .icon-checkmark {
      display: block; }
  .calendarLessonsDay.skeleton {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.calendar {
  flex: 1; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.studentLessonsDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: default;
  min-width: 40px;
  min-height: 40px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  .studentLessonsDay .icon-available {
    display: none;
    color: #72809d;
    font-weight: 400; }
  .studentLessonsDay .icon-checkmark {
    display: none;
    color: white; }
  .studentLessonsDay.notAvailable {
    background-color: #F0F4F8; }
    .studentLessonsDay.notAvailable .icon-checkmark {
      display: block;
      color: #D8DDE7; }
  .studentLessonsDay.available {
    border: 2px dashed #DCE1E7; }
  .studentLessonsDay.selectedLesson {
    background-color: #696CFF; }
    .studentLessonsDay.selectedLesson .icon-checkmark {
      display: block; }

.calendar {
  flex: 1; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.calendarGroupLessonDay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 40px;
  align-self: center;
  min-height: 40px;
  text-align: center;
  border-radius: 16px;
  margin: 1px; }
  .calendarGroupLessonDay .icon-available {
    display: none;
    color: #72809d;
    font-weight: 400; }
  .calendarGroupLessonDay .icon-checkmark {
    display: none;
    color: white; }
  .calendarGroupLessonDay.notAvailable {
    background-color: #F0F4F8; }
  .calendarGroupLessonDay.available {
    border: 2px dashed #DCE1E7; }
    .calendarGroupLessonDay.available .icon-available {
      display: block; }
    .calendarGroupLessonDay.available:hover {
      border-color: #696CFF; }
      .calendarGroupLessonDay.available:hover .icon-available {
        color: #696CFF; }
  .calendarGroupLessonDay.selectedLesson {
    background-color: #696CFF; }
    .calendarGroupLessonDay.selectedLesson .icon-checkmark {
      display: block; }

.calendar {
  flex: 1; }

.znk-select-container.inline {
  display: flex; }

.znk-select-container .znk-select-option span {
  margin-left: 5px; }

.znk-select-container .znk-select-option.znk-select-option-selected span {
  font-weight: 600; }

.znk-input-select-formg {
  margin-bottom: 0.3rem; }
  .znk-input-select-formg .znk-input-select {
    background-color: #F0F4F8;
    border-radius: 12px;
    border: none;
    color: #2C3F58;
    font-weight: 500;
    line-height: 1.5;
    height: 44px;
    margin-bottom: 0.3rem; }
    .znk-input-select-formg .znk-input-select.error {
      color: #FF3E1D; }
  .znk-input-select-formg .znk-input-select:hover {
    background-color: #F0F4F8;
    cursor: pointer; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.groupRow {
  border-radius: 0.5rem;
  margin-bottom: 0.25rem; }
  .groupRow.open {
    background-color: rgba(105, 108, 255, 0.05); }
    .groupRow.open .individualRow:hover, .groupRow.open .individualRow:active {
      background-color: rgba(105, 108, 255, 0.05); }

.individualRow {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 0.5rem 0.5rem; }
  .individualRow:hover {
    background-color: rgba(105, 108, 255, 0.05); }
  .individualRow .individualField {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-table-select {
  line-height: 32px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  border: 1px solid #DCE1E7; }
  .znk-table-select .mainBtn {
    border-radius: 12px 0 0 12px;
    padding: 0 0.75rem;
    align-self: center;
    text-align: center;
    border-right: 1px solid #DCE1E7;
    font-weight: 400;
    color: #778699;
    cursor: pointer; }
    .znk-table-select .mainBtn.no-border-right-mainBtn {
      border-right: none; }
    .znk-table-select .mainBtn:hover {
      background-color: #F0F4F8; }
      .znk-table-select .mainBtn:hover:active {
        background-color: #696CFF;
        color: white; }
  .znk-table-select .znk-table-dropdown {
    padding: 0 0.75rem;
    border-radius: 0 12px 12px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .znk-table-select .znk-table-dropdown .znk-table-caret {
      color: #778699; }
    .znk-table-select .znk-table-dropdown.active {
      background-color: #696CFF; }
      .znk-table-select .znk-table-dropdown.active .znk-table-caret {
        color: white; }
    .znk-table-select .znk-table-dropdown:hover {
      background-color: #F0F4F8; }
      .znk-table-select .znk-table-dropdown:hover .znk-table-caret {
        color: #72809d; }
      .znk-table-select .znk-table-dropdown:hover.active {
        background-color: rgba(105, 108, 255, 0.9); }
        .znk-table-select .znk-table-dropdown:hover.active .znk-table-caret {
          color: white; }
      .znk-table-select .znk-table-dropdown:hover:active {
        background-color: #696CFF; }
        .znk-table-select .znk-table-dropdown:hover:active .znk-table-caret {
          color: white; }
    .znk-table-select .znk-table-dropdown .dropdown-menu {
      min-width: 153px;
      width: fit-content;
      padding: 0.75rem 0;
      margin-top: 0.25rem;
      border-radius: 12px;
      border: none; }
      .znk-table-select .znk-table-dropdown .dropdown-menu div {
        font-weight: 500;
        cursor: pointer; }
        .znk-table-select .znk-table-dropdown .dropdown-menu div span {
          border-radius: 0.75rem;
          font-size: 0.875rem;
          margin: 0 1rem;
          padding: 0.5rem 0.5rem;
          display: flex;
          white-space: nowrap;
          align-items: center;
          line-height: 1.375rem;
          font-weight: 500;
          flex: 1; }
          .znk-table-select .znk-table-dropdown .dropdown-menu div span:hover {
            background-color: rgba(105, 108, 255, 0.1);
            color: #696CFF; }
            .znk-table-select .znk-table-dropdown .dropdown-menu div span:hover.hover-disabled {
              background-color: transparent !important;
              color: #778699 !important;
              cursor: not-allowed !important; }
        .znk-table-select .znk-table-dropdown .dropdown-menu div .separator {
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.25rem;
          margin: 0 0.5rem;
          color: #254050;
          flex: 1; }
        .znk-table-select .znk-table-dropdown .dropdown-menu div hr {
          margin-bottom: 0.5rem !important; }
    .znk-table-select .znk-table-dropdown.dropdown-menu-width-content .dropdown-menu {
      min-width: auto !important;
      width: fit-content !important; }

.znk-table-dropdown {
  padding: 0 0.75rem;
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .znk-table-dropdown .znk-table-caret {
    color: #778699; }
  .znk-table-dropdown.active {
    background-color: #696CFF; }
    .znk-table-dropdown.active .znk-table-caret {
      color: white; }
  .znk-table-dropdown:hover .znk-table-caret {
    color: #72809d; }
  .znk-table-dropdown:hover.active {
    background-color: rgba(105, 108, 255, 0.9); }
    .znk-table-dropdown:hover.active .znk-table-caret {
      color: white; }
  .znk-table-dropdown:hover:active {
    background-color: none; }
    .znk-table-dropdown:hover:active .znk-table-caret {
      color: white; }
  .znk-table-dropdown .dropdown-menu {
    min-width: 153px;
    width: fit-content;
    padding: 0.75rem 0;
    margin-top: 0.25rem;
    border-radius: 12px;
    border: none; }
    .znk-table-dropdown .dropdown-menu div {
      font-weight: 500;
      cursor: pointer;
      white-space: initial; }
      .znk-table-dropdown .dropdown-menu div span {
        border-radius: 0.75rem;
        font-size: 0.875rem;
        margin: 0 1rem;
        padding: 0.5rem 0.5rem;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 1.375rem;
        font-weight: 500;
        flex: 1; }
        .znk-table-dropdown .dropdown-menu div span:hover {
          background-color: rgba(105, 108, 255, 0.1);
          color: #696CFF; }
          .znk-table-dropdown .dropdown-menu div span:hover.hover-disabled {
            background-color: transparent !important;
            color: #778699 !important;
            cursor: not-allowed !important; }
      .znk-table-dropdown .dropdown-menu div .separator {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem;
        margin: 0 0.5rem;
        color: #254050;
        flex: 1; }
      .znk-table-dropdown .dropdown-menu div hr {
        margin-bottom: 0.5rem !important; }
  .znk-table-dropdown.dropdown-menu-width-content .dropdown-menu {
    min-width: auto !important;
    width: fit-content !important;
    padding: 0.25rem 0; }

.znk-table-dropdown-custom {
  padding: 0 0.75rem;
  border-radius: 0 12px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .znk-table-dropdown-custom .znk-table-caret {
    color: #778699; }
  .znk-table-dropdown-custom .dropdown-menu {
    min-width: 153px;
    width: fit-content;
    padding: 0.75rem 0;
    margin-top: 0.25rem;
    border-radius: 12px;
    border: none; }
    .znk-table-dropdown-custom .dropdown-menu div {
      font-weight: 500;
      cursor: pointer;
      white-space: initial; }
      .znk-table-dropdown-custom .dropdown-menu div .primary {
        border-radius: 0.75rem;
        font-size: 0.875rem;
        margin: 0 1rem;
        padding: 0.5rem 0.5rem;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 1.375rem;
        font-weight: 500;
        flex: 1; }
        .znk-table-dropdown-custom .dropdown-menu div .primary:hover {
          background-color: rgba(105, 108, 255, 0.1);
          color: #696CFF !important; }
      .znk-table-dropdown-custom .dropdown-menu div .warning {
        border-radius: 0.75rem;
        font-size: 0.875rem;
        margin: 0 1rem;
        padding: 0.5rem 0.5rem;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 1.375rem;
        font-weight: 500;
        flex: 1;
        color: #FF3E1D !important; }
        .znk-table-dropdown-custom .dropdown-menu div .warning:hover {
          background-color: rgba(255, 62, 29, 0.1);
          color: #FF3E1D !important; }
      .znk-table-dropdown-custom .dropdown-menu div .separator {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem;
        margin: 0 0.5rem;
        color: #254050;
        flex: 1; }
      .znk-table-dropdown-custom .dropdown-menu div hr {
        margin-bottom: 0.5rem !important; }
  .znk-table-dropdown-custom.dropdown-menu-width-content .dropdown-menu {
    min-width: auto !important;
    width: fit-content !important;
    padding: 0.25rem 0; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.headerRow {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border-radius: 12px; }
  .headerRow:hover {
    background-color: rgba(105, 108, 255, 0.05); }
  .headerRow .headerField {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #696CFF; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.planItem {
  border-radius: 5px;
  cursor: pointer; }
  .planItem:hover {
    background-color: #f7f7ff; }

@keyframes movement {
  from {
    left: 0%; }
  to {
    left: -100%; } }

.zkn-carousel-parent::-webkit-scrollbar {
  display: none; }

.zkn-carousel-parent {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: transparent;
  overflow: hidden; }
  .zkn-carousel-parent .zkn-carousel-child {
    position: relative;
    width: min-content; }
  .zkn-carousel-parent.zkn-carousel-parent-scroll {
    overflow-x: scroll; }
  .zkn-carousel-parent.zkn-carousel-parent-animation {
    overflow-x: hidden;
    -moz-box-shadow: inset 10px 0px 10px 10px white;
    -webkit-box-shadow: inset 10px 0px 10px 10px white;
    box-shadow: inset 10px 0px 10px 10px white; }

.filter-selector *::-webkit-scrollbar-thumb {
  border-radius: 0px; }

.filter-selector .znk-data {
  max-height: 415px; }
  .filter-selector .znk-data.dropdown-menu {
    margin: 0.125rem 0 0 0;
    min-width: 10.5625rem !important; }
    @media (min-width: 768px) {
      .filter-selector .znk-data.dropdown-menu {
        min-width: 16.8125rem; } }

.filter-selector .button-cancel {
  font-size: 0.875rem;
  margin-left: 0.688rem; }

.filter-selector .button-submit {
  color: #7079ff;
  font-size: 0.875rem;
  margin-right: 0.688rem; }

.znk-data-values {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 86%, white 100%), linear-gradient(0deg, rgba(255, 255, 255, 0) 86%, white 100%);
  overflow-y: scroll;
  max-height: 210px; }
  .znk-data-values .znk-data-shadow {
    position: absolute;
    height: 10px;
    width: 100%; }
    .znk-data-values .znk-data-shadow.bottom {
      margin-top: 200px; }

.filter-between-dates .znk-data {
  max-height: 415px; }
  .filter-between-dates .znk-data.dropdown-menu {
    margin: 0;
    padding: 0;
    min-width: 269px; }

.filter-between-dates .znk-btn {
  min-height: 0; }

.filter-between-dates .button-cancel {
  font-size: 0.875rem; }

.filter-between-dates .button-submit {
  color: #7079ff;
  font-size: 0.875rem; }

.filter {
  overflow-x: scroll;
  scrollbar-width: 5px; }

.filter::-webkit-scrollbar {
  display: none; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-bullet-point-container {
  position: relative; }
  .znk-bullet-point-container .znk-bullet-point-children {
    position: relative;
    cursor: pointer; }
  .znk-bullet-point-container .znk-bullet-point {
    width: max-content;
    padding: 0.3125rem;
    position: absolute;
    border-radius: 0.3125rem;
    z-index: 10;
    transition: .5s; }
    .znk-bullet-point-container .znk-bullet-point .triangle {
      position: absolute;
      left: 47%;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white; }
      .znk-bullet-point-container .znk-bullet-point .triangle.triangle-bottom {
        bottom: -8px; }
      .znk-bullet-point-container .znk-bullet-point .triangle.triangle-top {
        top: -8px;
        transform: rotate(180deg); }
      .znk-bullet-point-container .znk-bullet-point .triangle.triangle-left {
        left: -10px;
        transform: rotate(90deg);
        bottom: 49%; }
    .znk-bullet-point-container .znk-bullet-point.znk-bullet-point-stats {
      max-width: 316px; }
    .znk-bullet-point-container .znk-bullet-point.dark-theme {
      background-color: #35425B;
      color: white; }
      .znk-bullet-point-container .znk-bullet-point.dark-theme .triangle {
        border-top: 8px solid #35425B; }
    .znk-bullet-point-container .znk-bullet-point.light-theme {
      background-color: white; }
      .znk-bullet-point-container .znk-bullet-point.light-theme .triangle {
        border-top: 8px solid white; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.input-and-buttons {
  min-width: 230px; }
  .input-and-buttons label {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    line-height: 24px;
    color: #72809d; }
  .input-and-buttons .leftButton {
    width: 20%;
    font-size: 1rem;
    line-height: 24px; }
  .input-and-buttons .mainInput {
    width: 60%;
    margin: 0 0.125rem;
    font-size: 1rem;
    line-height: 24px; }
  .input-and-buttons .rightButton {
    width: 20%;
    font-size: 1rem;
    line-height: 24px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-list-option-selected-container {
  width: 22px;
  height: 22px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .znk-list-option-selected-container .znk-list-option-selected-inner {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #72809D; }
  .znk-list-option-selected-container.znk-list-option-selected-inner-primary .znk-list-option-selected-inner {
    background-color: #696CFF; }
  .znk-list-option-selected-container.znk-list-option-selected-inner-mustard .znk-list-option-selected-inner {
    background-color: #DFA536; }
  .znk-list-option-selected-container.znk-list-option-selected-inner-gold .znk-list-option-selected-inner {
    background-color: #FF7733; }

.znk-pre-input-amount span {
  background-color: #F0F4F8 !important;
  border: 1px solid #F0F4F8;
  border-radius: 12px; }

.znk-post-input-amount span {
  background-color: #F0F4F8 !important;
  border: 1px solid #F0F4F8;
  border-radius: 12px; }

.znk-calendar {
  padding-left: 58px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */ }
  .znk-calendar ::-webkit-scrollbar {
    display: none; }
  .znk-calendar .znk-column-calendar {
    position: relative; }
    .znk-calendar .znk-column-calendar .label-day {
      position: absolute;
      top: -30px;
      background-color: white;
      padding: 10px 0px; }
    .znk-calendar .znk-column-calendar .znk-bloc-calendar {
      border-right: 1px solid rgba(114, 128, 157, 0.2);
      position: relative; }
      .znk-calendar .znk-column-calendar .znk-bloc-calendar .label-hour {
        position: absolute;
        left: -48px;
        top: -9px; }
      .znk-calendar .znk-column-calendar .znk-bloc-calendar:hover {
        cursor: pointer; }
      .znk-calendar .znk-column-calendar .znk-bloc-calendar.disabled-bloc:hover {
        cursor: not-allowed; }

.znk-calendar-loading {
  background-color: black;
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  transition: 0.5s; }
  .znk-calendar-loading.znk-calendar-loading-active {
    z-index: 30;
    opacity: 0.5; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

@keyframes appears {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.znk-bloc-lesson {
  z-index: 10;
  cursor: pointer;
  border: 1px solid white;
  margin: 0;
  overflow: hidden;
  /**size: normal**/
  height: 56px;
  padding: 8px 12px;
  /**status: scheduled**/
  background-color: #72809D;
  border-radius: 8px; }
  .znk-bloc-lesson .znk-bloc-lesson-text {
    width: 100%;
    overflow: hidden; }
  .znk-bloc-lesson .znk-bloc-lesson-text-activity {
    width: 77%;
    overflow: hidden; }
  .znk-bloc-lesson .znk-bloc-lesson-icon {
    width: 10%; }
  .znk-bloc-lesson .znk-bloc-lesson-check {
    width: 0%;
    overflow: hidden; }
  .znk-bloc-lesson .znk-bloc-lesson-activity {
    width: 10%;
    overflow: visible; }
  .znk-bloc-lesson .znk-bloc-lesson-check-selected {
    width: 10%;
    overflow: visible; }
  .znk-bloc-lesson span {
    white-space: nowrap; }
  .znk-bloc-lesson .title {
    color: white; }
  .znk-bloc-lesson .subtitle {
    color: white; }
  .znk-bloc-lesson svg {
    color: white; }
  .znk-bloc-lesson:hover {
    opacity: inherit; }
    .znk-bloc-lesson:hover .znk-bloc-lesson-check {
      width: 10%;
      overflow: visible; }
    .znk-bloc-lesson:hover .znk-bloc-lesson-text {
      width: 90%; }
  .znk-bloc-lesson.znk-bloc-lesson-size-big {
    height: 72px;
    padding: 8px 12px; }
  .znk-bloc-lesson.znk-bloc-lesson-size-medium {
    height: 40px;
    padding: 0 12px; }
  .znk-bloc-lesson.znk-bloc-lesson-size-skinny {
    height: 24px;
    padding: 0 12px; }
  .znk-bloc-lesson.znk-bloc-lesson-attended {
    background-color: #F3FCD4;
    border-radius: 8px; }
    .znk-bloc-lesson.znk-bloc-lesson-attended .title, .znk-bloc-lesson.znk-bloc-lesson-attended .subtitle {
      color: #81B023; }
    .znk-bloc-lesson.znk-bloc-lesson-attended svg {
      color: #81B023; }
  .znk-bloc-lesson.znk-bloc-lesson-upcoming {
    background-color: #696CFF;
    border-radius: 8px; }
    .znk-bloc-lesson.znk-bloc-lesson-upcoming .title, .znk-bloc-lesson.znk-bloc-lesson-upcoming .subtitle, .znk-bloc-lesson.znk-bloc-lesson-upcoming svg {
      color: white; }
  .znk-bloc-lesson.znk-bloc-lesson-incomplete {
    background-color: #FFF5CB;
    border-radius: 8px; }
    .znk-bloc-lesson.znk-bloc-lesson-incomplete .title, .znk-bloc-lesson.znk-bloc-lesson-incomplete .subtitle, .znk-bloc-lesson.znk-bloc-lesson-incomplete svg {
      color: #E6AC00; }
  .znk-bloc-lesson.znk-bloc-lesson-missed {
    background-color: #FFEEEB;
    border-radius: 8px; }
    .znk-bloc-lesson.znk-bloc-lesson-missed .title, .znk-bloc-lesson.znk-bloc-lesson-missed .subtitle, .znk-bloc-lesson.znk-bloc-lesson-missed svg {
      color: #FF3E1D; }
  .znk-bloc-lesson.znk-bloc-lesson-draft {
    background-color: white;
    border-radius: 8px;
    border-color: rgba(114, 128, 157, 0.5); }
    .znk-bloc-lesson.znk-bloc-lesson-draft .title, .znk-bloc-lesson.znk-bloc-lesson-draft .subtitle, .znk-bloc-lesson.znk-bloc-lesson-draft svg {
      color: rgba(114, 128, 157, 0.5); }
  .znk-bloc-lesson.znk-bloc-lesson-active {
    background-color: #35425B; }
  .znk-bloc-lesson.znk-bloc-lesson-free {
    background-color: transparent;
    border: 1px dashed transparent;
    z-index: 9;
    position: absolute; }
    .znk-bloc-lesson.znk-bloc-lesson-free span {
      color: transparent; }
  .znk-bloc-lesson.znk-bloc-lesson-draggingOver {
    background-color: rgba(114, 128, 157, 0.3); }
  .znk-bloc-lesson.znk-bloc-lesson-freeHover {
    background-color: transparent;
    border: 1px dashed #696CFF;
    z-index: 9;
    position: absolute; }
    .znk-bloc-lesson.znk-bloc-lesson-freeHover span {
      color: #696CFF; }
    .znk-bloc-lesson.znk-bloc-lesson-freeHover:hover {
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75); }
  .znk-bloc-lesson.znk-bloc-lesson-blocked {
    background-color: #F2F2FF;
    border: none;
    border-radius: 0px;
    width: 100% !important;
    padding: 0px;
    cursor: default;
    z-index: 8; }

.input-selector *::-webkit-scrollbar-thumb {
  border-radius: 0px; }

.input-selector .znk-data {
  max-height: 415px; }
  .input-selector .znk-data.dropdown-menu {
    margin: 0.125rem 0 0 0;
    min-width: 10.5625rem !important; }
    @media (min-width: 768px) {
      .input-selector .znk-data.dropdown-menu {
        min-width: 15.625rem !important; } }

.input-selector .znk-data-values {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 86%, white 100%), linear-gradient(0deg, rgba(255, 255, 255, 0) 86%, white 100%);
  overflow-y: scroll;
  max-height: 210px; }
  .input-selector .znk-data-values .znk-data-shadow {
    position: absolute;
    height: 10px;
    width: 100%; }
    .input-selector .znk-data-values .znk-data-shadow.top {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 100%); }
    .input-selector .znk-data-values .znk-data-shadow.bottom {
      margin-top: 200px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%); }

.input-selector .button-cancel {
  font-size: 0.875rem;
  margin-left: 0.688rem; }

.input-selector .button-submit {
  color: #7079ff;
  font-size: 0.875rem;
  margin-right: 0.688rem; }

.znk-activity {
  display: flex;
  justify-content: center; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

textarea {
  resize: none; }

.znk-input {
  background-color: #F0F4F8;
  border-radius: 12px;
  border: none;
  color: #2C3F58;
  font-weight: 500;
  line-height: 1.5; }
  .znk-input.placeholder-to-right {
    text-align: right; }
    .znk-input.placeholder-to-right:hover, .znk-input.placeholder-to-right:active, .znk-input.placeholder-to-right:focus {
      text-align: right; }
    .znk-input.placeholder-to-right::placeholder {
      text-align: right; }
  .znk-input.error {
    color: #FF3E1D; }
  .znk-input:hover, .znk-input:active, .znk-input:focus {
    background-color: #F0F4F8;
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important; }
  .znk-input::placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input::-webkit-input-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input::-moz-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input:-ms-input-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input:-moz-placeholder {
    color: rgba(119, 134, 153, 0.5);
    font-weight: 400;
    line-height: 1.25; }
  .znk-input.noRight {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .znk-input.noLeft {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .znk-input.znk-table-input {
    border-radius: 16px;
    background-color: transparent; }

.znk-card .icon {
  background-color: #F0F4F8;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border: none;
  color: #2C3F58;
  min-height: 44px; }
  .znk-card .icon span {
    background-color: #F0F4F8; }

.znk-card .addon {
  color: #778699;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; }

.znk-no-border {
  background-color: #F0F4F8 !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; }

.znk-segment {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .znk-segment .left {
    width: calc(50% - 2px);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }
  .znk-segment .right {
    width: calc(50% - 2px);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }

.znk-select.special {
  margin-left: 3px;
  margin-right: 3px; }
  .znk-select.special button {
    border-radius: 0px !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-push {
  position: fixed;
  padding-top: 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5); }
  .modal-push .one {
    width: 25%; }
  .modal-push .two {
    width: 75%; }

.modal-content-push {
  position: relative;
  background-color: white;
  margin: auto 0 auto auto;
  width: 75%;
  height: 100vh; }

.c-25 {
  width: 35%; }

.c-50 {
  width: 65%;
  margin-left: 2%;
  height: 100vh;
  -webkit-box-shadow: -11px 3px 12px -4px rgba(67, 86, 100, 0.1);
  box-shadow: -11px 3px 12px -4px rgba(67, 86, 100, 0.1);
  border-radius: 12px;
  overflow-y: scroll; }

.c-100 {
  width: 100%; }

.unlock {
  display: block; }

.lock {
  display: none; }

.animated {
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.7s;
  animation-name: animatetop;
  animation-duration: 0.7s; }

@-webkit-keyframes animatetop {
  from {
    right: -300px;
    opacity: 0; }
  to {
    right: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    right: -300px;
    opacity: 0; }
  to {
    right: 0;
    opacity: 1; } }

.znk-check-container.inline {
  display: flex; }

.znk-check-container .znk-select-check span {
  margin-left: 5px; }

.znk-check-container .znk-select-check.znk-select-check-selected span {
  font-weight: 600; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-list-check {
  width: 100%;
  background-color: #F0F4F8;
  line-height: 1.7;
  font-size: 1rem;
  padding: 0.625rem 0.75rem 0.625rem 1rem;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }
  .znk-list-check span {
    margin-left: 4px;
    color: #778699;
    font-weight: 400; }
  .znk-list-check .icon-list-option {
    color: white; }
  .znk-list-check.processing {
    background-color: #D7E1EA; }
  .znk-list-check.selected {
    background-color: #696CFF; }
    .znk-list-check.selected span, .znk-list-check.selected small {
      color: #f8f9fa;
      font-weight: 500; }
    .znk-list-check.selected .znk-icon-service {
      background-color: #72809D;
      color: white; }
  .znk-list-check.selected-input span, .znk-list-check.selected-input small {
    color: #2C3F58;
    font-weight: 500; }
  .znk-list-check.selected-input .znk-icon-service {
    color: white; }
  .znk-list-check.selected-secondary {
    background-color: #FFB519; }
    .znk-list-check.selected-secondary span, .znk-list-check.selected-secondary small {
      color: #f8f9fa; }
    .znk-list-check.selected-secondary .znk-icon-service {
      color: white; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-note {
  background-color: #FFF5CB;
  border-radius: 12px; }
  .znk-note .textNote-edit {
    background-color: transparent;
    border: none; }
    .znk-note .textNote-edit:focus, .znk-note .textNote-edit:active {
      border: none;
      outline: none; }
  .znk-note .hover-icon:hover {
    background-color: #ffe67f; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.modal-push-sticky {
  transition: 0.3s;
  margin: auto 0 auto auto;
  height: 100%;
  display: flex; }
  @media (min-width: 0px) {
    .modal-push-sticky.showRight {
      width: 0%; } }
  @media (min-width: 641px) {
    .modal-push-sticky.showRight {
      width: auto; } }
  @media (min-width: 1100px) {
    .modal-push-sticky.showRight {
      width: auto; } }
  @media (min-width: 1440px) {
    .modal-push-sticky.showRight {
      width: auto; } }
  .modal-push-sticky.fixed-head-modal {
    max-height: 100vh; }
  .modal.fade .modal-push-sticky {
    transform: translate(0, 50px); }
    @media (min-width: 768px) {
      .modal.fade .modal-push-sticky {
        transform: translate(467px, 0); } }
  .modal.show .modal-push-sticky {
    transform: none; }
  @media (min-width: 768px) {
    .modal-push-sticky {
      min-height: 100%;
      max-width: none;
      width: 46.6vw; } }
  .modal-push-sticky .modal-content {
    transition: 0.3s;
    height: fit-content;
    max-height: 100vh;
    border: none;
    border-radius: 1rem 1rem 0 0;
    margin: auto 0 0 0; }
    @media (min-width: 768px) {
      .modal-push-sticky .modal-content {
        max-height: none;
        border-radius: 1rem 0 0 1rem;
        margin: auto 0 auto auto;
        height: 100%;
        padding: 0 1rem; } }
    @media (min-width: 1100px) {
      .modal-push-sticky .modal-content {
        padding: 0 5rem; }
        .modal-desktop-half .modal-push-sticky .modal-content {
          padding: 0 1.5rem; } }
    @media (min-width: 1440px) {
      .modal-push-sticky .modal-content {
        padding: 0 10rem; }
        .modal-desktop-half .modal-push-sticky .modal-content {
          padding: 0 1.5rem; } }
    .modal-push-sticky .modal-content nav {
      background-color: #ffffff !important;
      align-items: center;
      justify-content: space-between; }
      .modal-push-sticky .modal-content nav .navbar-brand {
        display: none; }
      .modal-push-sticky .modal-content nav svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-push-sticky .modal-content .modal-header {
      border: none; }
      @media (min-width: 768px) {
        .modal-push-sticky .modal-content .modal-header {
          margin-top: 1rem; } }
      .modal-push-sticky .modal-content .modal-header svg {
        cursor: pointer;
        color: #ccd1dc; }
    .modal-push-sticky .modal-content .modal-body {
      overflow: hidden scroll; }
      .modal-push-sticky .modal-content .modal-body::-webkit-scrollbar {
        width: 0rem; }
    .modal-push-sticky .modal-content .modal-footer {
      border: none; }
  @media (min-width: 992px) {
    .modal-push-sticky {
      max-width: none;
      width: 53.4vw; }
      .modal-push-sticky.modal-desktop-half {
        min-width: 400px;
        max-width: 26.7vw; }
        .modal-push-sticky.modal-desktop-half .modal-content {
          padding: 0 1rem; } }
  @media (min-width: 1200px) {
    .modal-push-sticky {
      width: 61.2vw; }
      .modal-push-sticky.modal-desktop-half {
        max-width: 31vw; } }
  .modal-push-sticky.another-modal-dialog {
    margin-left: 0px;
    background-color: white;
    transition: 0.3s;
    width: 0%;
    position: relative; }
    @media (min-width: 0px) {
      .modal-push-sticky.another-modal-dialog.show {
        width: 100%; } }
    @media (min-width: 641px) {
      .modal-push-sticky.another-modal-dialog.show {
        width: 75%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10; } }
    @media (min-width: 1100px) {
      .modal-push-sticky.another-modal-dialog.show {
        width: 45%;
        position: relative; } }
    @media (min-width: 1440px) {
      .modal-push-sticky.another-modal-dialog.show {
        width: 45%;
        position: relative; } }
    .modal-push-sticky.another-modal-dialog .another-modal-content {
      overflow-y: scroll; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-box-information {
  border-radius: 1rem;
  background-color: #F0F4F8; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.block-available {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
  margin: 1px;
  border: 2px dashed #DCE1E7; }
  .block-available .icon-checkmark {
    display: block; }
  .block-available.noFree {
    border: 2px solid #F0F4F8;
    background-color: #F0F4F8;
    cursor: default;
    color: #778699; }
  .block-available.available {
    border: 2px solid #696CFF;
    color: #696CFF; }
  .block-available.selected {
    border: 2px solid #8FC813;
    color: #8FC813; }
  .block-available.selectedUnavailable {
    border: 2px solid #FF3E1D;
    color: #FF3E1D; }
  .block-available.skeleton {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.block-available-recurring {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  flex: 1;
  text-align: center;
  border-radius: 16px;
  margin: 1px;
  border: 2px dashed #DCE1E7; }
  .block-available-recurring .icon-checkmark {
    display: block; }
  .block-available-recurring.noFree {
    border: 2px solid #F0F4F8;
    background-color: #F0F4F8;
    cursor: default;
    color: #778699; }
  .block-available-recurring.available {
    border: 2px solid #696CFF;
    color: #696CFF; }
  .block-available-recurring.selected {
    border: 2px solid #8FC813;
    color: #8FC813; }
  .block-available-recurring.selectedUnavailable {
    border: 2px solid #FF3E1D;
    color: #FF3E1D; }
  .block-available-recurring.skeleton {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.lessonShortCard {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  margin: 0.25rem 0;
  height: 120px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 20px; }
  .lessonShortCard.upcoming-card {
    background-color: #696CFF; }
  .lessonShortCard.next-card {
    background-color: #72809d; }
  .lessonShortCard.completed-card {
    background-color: #72809d; }
  .lessonShortCard.pending-card {
    background-color: #254050; }
  .lessonShortCard.due-card {
    background-color: #FFF3F0;
    color: #FF3E1D !important; }
    .lessonShortCard.due-card .opacity {
      color: rgba(37, 64, 80, 0.6); }
    .lessonShortCard.due-card svg {
      color: #FF3E1D !important; }

.h-100 {
  height: 100%; }

.down {
  margin-top: auto; }

.w-70 {
  width: 70%; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.nav-title {
  font-size: 1.125rem;
  color: #778699;
  font-weight: 700;
  line-height: 1.375rem; }
  .nav-title.active {
    color: #2C3F58; }
  .nav-title:hover {
    filter: brightness(85%);
    cursor: pointer; }

.h45 {
  font-size: 14px; }

.h14 {
  font-size: 0.875rem; }

.font-weight-light {
  font-weight: 400; }

.znk-meet {
  width: 100%;
  line-height: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 14px; }

.bordert {
  width: 100%;
  height: 1px;
  margin-right: -14px;
  margin-left: auto;
  border: 1px solid rgba(53, 66, 91, 0.1); }

.layout-two-sections {
  position: relative;
  transition: 0.5s; }
  .layout-two-sections .options-section {
    z-index: 10;
    transition: 0.5s;
    width: auto;
    position: relative; }
    .layout-two-sections .options-section .min {
      position: absolute;
      top: 0;
      width: 100%;
      transition: 0.5s; }
    .layout-two-sections .options-section .max {
      position: absolute;
      top: 0;
      width: 100%;
      transition: 0.5s;
      overflow-y: scroll;
      overflow-x: clip; }
  .layout-two-sections .calendar-section {
    overflow-x: auto;
    overflow-y: clip;
    z-index: 5;
    transition: 0.5s;
    position: relative;
    width: 100vw;
    left: 0vw !important; }
  .layout-two-sections.open-side .options-section {
    width: 30vw; }
    .layout-two-sections.open-side .options-section .min {
      opacity: 0;
      left: -7vw; }
    .layout-two-sections.open-side .options-section .max {
      opacity: 1;
      left: 0vw; }
  .layout-two-sections.close-side .options-section {
    width: 7vw; }
    .layout-two-sections.close-side .options-section .min {
      opacity: 1;
      left: 0vw; }
    .layout-two-sections.close-side .options-section .max {
      opacity: 0;
      left: -30vw; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-alert {
  border-radius: 16px;
  font-weight: 100;
  font-size: 16px;
  background-color: #FFF5CB;
  color: #E6AC00; }
  .znk-alert.znk-alert-pointer {
    cursor: pointer; }
  .znk-alert.znk-alert-close {
    opacity: 0; }
  .znk-alert.znk-alert-success {
    background-color: #35425B;
    color: white; }
  .znk-alert.znk-alert-error {
    background-color: #FFEEEB;
    color: #FF3E1D; }
  .znk-alert.znk-alert-message {
    background-color: #35425B;
    color: white; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.layout-container.close-section .left-section {
  width: 100vw !important; }

.layout-container.close-section .right-section {
  width: 0vw !important; }
  .layout-container.close-section .right-section .right-section-content {
    opacity: 0;
    right: -20%; }

.layout-container.open-section .right-section .right-section-content {
  opacity: 1; }

.layout-container.open-section .left-section.left-section-SM {
  width: 0vw !important; }

.layout-container .left-section {
  transition: .5s;
  z-index: 15;
  background-color: white; }
  .layout-container .left-section.left-section-SM {
    width: 100vw; }
  .layout-container .left-section.left-section-MD {
    width: 70%; }
  .layout-container .left-section.left-section-LG {
    width: 70%; }
  .layout-container .left-section.left-section-XL {
    width: 70%; }

.layout-container .right-section {
  transition: .5s;
  background-color: white;
  -webkit-box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1);
  -moz-box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1);
  box-shadow: inset 0px -3px 32px 2px rgba(67, 86, 100, 0.1);
  position: relative;
  z-index: 5; }
  .layout-container .right-section.right-section-SM {
    width: 100vw; }
  .layout-container .right-section.right-section-MD {
    width: 30%; }
  .layout-container .right-section.right-section-LG {
    width: 30%; }
  .layout-container .right-section.right-section-XL {
    width: 30%; }
  .layout-container .right-section .right-section-content {
    position: absolute;
    right: 0px;
    z-index: 10;
    transition: 1s; }
    .layout-container .right-section .right-section-content.right-section-content-SM {
      width: 100vw;
      min-width: 100vw; }
    .layout-container .right-section .right-section-content.right-section-content-MD {
      width: 100%;
      min-width: 27%; }
    .layout-container .right-section .right-section-content.right-section-content-LG {
      width: 100%;
      min-width: 27%; }
    .layout-container .right-section .right-section-content.right-section-content-XL {
      width: 100%;
      min-width: 27%; }

@keyframes fill {
  from {
    width: 0; }
  to {
    width: inherit; } }

.fill-animation {
  animation-name: fill;
  animation-duration: 1.5s;
  animation-delay: .5s;
  animation-fill-mode: forwards; }

.progress-bar {
  height: 8px;
  width: 100%;
  background-color: #D7E1EA;
  border-radius: 4px;
  min-width: 200px;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  justify-content: initial; }
  .progress-bar.progress-bar-loading {
    cursor: wait;
    background-repeat: repeat;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    border: none;
    animation-name: animation-16jpnkj;
    animation-duration: 9s;
    animation-direction: reverse;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

.znk-chat-box {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  clear: both; }

.znk-chat-message {
  margin: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 50%;
  width: auto;
  align-items: center; }
  .znk-chat-message.left {
    align-self: flex-start;
    flex-direction: row; }
  .znk-chat-message.right {
    align-self: flex-end;
    flex-direction: row-reverse; }

.actual-user.lesson {
  color: white;
  border-radius: 2rem 2rem 0rem 2rem;
  background-color: #696CFF;
  padding: 10%;
  margin-left: 10px; }

.actual-user.task {
  color: #696CFF;
  border-radius: 2rem 2rem 0rem 2rem;
  background-color: #f8f8ff;
  padding: 10%;
  margin-left: 10px;
  display: flex;
  flex-direction: column; }

.other-user.lesson {
  color: grey;
  border-radius: 2rem 2rem 2rem 0rem;
  background-color: #F0F4F8;
  padding: 10%;
  margin-right: 10px;
  display: flex;
  flex-direction: column; }

.other-user.task {
  color: grey;
  border-radius: 2rem 2rem 2rem 0rem;
  background-color: #f5f5f5;
  padding: 10%;
  margin-right: 10px;
  display: flex;
  flex-direction: column; }

.user-name {
  margin-bottom: 3%;
  color: black;
  white-space: nowrap; }

.message-date {
  font-size: 12px;
  white-space: nowrap; }

.user-role {
  margin-bottom: 3%;
  color: #A4A5FF;
  white-space: nowrap;
  font-size: '10px'; }

.znk-tool-tip-origin {
  background-color: transparent;
  border: none; }
  .znk-tool-tip-origin:focus {
    outline: none; }

.znk-tool-tip-info {
  border-radius: 10px !important; }

.light-tooltip {
  position: relative; }
  .light-tooltip .tooltip-content {
    white-space: nowrap;
    text-align: center;
    position: absolute;
    padding: 3px 10px;
    top: 0px;
    /* left: -300%; */
    background-color: black;
    color: white;
    border-radius: 12px;
    visibility: hidden;
    opacity: 0;
    transition: .3s; }
  .light-tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1; }

.icon-container {
  position: relative;
  border: solid 1px grey;
  border-radius: 50%;
  background-color: #696CFF; }

.span-text {
  height: 50%;
  width: 100%;
  padding: 24%;
  font-size: 10px;
  color: white !important;
  text-align: center;
  font-weight: bold !important;
  margin: auto; }

.status-circle {
  border-radius: 50%;
  background-color: grey;
  bottom: 0;
  right: 0;
  position: absolute; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-sign-input {
  box-shadow: 1px 5px 15px 5px rgba(0, 0, 0, 0.27);
  border-radius: 10px;
  background-color: white;
  width: 350px;
  height: 200px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .znk-sign-input .znk-sign {
    cursor: pointer; }
  .znk-sign-input.closed {
    background-color: #fbfbfc; }
    .znk-sign-input.closed .znk-sign {
      cursor: default; }
  .znk-sign-input.showing {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0; }
    .znk-sign-input.showing .znk-sign {
      text-decoration: underline;
      color: black !important;
      cursor: default; }

.firts-sign .znk-sign-input {
  box-shadow: none;
  border-radius: 10px 0px 0px 10px; }

.inner-sign .znk-sign-input {
  box-shadow: none;
  border-radius: 0px; }

.last-sign .znk-sign-input {
  box-shadow: none;
  border-radius: 0px 10px 10px 0px; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-sign-group {
  width: fit-content;
  border-radius: 10px;
  box-shadow: 1px 5px 15px 5px rgba(0, 0, 0, 0.27); }

.znk-score-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 160px;
  min-width: 160px;
  border-radius: 10px;
  padding: 10px;
  background-color: #bc5de5; }

.znk-score-zero {
  border-style: dashed;
  border-width: thin;
  border-color: #D6DAE3; }

.znk-score-list-item {
  display: flex;
  flex-direction: row;
  padding: 0px;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #f0f4f8;
  overflow: hidden; }
  .znk-score-list-item .left {
    align-self: center;
    padding-left: 10px; }
  .znk-score-list-item .right {
    width: 22%;
    align-self: center;
    background-color: #f0f4f8;
    padding: 10px; }
    .znk-score-list-item .right .input-score {
      width: 100%;
      background-color: transparent;
      border: none;
      text-align: center;
      outline: none; }

.znk-ribbon-content {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 20px; }

.znk-ribbon {
  background-color: black;
  clip-path: polygon(100% 0, 100% 100%, 50% 68%, 0 99%, 0 0);
  width: 50px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding: 0; }
  .znk-ribbon.left {
    z-index: 10;
    background-color: #eaccf6;
    margin-top: -30px; }
  .znk-ribbon.right {
    z-index: 5;
    margin-left: -30px;
    background-color: #9623c4; }

.znk-ribbon-task {
  background-color: black;
  clip-path: polygon(100% 0, 100% 100%, 50% 68%, 0 99%, 0 0);
  width: 50px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding: 0; }
  .znk-ribbon-task.left {
    z-index: 10;
    background-color: #eaccf6;
    margin-top: -50px; }
  .znk-ribbon-task.right {
    z-index: 5;
    margin-left: -35px;
    margin-top: -23px;
    background-color: #9623c4; }

.znk-ribbon-small {
  background-color: white;
  clip-path: polygon(30% 0, 30% 30%, 18% 24%, 7% 30%, 7% 0);
  width: 55px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding: 0; }
  .znk-ribbon-small.left {
    z-index: 10;
    background-color: #eaccf6;
    margin-top: -50px; }
  .znk-ribbon-small.right {
    z-index: 5;
    margin-left: -35px;
    margin-top: -23px;
    background-color: #9623c4; }

.znk-ribbon-noexam {
  background-color: white;
  clip-path: polygon(30% 0, 30% 30%, 18% 24%, 7% 30%, 7% 0);
  width: 55px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding: 0; }
  .znk-ribbon-noexam.left {
    z-index: 10;
    background-color: #696CFF;
    margin-top: -50px; }
  .znk-ribbon-noexam.right {
    z-index: 5;
    margin-left: -35px;
    margin-top: -23px;
    background-color: #696CFF; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-logo-item {
  object-fit: cover;
  padding: 10px;
  background-color: #eceff4;
  border-radius: 10px; }
  .znk-logo-item .logo-carrousel {
    height: 90px;
    width: 90px;
    background-color: transparent !important;
    display: block;
    overflow: hidden;
    display: flex;
    margin: auto;
    object-fit: scale-down; }

.znk-logo-circle.status {
  margin-left: 3px; }
  .znk-logo-circle.status .circle {
    clip-path: circle(5px at 6px 6px);
    width: 12px;
    height: 12px; }
    .znk-logo-circle.status .circle.active.wishlist {
      background-color: grey; }
    .znk-logo-circle.status .circle.active.inProgress {
      background-color: #B44BE1; }
    .znk-logo-circle.status .circle.active.applied {
      background-color: #ffd322; }
    .znk-logo-circle.status .circle.active.admitted {
      background-color: #81B023; }
    .znk-logo-circle.status .circle.active.rejected {
      background-color: #FF3E1D; }
    .znk-logo-circle.status .circle.active.waitlistedDiffered {
      background-color: blue; }
    .znk-logo-circle.status .circle.active.withdrawn {
      background-color: gray; }
    .znk-logo-circle.status .circle.nothing {
      background-color: #d7e1ea !important; }

@keyframes appearsTimeline {
  0% {
    opacity: 0; }
  50% {
    opacity: .6; }
  100% {
    opacity: 1; } }

.znk-template-timeline-group {
  animation-name: appearsTimeline;
  animation-duration: .4s; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-template-timeline .timeline-icon {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 30px; }
  .znk-template-timeline .timeline-icon .line {
    position: absolute;
    background-color: #d8e2eb;
    width: 2px;
    height: 100%;
    align-self: center; }
    .znk-template-timeline .timeline-icon .line.plus {
      top: 0;
      height: 50%; }
  .znk-template-timeline .timeline-icon .circle {
    clip-path: circle(50% at 50% 50%);
    background-color: #32405b;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center; }
    .znk-template-timeline .timeline-icon .circle .star {
      clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
      background-color: white;
      width: 15px;
      height: 15px; }
    .znk-template-timeline .timeline-icon .circle .plus {
      transform: rotate(45deg); }
    .znk-template-timeline .timeline-icon .circle.plus {
      background-color: blue; }

.znk-template-timeline .create-task {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  background-color: #F2F2FF;
  cursor: pointer; }
  .znk-template-timeline .create-task .plus {
    transform: rotate(45deg); }

.znk-platform-label {
  width: fit-content;
  display: flex;
  flex-direction: row;
  border: 1px dashed #dde2e8;
  border-radius: 0px 20px 20px 0px;
  padding: 8px 15px;
  z-index: 10;
  background-color: #f8f9fa; }
  .znk-platform-label .star {
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
    background-color: #32405b;
    width: 15px;
    height: 15px;
    align-self: center;
    margin-right: 10px; }
  .znk-platform-label span {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    transition: 1.5s;
    width: 0px;
    max-width: fit-content; }
  .znk-platform-label:hover span {
    width: 1000px; }
  .znk-platform-label.active {
    border: none;
    background-color: #32405b; }
    .znk-platform-label.active .star {
      background-color: white; }

.xlsxCard {
  border-radius: 12px;
  height: 4%;
  background-color: #F0F4F8; }

.main-border {
  border-style: dashed;
  border-color: #E7E7E7;
  border-radius: 15px;
  border-width: 2px; }

.border-solid {
  border-style: solid;
  border-color: #E7E7E7;
  border-radius: 15px;
  border-width: 2px; }

.border-dashed {
  border-style: dashed;
  border-color: #E7E7E7;
  border-radius: 15px;
  border-width: 2px; }

.content {
  color: #778699; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.processItem {
  border-radius: 12px; }

.icon-process-item {
  width: 15%; }

.content-process-item {
  width: 95%; }

.completed {
  background-color: #F3FCD4;
  color: #81B023; }

.incomplete {
  background-color: #FFF5CB;
  color: #E6AC00; }

.missed {
  background-color: #FFEEEB;
  color: #FF3E1D; }

.upcoming {
  background-color: #72809d;
  color: white; }

.scheduled {
  background-color: #35425B;
  color: white; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.file-item {
  position: relative;
  clip-path: polygon(62% 0, 100% 31%, 100% 100%, 0 100%, 0 0);
  overflow: hidden;
  position: relative;
  display: block;
  width: 140px;
  height: 180px;
  border-radius: 10px;
  background-color: #4F8BFF;
  color: #FFFFFF;
  padding: 10px 10px 10px 16px;
  text-align: left; }
  .file-item.file-item-hover:hover {
    display: inline-block;
    animation: pulse;
    /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s;
    /* don't forget to set a duration! */
    animation-iteration-count: infinite; }
  .file-item.primary-file {
    background-color: #696CFF;
    color: white; }
  .file-item.gray-file {
    background-color: #d8e2eb; }
  .file-item.purple-file {
    background-color: #B44BE1;
    color: white; }
  .file-item.sat-file {
    background-color: #B44BE1;
    color: white; }
  .file-item.toefl-file {
    background-color: #FF7733;
    color: white; }
  .file-item.act-file {
    background-color: #38A895;
    color: white; }
  .file-item.ielts-file {
    background-color: #696CFF;
    color: white; }
  .file-item.ap-file {
    background-color: #FF7733;
    color: white; }
  .file-item.duolingo-file {
    background-color: #5B5D5F;
    color: white; }
  .file-item.ssat-file {
    background-color: #B44BE1;
    color: white; }
  .file-item.gre-file {
    background-color: #38A895;
    color: white; }
  .file-item.lsat-file {
    background-color: #472B39;
    color: white; }
  .file-item.isee-file {
    background-color: #3F472B;
    color: white; }
  .file-item.newExam-file {
    background-color: white;
    color: white;
    border: 1px dashed #7079ff; }
  .file-item .file-text {
    color: white; }
  .file-item.small {
    border-radius: 5px;
    width: 35px;
    height: 45px;
    padding: 1px; }
    .file-item.small:before {
      width: 15px;
      height: 15px; }
  .file-item.small-plus {
    clip-path: polygon(85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);
    border-radius: 5px;
    width: 40px;
    height: 60px;
    padding: 1px; }
    .file-item.small-plus:before {
      width: 10px;
      height: 10px; }
  .file-item.medium {
    border-radius: 10px;
    width: 70px;
    height: 90px;
    padding: 5px; }
    .file-item.medium:before {
      width: 26px;
      height: 28px; }
  .file-item.medium-plus {
    clip-path: polygon(90% 0, 100% 10%, 100% 100%, 0 100%, 0 0);
    border-radius: 10px;
    width: 95px;
    height: 122px;
    padding: 7px;
    font-size: initial;
    margin-right: 15px; }
    .file-item.medium-plus:before {
      width: 15px;
      height: 15px; }

.file-item:before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 0;
  background: #F3EEE8; }

.list-item-options {
  border-radius: 12px;
  height: 2%;
  background-color: #F0F4F8; }

.icon-list-item-option {
  width: 3%; }

.mainText {
  width: 60%;
  text-align: left;
  text-decoration: line-through; }

.date {
  width: 15%; }

.avatar-icon {
  width: 0%;
  text-align: center; }

.znk-document-info-section {
  border: 1px solid #D6DAE3;
  border-radius: 10px;
  width: 100%;
  padding: 20px; }

.znk-document-info-group {
  border: 1px solid #D6DAE3;
  border-radius: 10px;
  width: 100%; }
  .znk-document-info-group .znk-document-info-section {
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #D6DAE3; }
    .znk-document-info-group .znk-document-info-section.last {
      border-bottom: none; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.card-college {
  background: #F2F2FF;
  border-radius: 15px;
  height: 220px;
  width: 272px; }

.college-img-web {
  margin: 11%;
  height: 75%;
  width: 75%; }

.college-img-mobile {
  padding: 20%;
  height: 100%;
  width: 100%; }

.list-item-container {
  padding: 4px;
  border-bottom: solid 1px #DEDEDE; }

.number-text {
  font-size: 22px;
  color: #E3E3E3; }

.slick-prev:before {
  color: grey; }

.slick-arrow:before {
  color: grey; }

.rate-text {
  font-size: 14px; }

.position-college {
  color: #A7E521; }

.addNewCollege:hover {
  background-color: #DCE1E7;
  cursor: pointer; }

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 20px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.input-switch:checked + .slider {
  background-color: #696CFF; }

.input-switch:focus + .slider {
  box-shadow: 0 0 1px #696CFF; }

.input-switch:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

.slider.round-switch {
  border-radius: 34px; }

.slider.round-switch:before {
  border-radius: 50%; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-float-button {
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 100; }

.znk-float-options {
  z-index: 100;
  position: fixed;
  bottom: 50px;
  right: -1000px;
  transition: .5s;
  background-color: #696CFF;
  padding: 10px;
  max-height: 300px;
  min-width: 150px;
  max-width: 75%;
  overflow-y: scroll;
  border-radius: 12px; }
  .znk-float-options.open {
    right: 50px; }
  .znk-float-options .list-options {
    border-radius: 12px;
    color: white;
    padding: 10px; }
    .znk-float-options .list-options .info-option {
      width: 90%; }
    .znk-float-options .list-options .label-option {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80%;
      overflow: hidden; }
    .znk-float-options .list-options .icon-option {
      width: 15px !important;
      height: 15px !important; }
    .znk-float-options .list-options:hover {
      cursor: pointer;
      background-color: #6660ec; }

.row-task {
  border-radius: 10px;
  background-color: #F0F4F8; }
  .row-task.lesson-attended {
    background-color: #F3FCD4;
    color: #81B023; }
    .row-task.lesson-attended .hover-icon:hover {
      background-color: #dff78c; }
  .row-task.lesson-upcoming {
    background-color: #696CFF;
    color: white; }
    .row-task.lesson-upcoming .hover-icon:hover {
      background-color: #1d21ff; }
  .row-task.lesson-incomplete {
    background-color: #FFF5CB;
    color: #E6AC00; }
    .row-task.lesson-incomplete .hover-icon:hover {
      background-color: #ffe67f; }
  .row-task.lesson-missed {
    background-color: #FFEEEB;
    color: #FF3E1D; }
    .row-task.lesson-missed .hover-icon:hover {
      background-color: #ffad9f; }
  .row-task.lesson-scheduled {
    background-color: #72809d;
    color: white; }
    .row-task.lesson-scheduled .hover-icon:hover {
      background-color: #505b73; }
  .row-task.lesson-draft {
    background-color: white;
    color: #696CFF;
    border: 1px solid #696CFF; }
    .row-task.lesson-draft .hover-icon:hover {
      background-color: #1d21ff; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.event-item {
  border-radius: 8px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px; }
  .event-item.event-item-primary {
    background-color: #696CFF;
    color: white; }
    .event-item.event-item-primary .hover-icon:hover {
      background-color: #1d21ff; }
  .event-item.event-item-secondary {
    background-color: #72809d;
    color: white; }
    .event-item.event-item-secondary .hover-icon:hover {
      background-color: #505b73; }
  .event-item.event-item-blank {
    border: 1px solid #696CFF;
    background-color: white;
    color: #696CFF; }
  .event-item.event-item-success {
    background-color: #F3FCD4;
    color: #81B023; }
    .event-item.event-item-success .hover-icon:hover {
      background-color: #dff78c; }
  .event-item.event-item-danger {
    background-color: #FFEEEB;
    color: #FF3E1D; }
    .event-item.event-item-danger .hover-icon:hover {
      background-color: #ffad9f; }
  .event-item.event-item-warning {
    background-color: #E7AD00;
    color: #E6AC00; }
    .event-item.event-item-warning .hover-icon:hover {
      background-color: #9b7400; }
  .event-item.event-item-canceled {
    background-color: #ffeeeb;
    color: #ff3e1d; }
    .event-item.event-item-canceled .hover-icon:hover {
      background-color: #ffad9f; }
  .event-item.event-item-input {
    background-color: #F2F2FF;
    color: #778699; }
    .event-item.event-item-input .hover-icon:hover {
      background-color: #e3e3ff; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-alert-button {
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  border-color: black;
  background-color: white;
  color: black; }
  .znk-alert-button.znk-alert-button-success {
    border-color: #F3FCD4;
    background-color: #F3FCD4;
    color: #AAE61D; }
    .znk-alert-button.znk-alert-button-success svg {
      color: #AAE61D; }
  .znk-alert-button.znk-alert-button-warning {
    border-color: #FFF5CB;
    background-color: #FFF5CB;
    color: #E6AC00; }
    .znk-alert-button.znk-alert-button-warning svg {
      color: #E6AC00; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-input-password {
  border: none;
  border-radius: 12px;
  outline: none;
  background-color: #F2F2FF; }
  .znk-input-password .badge-icon {
    cursor: pointer;
    background-color: #F2F2FF;
    color: #D6DAE3; }
    .znk-input-password .badge-icon.active {
      color: #778699; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.day-span {
  color: #778699;
  font-size: 12px;
  width: 13%;
  text-align: center; }

.calendar-month-day {
  width: 13%;
  height: 40px;
  color: #778699;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1px;
  border-radius: 35%;
  font-size: 12px;
  cursor: not-allowed; }
  .calendar-month-day::before {
    position: absolute;
    top: -12px;
    color: #778699;
    font-size: 12px; }
  .calendar-month-day.border-dashed {
    border: 2px dashed #D6DAE3;
    cursor: pointer; }
  .calendar-month-day.blue-active {
    border: 2px solid blue;
    color: blue;
    cursor: pointer; }
  .calendar-month-day.red-unselect {
    border: 2px solid red;
    color: red;
    cursor: pointer;
    font-size: 30px; }
  .calendar-month-day.mo-day-en::before {
    content: 'Mo'; }
  .calendar-month-day.tu-day-en::before {
    content: 'Tu'; }
  .calendar-month-day.we-day-en::before {
    content: 'We'; }
  .calendar-month-day.th-day-en::before {
    content: 'Th'; }
  .calendar-month-day.fr-day-en::before {
    content: 'Fr'; }
  .calendar-month-day.sa-day-en::before {
    content: 'Sa'; }
  .calendar-month-day.su-day-en::before {
    content: 'Su'; }
  .calendar-month-day.mo-day-es::before {
    content: 'Lu'; }
  .calendar-month-day.tu-day-es::before {
    content: 'Ma'; }
  .calendar-month-day.we-day-es::before {
    content: 'Mi'; }
  .calendar-month-day.th-day-es::before {
    content: 'Ju'; }
  .calendar-month-day.fr-day-es::before {
    content: 'Vi'; }
  .calendar-month-day.sa-day-es::before {
    content: 'Sa'; }
  .calendar-month-day.su-day-es::before {
    content: 'Do'; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-navbar-mobile {
  background-color: transparent;
  color: black; }

.znk-navbar-mobile-menu {
  position: absolute;
  transition: 0.3s;
  top: 0;
  z-index: 150;
  min-height: 110%; }
  .znk-navbar-mobile-menu.open-menu {
    background-color: rgba(128, 128, 128, 0.5);
    transform: translateX(0); }
  .znk-navbar-mobile-menu.close-menu {
    background-color: transparent;
    transform: translateX(-100%); }
  .znk-navbar-mobile-menu .znk-navbar-mobile-menu-option {
    padding: 23px 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    background-color: #F8F9FA;
    color: #5a6782; }
    .znk-navbar-mobile-menu .znk-navbar-mobile-menu-option svg {
      color: #5a6782;
      justify-self: flex-end; }
    .znk-navbar-mobile-menu .znk-navbar-mobile-menu-option:hover {
      background-color: #F0F4F8; }
  .znk-navbar-mobile-menu .znk-navbar-mobile-menu-header {
    width: 100%;
    background-color: #F8F9FA;
    padding: 23px 15px; }

.menu-burger-icon {
  width: 18px;
  height: 3px;
  background-color: #778699;
  position: relative;
  border-radius: 7px; }
  .menu-burger-icon:before {
    border-radius: 7px;
    background: #778699;
    width: 100%;
    height: 3px;
    position: absolute;
    top: -6px;
    content: ''; }
  .menu-burger-icon:after {
    border-radius: 7px;
    background: #778699;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 6px;
    content: ''; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.inf-save {
  background-color: #FFF5CB;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; }

.test {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll; }

.test::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */ }

.hover-icon-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  padding: 2px !important;
  transition: .3s; }
  .hover-icon-note:hover {
    cursor: pointer;
    background-color: #F0F4F8; }
  .hover-icon-note.white:hover {
    background-color: white; }
  .hover-icon-note.dark:hover {
    background-color: #20293a; }
  .hover-icon-note.primary:hover {
    background-color: #363aff; }
  .hover-icon-note.secondary:hover {
    background-color: #5a6782; }
  .hover-icon-note.disabled:hover {
    background-color: transparent !important;
    cursor: not-allowed !important; }
  .hover-icon-note.danger:hover {
    background-color: #ffad9f; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.znk-youtube-card {
  overflow: hidden;
  cursor: pointer; }
  .znk-youtube-card .znk-youtube-card-frame {
    border-radius: 12px;
    overflow: hidden; }
  .znk-youtube-card .znk-youtube-play-button {
    width: 20%;
    position: absolute;
    top: 40%;
    left: 40%;
    opacity: .6;
    transition: .65s; }
    .znk-youtube-card .znk-youtube-play-button:hover {
      opacity: 1; }
  .znk-youtube-card .znk-youtube-card-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    padding: 0px 0px;
    position: relative;
    background-size: cover;
    transition: .65s; }
    .znk-youtube-card .znk-youtube-card-content .znk-youtube-card-info {
      position: absolute;
      bottom: -100%;
      transition: .65s;
      opacity: 0; }
    .znk-youtube-card .znk-youtube-card-content .znk-youtube-card-image {
      transition: .65s; }
  .znk-youtube-card:hover .znk-youtube-card-content .znk-youtube-card-info {
    bottom: 0px;
    opacity: 1; }
  .znk-youtube-card:hover .znk-youtube-card-content .znk-youtube-card-image {
    transform: scale(1.2); }

.znk-youtube-card-info {
  background-color: #F0F4F8;
  color: #2C3F78;
  height: auto; }

.znk-youtube-channel-avatar div {
  border-radius: 50%;
  border: 1px solid #778699;
  width: 40px !important;
  height: 40px !important;
  background-size: contain; }

.znk-youtube-logo {
  width: 20px; }

.znk-subscribe-button {
  background-color: red;
  color: white;
  padding: 7px 10px;
  cursor: pointer;
  transition: 0.6s;
  opacity: 1; }
  .znk-subscribe-button.on-subscribe {
    opacity: .6;
    background-color: #303030;
    color: white; }

.youtube-zinkerz-container {
  transition: 1s; }

.card-hidden {
  visibility: hidden; }

.navigation-slider-button {
  position: absolute;
  top: 40%;
  z-index: 1;
  cursor: pointer; }
  .navigation-slider-button.next-slider-button {
    right: 0; }
  .navigation-slider-button.previous-slider-button {
    left: 0; }

.navigation-icon {
  opacity: .5;
  color: #778699; }
  .navigation-icon:hover {
    color: #2C3F58; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.plan-pending-approve {
  border: 1px solid #D6DAE3;
  border-radius: 12px;
  min-width: 200px;
  max-width: 200px;
  height: 220px;
  overflow: hidden; }
  .plan-pending-approve .top-section {
    height: 30%;
    background-color: #35425b;
    color: white; }
  .plan-pending-approve.counseling-plan .top-section {
    background-color: #35425b; }
  .plan-pending-approve.liveprep-plan .top-section {
    background-color: #696CFF; }
  .plan-pending-approve .bottom-section {
    border-top: 1px solid #D6DAE3;
    height: 70%; }

.type-plan-icon {
  cursor: pointer;
  transition: .3s;
  color: #D6DAE3; }
  .type-plan-icon:hover {
    transform: scale(1.2);
    color: #778699; }

@keyframes cargando {
  0% {
    transform: rotateZ(0deg); }
  50% {
    transform: rotateZ(360deg); }
  100% {
    transform: rotateZ(0deg); } }

@keyframes escalar {
  0% {
    transform: scale(1); }
  4% {
    transform: scale(1); }
  24% {
    transform: scale(1.5); }
  36% {
    transform: scale(1.3); }
  48% {
    transform: scale(1.5); }
  60% {
    transform: scale(1.3); }
  72% {
    transform: scale(1.5); }
  96% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

.zinkerz-logo-circular-animation svg {
  animation-name: escalar !important;
  animation-duration: 5s !important;
  animation-iteration-count: infinite; }

@keyframes appears {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.znk-carousel {
  overflow: hidden;
  position: relative; }
  .znk-carousel .znk-carousel-container {
    transition: 1s; }
    .znk-carousel .znk-carousel-container .znk-carousel-loading {
      animation-name: appears;
      animation-duration: 1s;
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .znk-carousel .znk-carousel-container .znk-carousel-item {
      animation-name: appears;
      animation-duration: 1s;
      overflow: hidden; }

.card-hidden {
  visibility: hidden; }

.navigation-slider-button {
  position: absolute;
  top: 40%;
  z-index: 1;
  /* background-color: black; */ }

.next-slider-button {
  right: 0; }

.previous-slider-button {
  left: 0; }

.znk-instagram-card {
  width: 100%;
  aspect-ratio: 1/1;
  transition: .3s;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default; }
  .znk-instagram-card .znk-instagram-image {
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .znk-instagram-card .znk-instagram-image img, .znk-instagram-card .znk-instagram-image iframe {
      transition: .3s;
      height: 102%; }
  .znk-instagram-card .znk-instagram-label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    color: white;
    font-size: 18px;
    transition: .5s;
    opacity: 0;
    margin-top: 70px;
    text-align: center;
    padding: 0px 10px; }
  .znk-instagram-card .znk-instagram-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: transparent;
    transition: .5s;
    z-index: 1; }
  .znk-instagram-card .znk-instagram-options {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0px;
    transition: .5s;
    position: absolute;
    color: white;
    align-self: flex-end;
    bottom: 0;
    opacity: 0; }
    .znk-instagram-card .znk-instagram-options div {
      display: flex;
      flex-direction: row;
      column-count: 3;
      column-gap: 20px; }
    .znk-instagram-card .znk-instagram-options span {
      cursor: pointer; }
  .znk-instagram-card:hover .znk-instagram-image img, .znk-instagram-card:hover .znk-instagram-image iframe {
    filter: blur(5px);
    transform: scale(1.15); }
  .znk-instagram-card:hover .znk-instagram-label {
    opacity: 1;
    margin-top: 0px; }
  .znk-instagram-card:hover .znk-instagram-options {
    opacity: 1; }
  .znk-instagram-card:hover .znk-instagram-info {
    background-color: rgba(0, 0, 0, 0.5); }

.znk-instagram-logo-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%; }

.znk-instagram-logo-skeleton {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important; }

.background-sat {
  background-color: #B44BE1 !important; }

.background-ielts {
  background-color: #696CFF !important; }

.background-toefl {
  background-color: #FF7733 !important; }

.background-act {
  background-color: #38A895 !important; }

.background-ap {
  background-color: #FF7733 !important; }

.background-duolingo {
  background-color: #5B5D5F !important; }

.background-ssat {
  background-color: #B44BE1 !important; }

.background-gre {
  background-color: #38A895 !important; }

.background-lsat {
  background-color: #472B39 !important; }

.background-isee {
  background-color: #3F472B !important; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #d2d5d8;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; }
  .lds-ring.lds-ring-small {
    width: 40px;
    height: 40px; }
    .lds-ring.lds-ring-small div {
      width: 30px;
      height: 30px;
      margin: 5px;
      border: 5px solid #d2d5d8;
      border-color: #d2d5d8 transparent transparent transparent; }
  .lds-ring.lds-ring-medium {
    width: 80px;
    height: 80px; }
    .lds-ring.lds-ring-medium div {
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #d2d5d8;
      border-color: #d2d5d8 transparent transparent transparent; }
  .lds-ring.lds-ring-large {
    width: 120px;
    height: 120px; }
    .lds-ring.lds-ring-large div {
      width: 100px;
      height: 100px;
      margin: 10px;
      border: 10px solid #d2d5d8;
      border-color: #d2d5d8 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.znk-scroll-horizontal {
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  overflow-x: auto !important;
  overflow-y: auto !important;
  flex-wrap: nowrap;
  scrollbar-width: none !important; }
  .znk-scroll-horizontal::-webkit-scrollbar {
    display: none; }
  .znk-scroll-horizontal span {
    scroll-snap-align: start; }
  .znk-scroll-horizontal:hover, .znk-scroll-horizontal:active, .znk-scroll-horizontal .scrolling {
    box-shadow: 1px 0 1px 1px white inset, -1px 0 1px 1px white inset, 12px 0 12px -6px #F0F4F8 inset, -12px 0 12px -6px #F0F4F8 inset; }
    @media (min-width: 1200px) {
      .znk-scroll-horizontal:hover, .znk-scroll-horizontal:active, .znk-scroll-horizontal .scrolling {
        box-shadow: none; } }
  @media (min-width: 1200px) {
    .znk-scroll-horizontal {
      overflow-x: visible;
      overflow-y: visible;
      box-shadow: none; }
      .znk-scroll-horizontal.scrollToo {
        overflow-x: scroll; } }

.hidde-scroll-vertical {
  overflow-y: auto !important;
  scrollbar-width: none; }
  .hidde-scroll-vertical::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }

.hidde-scroll-vertical::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px; }

.hidde-scroll-vertical::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7); }

.hidde-scroll-vertical::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.8); }

.hover-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  padding: 10px !important;
  transition: .3s; }
  .hover-icon.small-circle {
    padding: 5px !important; }
  .hover-icon:hover {
    cursor: pointer;
    background-color: #F0F4F8; }
  .hover-icon.white:hover {
    background-color: white; }
  .hover-icon.dark:hover {
    background-color: #20293a; }
  .hover-icon.primary:hover {
    background-color: #363aff; }
  .hover-icon.secondary:hover {
    background-color: #5a6782; }
  .hover-icon.disabled:hover {
    background-color: transparent !important;
    cursor: not-allowed !important; }
  .hover-icon.danger:hover {
    background-color: #ffad9f; }

.znk-no-horizontal-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none; }
  .znk-no-horizontal-scroll-bar::-webkit-scrollbar {
    display: none; }

.znk-scroll-horizontal-v2 {
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  overflow-x: overlay;
  overflow-y: hidden;
  flex-wrap: nowrap;
  scrollbar-width: none !important;
  transition: 0.5s; }
  .znk-scroll-horizontal-v2.znk-scroll-horizontal-planCard .planCard {
    scroll-snap-align: start; }
  .znk-scroll-horizontal-v2.znk-scroll-horizontal-youtube-video .znk-youtube-card {
    scroll-snap-align: start; }
  .znk-scroll-horizontal-v2:hover, .znk-scroll-horizontal-v2:active, .znk-scroll-horizontal-v2 .scrolling {
    box-shadow: 1px 0 1px 1px white inset, -1px 0 1px 1px white inset, 12px 0 12px -6px #F0F4F8 inset, -12px 0 12px -6px #F0F4F8 inset; }
    @media (min-width: 1200px) {
      .znk-scroll-horizontal-v2:hover, .znk-scroll-horizontal-v2:active, .znk-scroll-horizontal-v2 .scrolling {
        box-shadow: none; } }
  .znk-scroll-horizontal-v2::-webkit-scrollbar {
    height: 0.5rem; }

.btn-link {
  cursor: pointer; }

.ellipsis-text {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden; }

.znk-option-item {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F8;
  border-radius: 25%;
  cursor: pointer; }
  .znk-option-item svg {
    color: #72809d; }
  .znk-option-item:hover {
    background-color: rgba(105, 108, 255, 0.1); }
    .znk-option-item:hover svg {
      color: #696CFF; }
  .znk-option-item.znk-option-item-primary {
    background-color: #696CFF; }
    .znk-option-item.znk-option-item-primary svg {
      color: white; }
    .znk-option-item.znk-option-item-primary:hover {
      background-color: #696CFF; }
      .znk-option-item.znk-option-item-primary:hover svg {
        color: #F0F4F8; }

.icon-load {
  box-sizing: border-box;
  border: .2em solid;
  border-radius: 50%;
  border-bottom-color: rgba(0, 0, 0, 0.5) !important;
  color: rgba(0, 0, 0, 0.1) !important;
  animation: rotation 1s infinite linear; }

/**Cursor styles**/
.cursor-pointer {
  cursor: pointer !important; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.znk-menu-option {
  border-radius: 0.75rem;
  margin: 0 1rem;
  padding: 0.5rem 0.5rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-weight: 500;
  flex: 1;
  cursor: pointer; }
  .znk-menu-option:hover {
    background-color: rgba(105, 108, 255, 0.1);
    color: #696CFF; }

.rotate-45 {
  transform: rotate(45deg); }

.visibility-hidden {
  visibility: hidden; }

.text-line-through {
  text-decoration: line-through; }

.w-0 {
  width: 0% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-55 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-0 {
  height: 0% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.rotate-90 {
  transform: rotate(90deg); }

@keyframes appearsAnimation {
  0% {
    opacity: 0; }
  50% {
    opacity: .6; }
  100% {
    opacity: 1; } }

.appears-animation {
  animation-name: appearsAnimation;
  animation-duration: .4s; }

.list-content-on-modal {
  max-height: 400px;
  overflow-y: scroll; }

.text-align-center {
  text-align: center !important; }

.white-space-nowrap {
  white-space: nowrap !important; }

.background-primary {
  background-color: #696CFF !important; }

.border-radius-0 {
  border-radius: 0px !important; }

.opacity-white-bottom {
  bottom: 0px;
  position: sticky;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95) 25%);
  padding-top: 15px; }

.star-shape {
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  background-color: #9aa1af;
  width: 20px;
  height: 20px; }
  .star-shape.small {
    width: 15px;
    height: 15px; }
  .star-shape.medium {
    width: 20px;
    height: 20px; }
  .star-shape.counseling {
    background-color: #2c3f58; }

.h-100vh {
  height: 100vh !important; }

.w-min-content {
  width: min-content !important; }

.bg-wishlist:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-inProgress:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(180, 75, 225, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(180, 75, 225, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(180, 75, 225, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-applied:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 211, 34, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(255, 211, 34, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(255, 211, 34, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-admitted:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(129, 176, 35, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(129, 176, 35, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(129, 176, 35, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-rejected:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(255, 62, 29, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(255, 62, 29, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(255, 62, 29, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-waitlistedDiffered:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 255, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(0, 0, 255, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(0, 0, 255, 0.1);
  opacity: 2;
  cursor: pointer; }

.bg-withdrawn:hover {
  /* box-shadow: 0 0 0 15px rgba(#000,.4) ; */
  -webkit-box-shadow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  -moz-box-shadwow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  box-shadow: 0px 0px 0px 10px rgba(128, 128, 128, 0.1);
  opacity: 2;
  cursor: pointer; }

.fix-element-bottom {
  bottom: 0px;
  position: sticky;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.95) 25%);
  padding-top: 15px; }

.no-border-left {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important; }

.no-border-right {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important; }

.no-select-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */ }

.secondaryBackground {
  background-color: #f8f9fa !important; }

.whiteBackground {
  background-color: white !important; }

.border-icon-button {
  border: 1px solid #D6DAE3;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px !important;
  transition: .3s; }
  .border-icon-button svg {
    transition: .3s;
    color: #D6DAE3; }
  .border-icon-button:hover {
    border: 1px solid #696CFF; }
    .border-icon-button:hover svg {
      color: #696CFF; }
  .border-icon-button .background-sat {
    background-color: #B44BE1 !important; }
  .border-icon-button .background-ielts {
    background-color: #696CFF !important; }
  .border-icon-button .background-toefl {
    background-color: #FF7733 !important; }
  .border-icon-button .background-act {
    background-color: #38A895 !important; }
  .border-icon-button .background-sat {
    background-color: #B44BE1 !important; }
  .border-icon-button .background-ap {
    background-color: #FF7733 !important; }
  .border-icon-button .background-duolingo {
    background-color: #5B5D5F !important; }
  .border-icon-button .background-ssat {
    background-color: #B44BE1 !important; }
  .border-icon-button .background-gre {
    background-color: #38A895 !important; }
  .border-icon-button .background-lsat {
    background-color: #472B39 !important; }
  .border-icon-button .background-isee {
    background-color: #3F472B !important; }
  .border-icon-button .bottom-modal {
    position: absolute;
    bottom: 0; }
